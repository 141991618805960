import { useEffect } from "react";
import MetaData from "../../metadata/MetaData";
import PGNEdit from "../../pgnedit/PGNEdit";
import Upload from "../../upload/Upload";
import useLocalStorage from "../hooks/useLocalStorage";
import { useGameCacheContext } from "./context/GameCacheProvider";
import { useGameDataContext } from "./context/GameDataProvider";
import { useStepNavigationContext } from "./context/StepNavigationProvider";
import useResponsive from "../hooks/useResponsive";

/**
 * @returns StepView
 */
function StepView() {
    const context = useStepNavigationContext();
    const { updateCache, setUpdateCache } = useGameCacheContext();
    const {
        gameLogic,
        gameLogicSnapshot,
        setPgnString,
        setExpandMobileOverlay,
    } = useGameDataContext();

    const { storeContext } = useLocalStorage();

    const onResponsive = (breakpoint) => {
        const collapseBreakpoints = ["lg", "xl", "2xl", "3xl", "4xl"];

        if (
            !breakpoint ||
            collapseBreakpoints.find((bp) => bp === breakpoint)
        ) {
            setExpandMobileOverlay(false);
        }
    };

    useResponsive(onResponsive);

    useEffect(() => {
        if (updateCache === true) {
            storeContext();
            setUpdateCache(false);
        }
    }, [updateCache, setUpdateCache, storeContext]);

    useEffect(() => {
        if (gameLogic) {
            const newPgnString = gameLogic.save();
            setPgnString(newPgnString);
            setUpdateCache(true);
        }
    }, [setUpdateCache, gameLogic, gameLogicSnapshot, setPgnString]);

    const currentView = () => {
        switch (context.getActivePath().path) {
            case "upload":
                return <Upload />;
            case "metadata":
                return <MetaData />;
            case "pgnedit":
                return <PGNEdit />;
            default:
                break;
        }
    };
    return <>{currentView()}</>;
}

export default StepView;
