import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";
import { useGameCacheContext } from "./GameCacheProvider";

/**
 * @type {{
 *  stepNavigation: [{
 *      label: string,
 *      path: string,
 *      active: boolean,
 *  }],
 *  getActive: Function,
 *  getActivePath: Function
 * }}
 */
const StepNavigationContext = createContext();
/**
 * @type {Function}
 */
const StepNavigationDispatchContext = createContext();

/**
 * StepNavigationReducer with navigation actions
 * @param {[{path: string, label: string}]} stepNavigation
 * @param {{type: String, path: String, label: String}} action
 * @returns updated stepNavigation
 */
function StepNavigationReducer(stepNavigation, action) {
    const newStepNavigation = stepNavigation.map((nav) => {
        return { ...nav };
    });
    const findActive = (stepNav) => {
        const rev = stepNav.slice().reverse();
        return rev[rev.findIndex((nav) => nav.active === true)];
    };
    const currentActive = findActive(newStepNavigation);
    const currentIndex = newStepNavigation.findIndex(
        (nav) => nav.path === currentActive.path
    );
    switch (action.type) {
        case "next": {
            if (newStepNavigation[currentIndex + 1]) {
                newStepNavigation[currentIndex + 1].active = true;
            }
            return newStepNavigation;
        }
        case "to": {
            if (action.path) {
                const toIndex = newStepNavigation.findIndex(
                    (nav) => nav.path === action.path
                );
                if (toIndex !== -1) {
                    newStepNavigation.forEach(
                        (nav, index) => (nav.active = index <= toIndex)
                    );
                }
            }
            return newStepNavigation;
        }
        case "back": {
            if (currentIndex !== 0) {
                currentActive.active = false;
            }
            return newStepNavigation;
        }
        default: {
            throw Error("Unknown action: " + action.type);
        }
    }
}

/**
 * Provider (context) for the step navigation.
 * @param {React.ReactNode} children
 * @returns StepNavigationContext component
 */
export function StepNavigationProvider({ children }) {
    const [stepNavigation, dispatch] = useReducer(StepNavigationReducer, [
        {
            label: "stepnav.upload",
            path: "upload",
            active: true,
        },
        {
            label: "stepnav.metaData",
            path: "metadata",
            active: false,
        },
        {
            label: "stepnav.editRounds",
            path: "pgnedit",
            active: false,
        },
    ]);

    /**
     * @returns All paths that are "active"
     */
    const getActive = useCallback(
        () => stepNavigation.filter((route) => route.active === true),
        [stepNavigation]
    );
    /**
     * @returns The active "path" (Last Object in the navigation Array with the property "active: true")
     */
    const getActivePath = useCallback(() => {
        const filtered = stepNavigation.filter(
            (route) => route.active === true
        );
        return filtered[filtered.length - 1];
    }, [stepNavigation]);

    const context = useMemo(
        () => ({
            stepNavigation,
            getActive,
            getActivePath,
        }),
        [stepNavigation, getActive, getActivePath]
    );

    const { cache, cacheLoad } = useGameCacheContext();

    useEffect(() => {
        if (cacheLoad === false && Object.keys(cache).length > 0) {
            if (cache.navigation) {
                dispatch({
                    ...cache.navigation,
                    type: "to",
                });
            } else if(cache.pgnString) {
                dispatch({
                    path: "pgnedit",
                    type: "to",
                });
            } else {
                dispatch({
                    path: "metadata",
                    type: "to",
                });
            }
        }
    }, [cache, cacheLoad]);

    return (
        <StepNavigationContext.Provider value={context}>
            <StepNavigationDispatchContext.Provider value={dispatch}>
                {children}
            </StepNavigationDispatchContext.Provider>
        </StepNavigationContext.Provider>
    );
}

/**
 * @returns {StepNavigationContext}
 */
export const useStepNavigationContext = () => {
    return useContext(StepNavigationContext);
};

/**
 * @returns {StepNavigationDispatchContext}
 */
export const useStepNavigationDispatchContext = () => {
    return useContext(StepNavigationDispatchContext);
};

export default StepNavigationContext;
