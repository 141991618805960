import Icon from "../Icon";

/**
 * Default button component for this application.
 * @param {React.ReactNode} props.children
 * @param {string} props.title tooltip
 * @param {Function} props.onClick click handler
 * @param {string} props.className additional classes
 * @param {string} props.icon button icon
 * @param {string} props.iconSize icon size
 * @param {string} props.iconCls icon css classes
 * @param {boolean} props.invert outline button
 * @param {boolean} props.minimal style button similar to a link
 * @param {boolean} props.active
 * @param {boolean} props.disabled disable button
 * @param {string} props.icon name of optional icon to display
 * @returns button
 */
function Button({
    children,
    title,
    onClick,
    className,
    icon,
    iconSize,
    iconCls,
    invert,
    minimal,
    active,
    disabled,
    textColor,
    bgColor,
    ...props
}) {
    const invertStyles =
        "outline outline-1 py-[11px] outline-pgn-primary border border-2 border-solid hover:outline-pgn-primary active:outline-pgn-primary transition disabled:text-gray-300 disabled:outline-gray-300 " +
        (active ? "border-pgn-primary" : "border-transparent");
    const minimalStyles =
        "opacity-80 hover:opacity-100 active:text-bold disabled:text-gray-300";
    const defaultStyles =
        "border border-pgn-primary bg-opacity-90 hover:bg-opacity-100 active:bg-opacity-100 disabled:border-gray-300 disabled:bg-gray-300";

    const classes = invert
        ? invertStyles
        : minimal
        ? minimalStyles
        : defaultStyles;

    const color = textColor
        ? textColor
        : invert
        ? "text-pgn-primary"
        : minimal
        ? "text-pgn-primary"
        : "text-white";

    const backgroundColor = bgColor
        ? bgColor
        : invert
        ? "bg-white"
        : minimal
        ? "bg-transparent"
        : "bg-pgn-primary";

    return (
        <button
            className={[
                "inline-flex gap-2 p-3 rounded-md cursor-pointer disabled:cursor-default items-center",
                classes,
                color,
                backgroundColor,
                className,
            ].join(" ")}
            type="button"
            onClick={onClick}
            title={title}
            disabled={disabled}
            {...props}
        >
            {icon && <Icon name={icon} className={iconCls} size={iconSize} />}
            {children}
        </button>
    );
}

export default Button;
