import { useTranslation } from "react-i18next";
import Button from "../../../shared/components/buttons/Button";
import { useGameDataContext } from "../../../shared/stepview/context/GameDataProvider";
import { GameLogic } from "pgn-app-client";
import useGameLogic from "../../hooks/useGameLogic";

/**
 * Reset button to reset the "pgnedit" process.
 * @param {string} props.className optional additional css classes
 * @param {string} props.text button text
 * @param {boolean} props.minimal
 * @returns {Button}
 */
function ResetButton({ className = "", text, minimal = true }) {
    const { t } = useTranslation();
    const { player_1, player_2, setGameLogic } = useGameDataContext();
    const { updateGameLogic } = useGameLogic();

    const buttonText =
        typeof text === "string" || text instanceof String
            ? t(text)
            : t("step3.resetEdit");

    /**
     * Reset to a clean gameLogic state. If the user is "editing" and wants to see that state
     * he has to "edit" again.
     */
    const onReset = () => {
        setGameLogic(
            new GameLogic(player_1.data, player_2.data, updateGameLogic)
        );
    };

    return (
        <Button
            type="button"
            minimal={minimal}
            invert={!minimal}
            icon="reset"
            onClick={onReset}
            className={className}
        >
            {buttonText}
        </Button>
    );
}

export default ResetButton;
