import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../shared/components/Icon";
import Button from "../../shared/components/buttons/Button";

/**
 *
 * @param {Function} onSearch
 * @param {Function} onRemoveSearch
 * @param {[string]} filters
 * @returns searchfield (input)
 */
export default function SearchField({ onSearch, onRemoveSearch, filters }) {
    const { t } = useTranslation();
    const [query, setQuery] = useState("");

    const search = () => {
        if (query !== "" && filters.length < 4) {
            if (onSearch instanceof Function) {
                onSearch(query);
            }
            setQuery("");
        }
    };

    /**
     * on search button click
     */
    const onClick = () => {
        search();
    };

    const onKeyUp = (event) => {
        if (event.keyCode === 13) {
            search();
        }
    };

    const onDelete = (filter) => {
        if (onRemoveSearch instanceof Function) {
            onRemoveSearch(filter);
        }
    };

    return (
        <div className="flex align-middle w-full max-w-[] p-1 gap-1 border border-grey">
            <div className="flex align-middle gap-1">
                {filters.map((filter, index) => (
                    <div
                        key={`filter-${filter.toLowerCase()}-${index}`}
                        className="flex items-center align-middle justify-center gap-2 p-2 bg-slate-100"
                    >
                        <span>{filter}</span>
                        <span
                            onClick={() => onDelete(filter)}
                            className="cursor-pointer"
                        >
                            <Icon name="close" />
                        </span>
                    </div>
                ))}
            </div>
            <input
                type="text"
                className="w-full border-none outline-none"
                name="search_query"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                placeholder={t("step2.templates.modal.search")}
                onKeyUp={(e) => onKeyUp(e)}
            />
            <Button
                minimal
                icon="search"
                onClick={onClick}
                iconCls="text-gray-600"
                iconSize={6}
            />
        </div>
    );
}
