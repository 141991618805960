import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppProvider";
import useCreateRequest from "../../api/useCreateRequest";
import Form from "../../shared/components/form/Form";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import { useStepNavigationDispatchContext } from "../../shared/stepview/context/StepNavigationProvider";
import { useGameCacheContext } from "../../shared/stepview/context/GameCacheProvider";

const fields = [
    { key: "event", value: "" },
    { key: "location", value: "" },
    { key: "dateRound", type: "date" },
    { key: "round", value: "", type: "number" },
    { key: "board", value: "", type: "number" },
    { key: "white", value: "" },
    { key: "black", value: "" },
    {
        key: "result",
        value: null,
        type: "radio",
        options: [
            { value: "1-0", label: "1 - 0" },
            { value: "1/2-1/2", label: "1/2 - 1/2" },
            { value: "0-1", label: "0 - 1" },
            { value: "*", label: "*" },
        ],
    },
];

/**
 *
 * @returns Form that handles the metadata
 */
export default function MetaDataForm() {
    const { t } = useTranslation();
    const { shared, user, setSnack, setShared } = useAppContext();
    const { setUpdateCache } = useGameCacheContext();
    const { metaData, folder, gameId, setLoading } = useGameDataContext();
    const navigate = useStepNavigationDispatchContext();

    const { createPut } = useCreateRequest();

    const handleSubmit = async () => {
        const defaultAppendFormData = {
            title: metaData?.event || "",
            status: "publish",
            meta: {
                _selected_header_data: JSON.stringify(metaData),
                _order_by_date_round: JSON.stringify(metaData.dateRound),
                _order_by_status: 0,
            },
        };

        if (!shared) {
            defaultAppendFormData.parent = folder;
            defaultAppendFormData.author = user.id;
        }
        const formData = metaData;
        const appendedFormData = { ...formData, ...defaultAppendFormData };

        try {
            setLoading(true);

            if (!shared) {
                createPut(`users/${user.id}`, { selected_folder: folder });
            }
            const data = await createPut(
                `chess_entity/${gameId}`,
                appendedFormData
            );

            if (data.author !== user.id) {
                setShared({
                    author: data.author,
                    parent: data.parent,
                });
            }

            if (data) {
                navigate({ type: "next" });
                setUpdateCache(true);
            }
        } catch (err) {
            setSnack({ message: t("error.putMeta"), isError: true });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            fields={fields.map((field) => {
                if (field.type === "date") {
                    if (metaData[field.key] && metaData[field.key]) {
                        const date = dayjs.unix(metaData[field.key]);
                        field.value = date.format("YYYY-MM-DD");
                    }
                } else {
                    field.value = metaData[field.key];
                }

                return field;
            })}
            onSubmit={handleSubmit}
            submitLabel={t("step2.submit")}
        />
    );
}
