import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export default function FullScreenImage({
  id,
  isOpen,
  close,
  children,
  title,
  description,
}) {
  return (
    <Modal
      modalId={id}
      open={isOpen}
      onClose={close}
      center
      aria-labelledby={title ? id + "-modal-title" : undefined}
      aria-describedby={description ? id + "-modal-description" : undefined}
      classNames={{
        overlay: "!bg-black !bg-opacity-90 backdrop-blur-sm",
        modal:
          "md:h-full !bg-transparent !shadow-none !m-0 !p-8 md:!w-[1000px] md:!max-w-full text-center",
        closeButton:
          "[&_path]:!fill-white -translate-x-1/2 -translate-y-1/2 md:mt-6",
      }}
    >
      <div className="h-full flex justify-center [&_img]:object-contain">
        {title && <h3>{title}</h3>}
        {description && (
          <p className="absolute opacity-0 z-[-1]">{description}</p>
        )}
        {children}
      </div>
    </Modal>
  );
}
