import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 *
 * @returns wether a language cookie is set
 */
export function useLanguageCookie() {
    const { i18n } = useTranslation();
    const [isSet, setIsSet] = useState(false);

    useEffect(() => {
        const cookies = document.cookie ? document.cookie.split(";") : [];
        const langCookie = cookies.find((cookie) =>
            cookie.trim().startsWith("wp-wpml_current_language")
        );

        if (langCookie && langCookie.split("=").length > 1) {
            const newLanguage = langCookie.split("=")[1];
            i18n.changeLanguage(newLanguage);
            setIsSet(true);
        } else {
            setIsSet(false);
        }
    }, [i18n]);

    return { current: i18n.resolvedLanguage, isLangSet: isSet };
}
