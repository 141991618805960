/**
 * Chess Transition component.
 * @param {string} props.className css classes
 */
function ChessTransition({ className }) {
    return (
        <>
            <span className={"animate-fade-out absolute " + className}>
                &#x2659;
            </span>
            <span className={"animate-fade-in absolute " + className}>
                &#x2655;
            </span>
        </>
    );
}

export default ChessTransition;
