import React from "react";
import { useTranslation } from "react-i18next";
import ComboBox from "../../shared/components/form/Combo";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import { getNotations } from "../../api/PGNVariables";

/**
 *
 * @param {String} props.name Name of the player
 * @returns Select field for notation language
 */
export default function NotationLangSelect({ name, disabled, ...props }) {
    const contextName = name ? name : "";
    const context = useGameDataContext();
    const { t } = useTranslation();

    const onChange = ({ value }) => {
        context.updatePlayer(contextName, { notation_lang: value });
    };

    const options = getNotations().map((notation) => ({
        label: notation,
        value: notation,
    }));

    return (
        <ComboBox
            name={`notation_lang`}
            label={t("notationLang")}
            options={options}
            value={context[name]["notation_lang"]}
            onChange={onChange}
            searchable={false}
            labelClass={"text-sm sm:pb-1" + (disabled ? " text-gray-400" : "")}
            disabled={disabled}
            {...props}
        />
    );
}
