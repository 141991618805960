import { HalfMoveSnapshot } from './HalfMoveSnapshot';
import { GameLogic } from '../GameLogic';
import { GameLogicWriterSnapshot } from './GameLogicWriterSnapshot';

/**
 * Readonly Snapshot eines Zustands eines zugehörigen {@linkcode GameLogic} zu einem bestimmten Zeitpunkt.
 */
export class GameLogicSnapshot {
  /**
   * Siehe {@linkcode GameLogic.writer1}
   */
  public readonly writer1: GameLogicWriterSnapshot;

  /**
   * Siehe {@linkcode GameLogic.writer2}
   */
  public readonly writer2?: GameLogicWriterSnapshot;

  /**
   * Siehe {@linkcode GameLogic.halfMoves}
   */
  public readonly halfMoves: readonly HalfMoveSnapshot[];

  /**
   * Siehe {@linkcode GameLogic.bestBoardPgn}
   */
  public readonly bestBoardPgn?: string;

  /**
   * Siehe {@linkcode GameLogic.filtered}
   */
  public readonly filtered: boolean;

  /**
   * Siehe {@linkcode GameLogic.selectedHalfMove}
   */
  public readonly selectedHalfMove: HalfMoveSnapshot;

  /**
   * @private
   * Siehe {@linkcode GameLogic.constructor}
   */
  constructor(
    gameLogic: GameLogic,
    halfMoves: readonly HalfMoveSnapshot[],
    selectedHalfMove: HalfMoveSnapshot
  ) {
    this.halfMoves = halfMoves;
    this.writer1 = new GameLogicWriterSnapshot(gameLogic.writer1);

    if (gameLogic.writer2)
      this.writer2 = new GameLogicWriterSnapshot(gameLogic.writer2);

    this.bestBoardPgn = gameLogic.bestBoardPgn;
    this.filtered = gameLogic.filtered;
    this.selectedHalfMove = selectedHalfMove;
  }
}
