import { Chess } from "chess.js";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Chessboard } from "react-chessboard";
import { useAppContext } from "../AppProvider";
import { useGameDataContext } from "../shared/stepview/context/GameDataProvider";
import useGameLogic from "./hooks/useGameLogic";

/**
 * Chessboard preview component using {@link https://github.com/Clariity/react-chessboard react-chessboard}
 * for UI representation and {@link https://github.com/jhlywa/chess.js chess.js} for the chess logic.
 * @returns
 */
function ChessPreview() {
    const { setSnack } = useAppContext();
    const { gameLogicSnapshot, expandMobileOverlay, setExpandMobileOverlay } =
        useGameDataContext();

    const [game, setGame] = useState(new Chess());
    const [chessBoardId, setChessBoardId] = useState(new Date().valueOf());
    const { lastMoveSelected } = useGameLogic();

    /**
     * Method to determine wether the pieces on the chessboard are draggable/moveable
     * by the user.
     * @returns {boolean}
     */
    const canMovePieces = () => {
        if (gameLogicSnapshot == null) return false;
        const { selectedHalfMove: move } = gameLogicSnapshot;

        return gameLogicSnapshot && lastMoveSelected && move ? true : false;
    };

    /**
     * Method to handle a chess piece drop on the chessboard.
     * @param {string} sourceSquare from move
     * @param {string} targetSquare to move
     * @param {string} piece piece color and name
     * @returns {boolean}
     */
    const onDrop = (sourceSquare, targetSquare, piece) => {
        const { selectedHalfMove } = gameLogicSnapshot;
        const clonedGame = new Chess(game.fen());
        let move;
        try {
            move = clonedGame.move({
                from: sourceSquare,
                to: targetSquare,
                promotion: getPromotionPiece(piece || ""),
            });
        } catch (error) {
            setSnack({
                message: t("error.chessMove", {
                    from: sourceSquare,
                    to: targetSquare,
                }),
                isError: true,
            });
            move = null;
        }

        if (move === null) return false;

        selectedHalfMove.setSanFixed(move.san);
        setExpandMobileOverlay(false);

        return true;
    };

    /**
     * Get promotion piece.
     * @param {string} piece
     * @returns {string}
     */
    const getPromotionPiece = (piece) => {
        switch (piece.toLowerCase()) {
            case "bq":
            case "wq":
                return "q";
            case "wn":
            case "bn":
                return "n";
            case "wr":
            case "br":
                return "r";
            case "wb":
            case "bb":
                return "b";
            default:
                return "";
        }
    };

    useEffect(() => {
        const chess = new Chess();

        if (gameLogicSnapshot !== null && gameLogicSnapshot.bestBoardPgn) {
            const { bestBoardPgn } = gameLogicSnapshot;
            const { selectedHalfMove: move } = gameLogicSnapshot;
            if (move?.bestBoardFenBefore) {
                chess.load(move.bestBoardFenBefore);
            } else if (bestBoardPgn) {
                chess.loadPgn(bestBoardPgn);
            }
        }

        setGame(chess);
    }, [gameLogicSnapshot]);

    useEffect(() => {
        if (expandMobileOverlay) {
            setChessBoardId(new Date().valueOf());
        }
    }, [expandMobileOverlay]);

    return (
        <div className="flex w-full max-w-lg sm:max-w-xl self-center tablet:self-stretch">
            <Chessboard
                key={chessBoardId}
                position={game.fen()}
                arePiecesDraggable={canMovePieces()}
                onPieceDrop={onDrop}
                customDarkSquareStyle={{ backgroundColor: "#10B981" }}
                customLightSquareStyle={{ backgroundColor: "#F3F4F6" }}
            />
        </div>
    );
}

export default ChessPreview;
