import { getAjaxNonce } from "./PGNVariables";

function useCreateRequest() {
    /**
     * @typedef {Object} ReqOptionsDef
     * @property {string} title
     * @property {string} content
     * @property {string} parentId
     */

    /**
     * @param {string} slug
     * @param {string} mode
     * @param {ReqOptionsDef} options
     * @returns {Promise}
     */
    const request = async (slug, mode, options) => {
        try {
            if (slug) {
                const nonce = getAjaxNonce();
                const endpoint = `/wp-json/wp/v2/${slug}`;

                const response = await fetch(endpoint, {
                    method: mode,
                    headers: {
                        "Content-Type": "application/json",
                        "X-WP-Nonce": nonce,
                    },
                    body: JSON.stringify({
                        ...options,
                    }),
                    credentials: "include",
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                return data;
            } else {
                throw new Error(`Invalid api endpoint: paramter slug is empty`);
            }
        } catch (err) {
            console.error("Error creating post:", err);
        }
    };

    /**
     * @param {ReqOptionsDef} postData
     * @returns {Promise}
     */
    const adminPost = async (postData) => {
        try {
            const nonce = getAjaxNonce();

            const searchParams = new URLSearchParams();

            for (const [key, value] of Object.entries(postData)) {
                searchParams.append(key, value);
            }

            searchParams.append("sec", nonce);

            const response = await fetch(
                process.env.REACT_APP_WP_API_ADMIN_ENDPOINT,
                {
                    method: "POST",
                    headers: {
                        "Context-Type": "application/x-www-form-urlencoded",
                        "X-WP-Nonce": nonce,
                    },
                    body: searchParams,
                    credentials: "include",
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (err) {
            console.error("Error creating post:", err);
        }
    };

    /**
     * @param {string} slug
     * @param {ReqOptionsDef} options
     * @returns {Promise}
     */
    const createPost = async (slug, options) => {
        return request(slug, "POST", options);
    };

    /**
     * @param {string} slug
     * @param {ReqOptionsDef} options
     * @returns {Promise}
     */
    const createPut = async (slug, options) => {
        return request(slug, "PUT", options);
    };

    return { adminPost, createPost, createPut };
}

export default useCreateRequest;
