import { useEffect, useRef } from "react";
import Icon from "./Icon";
import { useClickOutside } from "../hooks/useClickOutside";

/**
 * Snackbar displaying messages or errors.
 * @param {string} props.message snackbar message
 * @param {boolean} props.error is snackbar displaying an error (no auto close, active close via "x")
 * @param {Function} props.onClose snackbar close function
 * @returns snackbar
 */
function Snackbar({ message, error, onClose }) {
    const snackbarRef = useRef(null);

    useClickOutside(snackbarRef, () => {
        if (onClose) {
            onClose();
        }
    });

    useEffect(() => {
        if (message !== "" && error === false) {
            setTimeout(() => {
                if (onClose) {
                    onClose();
                }
            }, 5000);
        }
    }, [message, error, onClose]);

    return (
        <div
            className={
                "z-20 flex fixed justify-between gap-4 p-4 " +
                "w-10/12 tiny:w-11/12 sm:w-[350px] shadow-md max-w-full " +
                "right:2 sm:right-4 bottom-2 sm:bottom-4 left-2 sm:left-[initial] " +
                "rounded-sm whitespace-nowrap bg-white border " +
                (error ? "border-[#991B1B] text-[#991B1B]" : "")
            }
            ref={snackbarRef}
        >
            <div className="flex whitespace-pre-line overflow-hidden">
                {message}
            </div>
            {error && (
                <div className="relative top-1 right-1 w-4">
                    <Icon
                        name="close"
                        className="cursor-pointer"
                        onClick={onClose}
                    />
                </div>
            )}
        </div>
    );
}

export default Snackbar;
