import { useCallback, useRef } from "react";
import { base64ToFile, FileToBase64 } from "../images/ImageConverter";
import { useGameDataContext } from "../stepview/context/GameDataProvider";
import { useStepNavigationContext } from "../stepview/context/StepNavigationProvider";
import dayjs from "dayjs";

/**
 * Hook with functions to handle the {@link localStorage}.
 * @returns {{
 *  loadStorage: Function,
 *  storeContext: Function,
 *  clearStorage: Function,
 *  addToStorage: Function,
 *  hasStorage: Function,
 *  getStorage: Function,
 *  removeStorage: Function
 * }}
 */
function useLocalStorage() {
    const navContext = useStepNavigationContext();
    const context = useGameDataContext();

    const contextProps = useRef([
        "player_1",
        "player_2",
        "metaData",
        "gameId",
        "folder",
        "pgnString",
    ]);

    /**
     * Method to invoke {@link localStorage.setItem}.
     * @param {String} key
     * @param {any} toStore
     */
    const addToStorage = useCallback((key, toStore) => {
        if (key && toStore) {
            localStorage.setItem(key, JSON.stringify(toStore));
        }
    }, []);

    /**
     * @param {String} key
     * @returns {Boolean}
     */
    const hasStorage = useCallback(
        (key) => localStorage.getItem(key) !== null,
        []
    );

    /**
     * @param {String} key
     * @returns {any}
     */
    const getStorage = useCallback(
        (key) => JSON.parse(localStorage.getItem(key)),
        []
    );

    /**
     * Method to invoke {@link localStorage.removeItem}.
     * @param {String} key
     */
    const removeStorage = useCallback(
        (key) => localStorage.removeItem(key),
        []
    );

    /**
     * Load local storage.
     * @return {{}}
     */
    const loadStorage = useCallback(() => {
        return [...contextProps.current, "navigation", "modified"].reduce((acc, key) => {
            if (hasStorage(key)) {
                if (key.includes("player")) {
                    const player = getStorage(key);
                    const files = base64ToFile(player.files);

                    files.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    );

                    acc[key] = { ...player, files: files };
                } else if(key === "folder") {
                    acc["parent"] = getStorage(key);
                } else {
                    acc[key] = getStorage(key);
                }
            }
            return acc;
        }, {});
    }, [contextProps, hasStorage, getStorage]);

    /**
     * Method to store the {@link useGameDataContext} into the localStorage.
     */
    const storeContext = useCallback(async () => {
        if (context && navContext) {
            contextProps.current.forEach(async (key) => {
                if (key.includes("player")) {
                    const player = context[key];
                    const images = await FileToBase64(player.files);
                    return addToStorage(key, { ...player, files: images });
                }
                return addToStorage(key, context[key]);
            });
            addToStorage("navigation", navContext.getActivePath());
            addToStorage("modified", dayjs().unix())
        }
    }, [context, navContext, addToStorage]);

    /**
     * Method to invoke {@link localStorage.removeItem} for the {@link useGameDataContext}
     */
    const clearStorage = useCallback(
        () =>
            [...contextProps.current, "navigation"].forEach((key) =>
                removeStorage(key)
            ),
        [contextProps, removeStorage]
    );

    return {
        loadStorage,
        storeContext,
        clearStorage,
        addToStorage,
        hasStorage,
        getStorage,
        removeStorage,
    };
}

export default useLocalStorage;
