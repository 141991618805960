import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useCache from "../../hooks/useCache";
import { useAppContext } from "../../../AppProvider";


/**
 * @type {{
*  cache: Object,
*  cacheLoad: boolean,
*  error: string,
*  updateCache: boolean,
*  setUpdateCache: Function,
* }}
*/
const GameCacheContext = createContext();
/**
 * Caching context.
 * @param {GameCacheContext.Provider} param0
 * @returns
 */
function GameCacheProvider({ children }) {
    const { setSnack } = useAppContext();

    /**
     * @type {Boolean} updateCache
     */
    const [updateCache, setUpdateCache] = useState(false);

    const { cache, cacheLoad, error } = useCache();

    useEffect(() => {
        if (error) {
            setSnack({ message: error, isError: true });
        }
    }, [setSnack, error]);

    const context = useMemo(
        () => ({
            cache,
            cacheLoad,
            error,
            updateCache,
            setUpdateCache,
        }),
        [cache, cacheLoad, error, updateCache]
    );

    return (
        <GameCacheContext.Provider value={context}>
            {children}
        </GameCacheContext.Provider>
    );
}

/**
 * @returns {GameCacheContext}
 */
export const useGameCacheContext = () => {
    return useContext(GameCacheContext);
};

export default GameCacheProvider;
