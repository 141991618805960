import { HalfMove } from '../HalfMove';
import { Color } from '../Color';
import { CommandSnapshot } from './CommandSnapshot';
import { HalfMoveWriterSnapshot } from './HalfMoveWriterSnapshot';

/**
 * Snapshot-Klasse korrespondierend zu der {@linkcode HalfMove}-Klasse
 *
 * Wird verwendet in {@linkcode GameLogicSnapshot.halfMoves}
 */
export class HalfMoveSnapshot {
  /**
   * Siehe {@linkcode HalfMove.color}
   */
  public readonly color: Color;

  /**
   * Siehe {@linkcode HalfMove.writer1}
   */
  public readonly writer1: HalfMoveWriterSnapshot;

  /**
   * Siehe {@linkcode HalfMove.writer2}
   */
  public readonly writer2?: HalfMoveWriterSnapshot;

  /**
   * Siehe {@linkcode HalfMove.halfMoveIndex}
   */
  public readonly halfMoveIndex: number;

  /**
   * Siehe {@linkcode HalfMove.halfMoveNo}
   */
  public readonly halfMoveNo: number;

  /**
   * Siehe {@linkcode HalfMove.commands}
   */
  public readonly commands: readonly CommandSnapshot[];

  /**
   * Siehe {@linkcode HalfMove.confident}
   */
  public readonly confident: boolean;

  /**
   * Siehe {@linkcode HalfMove.bestBoardFenAfter}
   */
  public readonly bestBoardFenAfter: string | undefined;

  /**
   * Siehe {@linkcode HalfMove.bestBoardFenBefore}
   */
  public readonly bestBoardFenBefore: string | undefined;

  /**
   * Siehe {@linkcode HalfMove.possibleMoves}
   */

  public readonly possibleMoves: readonly string[];

  /**
   * Siehe {@linkcode HalfMove.san}
   */
  public readonly san: string;

  /**
   * Siehe {@linkcode HalfMove.sanFixed}
   */
  public readonly sanFixed: string | undefined;

  /**
   * @private
   * Siehe {@linkcode HalfMove.constructor}
   */
  constructor(private halfMove: HalfMove) {
    this.color = halfMove.color;
    this.writer1 = new HalfMoveWriterSnapshot(halfMove.writer1);
    if (halfMove.writer2)
      this.writer2 = new HalfMoveWriterSnapshot(halfMove.writer2);
    this.halfMoveIndex = halfMove.halfMoveIndex;
    this.halfMoveNo = halfMove.halfMoveNo;
    this.commands = halfMove.commands.map((x) => new CommandSnapshot(x));
    this.confident = halfMove.confident;
    this.bestBoardFenAfter = halfMove.bestBoardFenAfter;
    this.bestBoardFenBefore = halfMove.bestBoardFenBefore;
    this.possibleMoves = halfMove.possibleMoves;
    this.san = halfMove.san;
    this.sanFixed = halfMove.sanFixed;
  }

  public setSanFixed(sanFixed: string | undefined) {
    this.halfMove.sanFixed = sanFixed;
  }
}
