/**
 * @typedef {object} PGNVars
 * @property {UserDef} user-data
 * @property {string} ajax-nonce
 * @property {string[]} notations
 */

/**
 * @typedef {object} UserDef
 * @property {number} id
 * @property {boolean} loggedIn
 * @property {string} username
 * @property {number} credits
 * @property {number[]} defaultFolderId
 * @property {number[]} selectedFolderId
 */

/**
 * @returns {PGNVars}
 */
const getPGNVars = () => {
    if (window.pgnvars) {
        return window.pgnvars;
    } else {
        return { "user-data": {} };
    }
};
/**
 * Function to return the user-data.
 * @returns {UserDef}
 */
export const getUserData = () => {
    if (process.env.NODE_ENV === "development") {
        return {
            id: 11,
            loggedIn: true,
            username: "loy.white",
            credits: 50,
            defaultFolderId: [151],
            sharedFolders: []
        };
    } else {
        return getPGNVars()["user-data"];
    }
};
/**
 * Function to return the ajax-none.
 * @returns {string}
 */
export const getAjaxNonce = () => {
    if (process.env.NODE_ENV === "development") {
        return "qwerf123456";
    } else {
        return getPGNVars()["ajax-nonce"];
    }
};
/**
 * Function to return the notations (notation languages).
 * @returns {string[]}
 */
export const getNotations = () => {
    const notations = getPGNVars()["notations"];
    return Array.isArray(notations) ? notations : [];
};
