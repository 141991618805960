import { useEffect, useState } from "react";
import tailwindTheme from "../../theme";


/**
 * Hook that retrieves responsive breakpoints in relation to tailwind.
 * Can be used with a {@link Function callback} or a state.
 * 
 * @param {Function} onUpdate
 */
const useResponsive = (onUpdate) => {
    const [breakpoint, setCurrentBreakpoint] = useState(null);

    useEffect(() => {
        /**
         * Debounce resize method to avoid too many methos calls.
         * @param {Function} func 
         * @param {number} time 
         * @returns 
         */
        function debounceResize(func, time) {
            const timeout = time || 250;
            let timer;
            return function (event) {
                if (timer) clearTimeout(timer);
                timer = setTimeout(func, timeout, event);
            };
        }

        /**
         * update breakpoint state in relation to screen.
         */
        const updateBreakpoint = () => {
            const windowWidth = window.innerWidth;
            const breakpoint = Object.keys(tailwindTheme.screens).find(
                (key) => {
                    const breakpointNum = Number(
                        tailwindTheme.screens[key].split("px")[0]
                    );
                    return windowWidth < breakpointNum;
                }
            );

            setCurrentBreakpoint(breakpoint);

            if (onUpdate) {
                onUpdate(breakpoint);
            }
        };

        debounceResize(updateBreakpoint);
        window.addEventListener("resize", debounceResize(updateBreakpoint));

        return () => {
            window.removeEventListener(
                "resize",
                debounceResize(updateBreakpoint)
            );
        };
    }, [onUpdate]);

    return breakpoint;
};

export default useResponsive;
