import { useEffect } from "react";

/**
 * This Hook can be used to detect clicks outside of the referenced element
 * @param {Element} ref Reference to element
 * @param {fuction} onClickOutside Function to call, if something outside of the element is clicked
 */
export function useClickOutside(ref, onClickOutside) {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}
