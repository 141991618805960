import { useTranslation } from "react-i18next";

/**
 * @returns component that displays the game title
 */
function GameTitle() {
    const { t } = useTranslation();
    return (
        <h1 className="mx-auto w-full max-w-7xl p-4 pb-2 text-4xl text-emerald-500 hidden sm:block">
            {t("newGame")}
        </h1>
    );
}

export default GameTitle;
