import { RowLocation } from '../RowLocation';
import { MoveShift } from '../MoveShift';
import { HalfMoveWriter } from '../HalfMoveWriter';

/**
 * Snapshot-Klasse korrespondierend zu der {@linkcode HalfMoveWriter}-Klasse
 *
 * Wird verwendet in {@linkcode HalfMoveSnapshot.writer1} und {@linkcode HalfMoveSnapshot.writer2}
 */
export class HalfMoveWriterSnapshot {
  /**
   * Siehe {@linkcode HalfMoveWriter.rowLocation}
   */
  public readonly rowLocation: RowLocation | undefined;

  /**
   * Siehe {@linkcode HalfMoveWriter.shift}
   */
  public readonly shift: MoveShift;

  /**
   * Siehe {@linkcode HalfMoveWriter.shiftUpAllowed}
   */
  public readonly shiftUpAllowed: boolean;

  /**
   * Siehe {@linkcode HalfMoveWriter.shiftDownAllowed}
   */
  public readonly shiftDownAllowed: boolean;

  /**
   * @private
   * Siehe {@linkcode HalfMoveWriter.constructor}
   */
  constructor(private halfMoveWriter: HalfMoveWriter) {
    this.rowLocation = halfMoveWriter.rowLocation;
    this.shift = halfMoveWriter.shift;
    this.shiftUpAllowed = halfMoveWriter.shiftUpAllowed;
    this.shiftDownAllowed = halfMoveWriter.shiftDownAllowed;
  }

  /**
   * Siehe {@linkcode HalfMoveWriter.setAsLastMove}
   *
   * Hinweis. Diese Methode leitet nur den Funktionsaufruf an die korrespondierende
   * Methode weiter, sie verändert nicht den Status dieses Objekts.
   */
  public setAsLastMove() {
    this.halfMoveWriter.setAsLastMove();
  }

  /**
   * Siehe {@linkcode HalfMoveWriter.shiftUp}
   *
   * Hinweis. Diese Methode leitet nur den Funktionsaufruf an die korrespondierende
   * Methode weiter, sie verändert nicht den Status dieses Objekts.
   */
  public shiftUp() {
    this.halfMoveWriter.shiftUp();
  }

  /**
   * Siehe {@linkcode HalfMoveWriter.shiftDown}
   *
   * Hinweis. Diese Methode leitet nur den Funktionsaufruf an die korrespondierende
   * Methode weiter, sie verändert nicht den Status dieses Objekts.
   */
  public shiftDown() {
    this.halfMoveWriter.shiftDown();
  }
}
