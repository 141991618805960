import Select from "react-select";
import { components } from "react-select";
import Icon from "../Icon";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon name="angleBottom" size={6} />
        </components.DropdownIndicator>
    );
};
const createValueContainer = (icon) => {
    return ({ children, ...props }) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    <Icon
                        name={icon}
                        size={6}
                        className="absolute left-0 top-0.5"
                    />
                    {children}
                </components.ValueContainer>
            )
        );
    };
};

const controlClasses = {
    base: "border disabled:bg-gray-300 disabled:border-gray-300 rounded-md px-2 sm:px-3 py-1 sm:py-2",
    disabled: "bg-gray-100 border-gray-300 text-gray-400",
};
const dropdownIndicatorClasses = "cursor-pointer hover:text-black";
const menuListClasses = "bg-white border border-gray-300 shadow-md rounded";
const groupClasses = "border-b-[1.5px] border-gray-300";
const optionClasses = {
    base: "p-4 cursor-pointer",
    select: "bg-white text-black hover:text-black",
};

/**
 * @param {{label: string, value: any}[]} props.options select options
 * @param {boolean} props.isMulti multiple values can be selected
 * @param {boolean} props.isGrouped has grouped options
 * @param {boolean} props.searchable options can be searched via the input
 * @param {boolean} props.disabled select is disabled
 * @param {string} props.name select name
 * @param {string} props.label label value
 * @param {string} props.labelClass css classes for the label
 * @param {string} props.placeholder placeholder label to display if no option is selected
 * @param {string} props.icon icon for the react-select input
 * @param {string} props.value default value
 * @param {Function} props.onChange change handler
 * @param {string} props.theme "default" or "pgn". Defaults to "default"
 * @returns Select (react-select) with label
 */
function ComboBox({
    options = [],
    isMulti = false,
    isGrouped = false,
    searchable = true,
    disabled,
    name = "",
    label = "",
    labelClass = "",
    placeholder = "",
    icon = "",
    value,
    onChange,
    theme = "default",
    ...props
}) {
    const components = {
        DropdownIndicator: DropdownIndicator,
        ...(icon && { ValueContainer: createValueContainer(icon) }),
    };

    const classes = () => {
        switch (theme.toLowerCase()) {
            case "default":
                return {
                    control: ({ isDisabled }) =>
                        [
                            controlClasses.base,
                            isDisabled
                                ? controlClasses.disabled
                                : "border-gray-400 text-black",
                        ].join(" "),
                    dropdownIndicator: () => dropdownIndicatorClasses,
                    valueContainer: () => (icon ? "pl-7" : ""),
                    menuList: () => menuListClasses,
                    group: () => groupClasses,
                    option: ({ isFocused, isSelected }) =>
                        [
                            optionClasses.base,
                            isFocused
                                ? "active:bg-gray-400 active:text-white"
                                : "",
                            isSelected
                                ? "bg-gray-400 text-white"
                                : `${optionClasses.select} hover:bg-gray-200`,
                        ].join(" "),
                };
            case "pgn":
                return {
                    control: ({ isDisabled }) =>
                        [
                            controlClasses.base,
                            isDisabled
                                ? controlClasses.disabled
                                : "border-pgn-primary text-pgn-primary",
                        ].join(" "),
                    dropdownIndicator: () => dropdownIndicatorClasses,
                    valueContainer: () => (icon ? "pl-7" : ""),
                    menuList: () => menuListClasses,
                    group: () => groupClasses,
                    option: ({ isFocused, isSelected }) =>
                        [
                            optionClasses.base,
                            isFocused
                                ? "active:bg-emerald-500 active:text-white"
                                : "",
                            isSelected
                                ? "bg-emerald-500 text-white"
                                : `${optionClasses.select} hover:bg-emerald-50`,
                        ].join(" "),
                };
            default:
                return;
        }
    };

    /**
     * Function to retrieve the selected option.
     * @returns {{label: string, value: number|string}}
     */
    const getSelected = () => {
        let allOptions = options;
        
        if (isGrouped) {
            allOptions = options.reduce((acc, curr) => {
                if (curr && Array.isArray(curr.options)) {
                    acc = [...acc, ...curr.options];
                }
                return acc;
            }, []);
        }
        return allOptions.find((option) => option.value === value);
    };

    return (
        <div className={"flex flex-col sm:gap-1 mb-3 w-full"}>
            {label ? (
                <label htmlFor={name} className={labelClass}>
                    {label}
                </label>
            ) : (
                ""
            )}
            <Select
                options={options}
                name={name}
                id={name}
                isMulti={isMulti}
                isSearchable={searchable}
                isDisabled={disabled}
                placeholder={placeholder}
                value={getSelected()}
                onChange={onChange}
                unstyled
                components={components}
                classNames={classes()}
                {...props}
            />
        </div>
    );
}

export default ComboBox;
