import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import { useTabContext } from "../../shared/stepview/context/TabProvider";
import ChooseLastMoveRow from "./ChooseLastMoveRow";
import Row from "./Row";
import Toolbar from "./Toolbar";
import PGNButtons from "./buttons/PGNButtons";

/**
 *
 * @returns View to choose the last move for both writers.
 */
function ChooseLastMove() {
    const { t } = useTranslation();
    const { player_1, player_2, gameLogicSnapshot } = useGameDataContext();

    /**
     * @type {number} showWriter 1 or 2
     */
    const [showWriter, setShowWriter] = useState(1);

    const { setTab, setP1ActiveImage, setP2ActiveImage } = useTabContext();

    const { halfMoves } = gameLogicSnapshot;

    useEffect(() => {
        const { writer1: { show: showWriter1 } = {} } = gameLogicSnapshot;
        const activeWriter = showWriter1 ? 1 : 2;
        setShowWriter(activeWriter);
    }, [gameLogicSnapshot]);

    useEffect(() => {
        const newActiveTab = `player_${showWriter}`;

        setTab(newActiveTab);
        if (newActiveTab === "player_1") {
            setP1ActiveImage(player_1.files.length - 1);
        } else if (newActiveTab === "player_2") {
            setP2ActiveImage(player_2.files.length - 1);
        }
    }, [
        player_1.files.length,
        player_2.files.length,
        showWriter,
        setTab,
        setP1ActiveImage,
        setP2ActiveImage,
    ]);

    return (
        <div className="flex flex-col gap-2">
            <h2>{t(`step3.lastMoveWriter${showWriter}`)}</h2>
            <Toolbar secondLabel={"filter.possibleMoves"} />
            <Row>
                {halfMoves.map((halfMove, i) => (
                    <ChooseLastMoveRow
                        key={i}
                        halfMove={halfMove}
                        disableBox={showWriter === 1 ? 1 : 0}
                    />
                ))}
            </Row>
            <PGNButtons />
        </div>
    );
}

export default ChooseLastMove;
