import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../AppProvider";
import Button from "../../../shared/components/buttons/Button";
import useGameLogic from "../../hooks/useGameLogic";

/**
 * Clipboard button to add a pgn string to the clipboard.
 * @param {string} props.text button text
 * @returns {Button}
 */
function ClipboardButton({ text }) {
    const { t } = useTranslation();
    const { setSnack } = useAppContext();
    const { createPgn } = useGameLogic();

    const buttonText =
        typeof text === "string" || text instanceof String
            ? t(text)
            : t("step3.pgnToClipboard");

    const onClick = async () => {
        let pgn = createPgn();

        if (pgn !== "") {
            navigator.clipboard.writeText(pgn).then(
                () =>
                    setSnack({
                        message: t("step3.clipboardSucess"),
                        isError: false,
                    }),
                () =>
                    setSnack({
                        message: t("step3.clipboardFailure"),
                        isError: true,
                    })
            );
        } else {
            setSnack({ message: t("step3.clipboardFailure"), isError: false });
        }
    };

    return (
        <Button type="button" minimal icon="copy" onClick={onClick}>
            {buttonText}
        </Button>
    );
}

export default ClipboardButton;
