import React, { useId } from "react";

import Icon from "../../shared/components/Icon";
import Button from "../../shared/components/buttons/Button";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import MoveBox from "./MoveBox";
import RowItem from "./RowItem";
import RowMoveNumber from "./RowMoveNumber";

/**
 *
 * @param {Object} props.move Move details
 * @returns
 */
export default function EditRow({ halfMove }) {
    const { gameLogicSnapshot } = useGameDataContext();

    const { selectedHalfMove: { halfMoveNo } = {} } = gameLogicSnapshot;
    const isSelected = halfMoveNo === halfMove.halfMoveNo;

    const rowItemId = `rowItem-${useId()}`;

    /**
     * Select halfMove row.
     * @param {React.MouseEvent<HTMLElement>}
     */
    const onRowClick = (event) => {
        if (event.target.id === rowItemId) {
            halfMove.halfMove.select();
        }
    };

    /**
     * Trigger {@link ICommand} execute.
     * @param {ICommand} command
     */
    const onSanFixed = (command) => {
        command.execute();
    };

    const multipleActions = halfMove.commands.length > 1;

    return (
        <RowItem
            className={isSelected ? "bg-emerald-50" : ""}
            focus={isSelected}
            onClick={onRowClick}
            id={rowItemId}
        >
            <div className="relative">
                <RowMoveNumber color={halfMove.color}>
                    {halfMove.halfMove.moveNo}
                </RowMoveNumber>
            </div>
            <div className="flex gap-2">
                {[halfMove.writer1, halfMove.writer2]
                    .filter((writer) => writer)
                    .map((writer, i) => {
                        return (
                            <MoveBox
                                key={i}
                                id={i}
                                writer={writer}
                                {...writer.halfMoveWriter.box}
                                onAdd={() => writer.shiftDown()}
                                onDelete={() => writer.shiftUp()}
                                hideActions={halfMoveNo !== halfMove.halfMoveNo}
                            />
                        );
                    })}
            </div>
            <div
                className={
                    multipleActions
                        ? "basis-full sm:hidden tablet:block tablet:basis-full lg:hidden h-0"
                        : "hidden"
                }
            ></div>
            <div
                className={
                    "flex h-6 w-6" +
                    (multipleActions ? "pl-4 sm:pl-0 tablet:pl-4 lg:pl-0" : "")
                }
            >
                <Icon name="arrowRight" size={6} className="mx-0" />
            </div>
            {halfMove.commands.map((command, i) => (
                <Button
                    key={i}
                    onClick={() => onSanFixed(command)}
                    invert
                    bgColor={command.prominent ? "bg-emerald-50" : ""}
                    className="pl-4 pr-4"
                    active={command.pressed}
                >
                    {command.text}
                </Button>
            ))}
        </RowItem>
    );
}
