import React from "react";

import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightBar } from "../../assets/icons/arrow-right-bar.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as NavCheck } from "../../assets/icons/nav-check.svg";
import { ReactComponent as FullScreen } from "../../assets/icons/fullscreen.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Flag } from "../../assets/icons/flag.svg";
import { ReactComponent as Pause } from "../../assets/icons/pause.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as Reset } from "../../assets/icons/reset.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as ChessBoard } from "../../assets/icons/chessboard.svg";
import { ReactComponent as InfoCircle } from "../../assets/icons/info-circle.svg";
import { ReactComponent as Files } from "../../assets/icons/files.svg";
import { ReactComponent as AngleRight } from "../../assets/icons/angle-right.svg";
import { ReactComponent as ImageUpload } from "../../assets/icons/image-upload.svg";
import { ReactComponent as ImagePlus } from "../../assets/icons/image-plus.svg";
import { ReactComponent as ImageMinus } from "../../assets/icons/image-minus.svg";
import { ReactComponent as Folder } from "../../assets/icons/folder.svg";
import { ReactComponent as CircleArrowRight } from "../../assets/icons/circle-arrow-right.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";

export default function Icon({ name, size = 4, className, ...otherProps }) {
    const props = {
        ...otherProps,
        className:
            "w-" + size + " h-" + size + " [&_path]:fill-current " + className,
    };
    switch (name) {
        case "arrowBottom":
            return (
                <ArrowLeft
                    {...props}
                    className={props.className + " -rotate-90"}
                />
            );
        case "arrowLeft":
            return <ArrowLeft {...props} />;
        case "arrowRight":
            return <ArrowRight {...props} />;
        case "arrowTop":
            return (
                <ArrowLeft
                    {...props}
                    className={props.className + " rotate-90"}
                />
            );
        case "arrowRightBar":
            return <ArrowRightBar {...props} />;
        case "check":
            return <Check {...props} />;
        case "navCheck":
            return <NavCheck {...props} />;
        case "fullScreen":
            return <FullScreen {...props} />;
        case "minus":
            return <Minus {...props} />;
        case "pencil":
            return <Pencil {...props} />;
        case "plus":
            return <Plus {...props} />;
        case "trash":
            return <Trash {...props} />;
        case "flag":
            return <Flag {...props} />;
        case "pause":
            return <Pause {...props} />;
        case "copy":
            return <Copy {...props} />;
        case "reset":
            return <Reset {...props} />;
        case "close":
            return <Close {...props} />;
        case "search":
            return <Search {...props} />;
        case "chessBoard":
            return <ChessBoard {...props} />;
        case "infoCircle":
            return <InfoCircle {...props} />;
        case "files":
            return <Files {...props} />;
        case "angleRight":
            return <AngleRight {...props} />;
        case "angleBottom":
            return (
                <AngleRight
                    {...props}
                    className={props.className + " rotate-90"}
                />
            );
        case "imageUpload":
            return <ImageUpload {...props} />;
        case "imagePlus":
            return <ImagePlus {...props} />;
        case "imageMinus":
            return <ImageMinus {...props} />;
        case "folder":
            return <Folder {...props} />;
        case "circleArrowRight":
            return <CircleArrowRight {...props} />;
        default:
            throw new Error(
                "An icon with the name '" + name + "' does not exist."
            );
    }
}
