import { useIdleTimer } from "react-idle-timer";
import { useAppContext } from "../AppProvider";
import useCreateRequest from "./useCreateRequest";

/**
 * Manage the apps keep alive and idle state.
 * As long as we keep the game session alive no other user
 * can edit (take over) the game.
 *
 * @returns {{
 *  keepAlive: Function
 *  refresh: Function
 *  unlock: Function
 *  pauseIdle: Function
 * }}
 */
function useKeepAlive() {
    const { user, keepAliveId, setkeepAliveId, setIdle, stopKeepAlive } =
        useAppContext();
    const { adminPost } = useCreateRequest();

    const idleTime = 5 * 60 * 1000; // 5 minutes
    const keepAliveInterval = (4 * 60 + 30) * 1000; // 4:30 minutes

    /**
     * Action to perform when user "idles" .
     */
    const onIdle = () => {
        pause();
        stopKeepAlive();
        setIdle(true);
    };

    const { start, pause } = useIdleTimer({
        onIdle,
        startManually: true,
        startOnMount: false,
        timeout: idleTime,
        stopOnIdle: true,
        events: [
            "mousemove",
            "keydown",
            "wheel",
            "DOMMouseScroll",
            "mousewheel",
            "mousedown",
            "touchstart",
            "touchmove",
            "MSPointerDown",
            "MSPointerMove",
            "visibilitychange",
            "focus",
        ],
    });

    /**
     * Pause idle watcher.
     * @see https://idletimer.dev/docs/api/methods#pause
     */
    const pauseIdle = () => {
        pause();
    };

    /**
     * Post refresh action for the active game.
     * Can be used to determine if the game is locked for the user.
     * @param {number} gameId
     * @returns {Promise}
     */
    const refresh = (gameId) => {
        const postData = {
            action: "refresh_edit_chess_entity",
            userId: user.id,
            gameId: gameId,
        };

        return adminPost(postData);
    };

    /**
     * Unlock the game session.
     * This allows other user to edit (take over) this match.
     * @param {number} gameId
     * @returns {Promise}
     */
    const unlock = (gameId) => {
        const postData = {
            action: "unlock_edit_chess_entity",
            userId: user.id,
            gameId: gameId,
        };

        return adminPost(postData);
    };

    /**
     * Keep game session alive as long as the browser is open and the user
     * is not idling for too long.
     * @param {number} gameId
     */
    const keepAlive = (gameId = null) => {
        if (keepAliveId === null && gameId !== null) {
            start();
            const intervalId = setInterval(() => {
                refresh(gameId);
            }, keepAliveInterval);

            setkeepAliveId(intervalId);
        }
    };

    return { pauseIdle, refresh, unlock, keepAlive };
}

export default useKeepAlive;
