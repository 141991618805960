import { Color } from './Color';

export function getMoveNoFromHalfMoveNo(halfMoveNo: number) {
  return (halfMoveNo + (halfMoveNo % 2 ? 1 : 0)) / 2;
}
export function getColorFromHalfMoveNo(halfMoveNo: number): Color {
  return halfMoveNo % 2 ? 'w' : 'b';
}

export function getRowLocationName(halfMoveIndex: number) {
  return (
    getMoveNoFromHalfMoveNo(halfMoveIndex + 1) +
    getColorFromHalfMoveNo(halfMoveIndex)
  );
}
