import React from "react";
import Icon from "../Icon";

/**
 *
 * @param {ReactNode} props.icon Name of the icon to display
 * @param {String} props.className
 * @param {Boolean} props.disabled
 * @param {function} props.onClick Click Eventhandler
 * @param {String} props.title Tooltip text
 * @returns Minimal round button
 */
export default function RoundButton({
    icon,
    className,
    disabled,
    onClick,
    title,
    color,
}) {
    return (
        <button
            className={
                "p-3 rounded-full text-white " +
                (!!disabled
                    ? "bg-gray-400 "
                    : color ? "cursor-pointer "
                    : "bg-pgn-primary cursor-pointer ") +
                (className ?? "")
            }
            disabled={disabled}
            onClick={onClick}
            style={{
                backgroundColor: disabled
                    ? "rgba(156 163 175)"
                    : color
                    ? color
                    : undefined,
            }}
            title={title}
        >
            <Icon name={icon} className="block" />
        </button>
    );
}
