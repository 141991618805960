import Breadcrumb from "./Breadcrumb";
import {
    useStepNavigationContext,
    useStepNavigationDispatchContext,
} from "./context/StepNavigationProvider";

/**
 * @param {string} props.className
 * @returns buttons (breadcrumbs)
 */
function StepBreadcrumbs({ className }) {
    const { getActive, stepNavigation } = useStepNavigationContext();
    const navigate = useStepNavigationDispatchContext();

    const breadcrumbs = getActive();

    /**
     * @param {{path: string, label: string}} breadcrumb
     */
    const onNavigate = (breadcrumb) => {
        navigate({
            ...breadcrumb,
            type: "to",
        });
    };

    return (
        <div className={"flex justify-start gap-2 md:gap-4 " + className}>
            {stepNavigation.map((_, index, all) => {
                const crumb = breadcrumbs[index];
                return crumb ? (
                    <Breadcrumb
                        key={`${crumb.path}-${index}`}
                        crumb={crumb}
                        visited={index !== breadcrumbs.length - 1}
                        canNavigate={
                            all.length - 1 > index && crumb?.path !== "upload"
                        }
                        onNavigate={onNavigate}
                    />
                ) : (
                    <Breadcrumb key={index} skeletton />
                );
            })}
        </div>
    );
}

export default StepBreadcrumbs;
