import React from "react";
import { Field } from "formik";

/**
 *
 * @param {{label: String, value: String}[]} props.options Options for the radio selection
 * @param {String} props.name Name of the form group
 * @returns
 */
export default function Radio({
    options,
    name,
    required,
    initValue,
    ...props
}) {
    return (
        <div role="group" className="flex gap-3 flex-wrap">
            {options?.map(({ label, value }) => (
                <label key={value} className="cursor-pointer">
                    <Field
                        type="radio"
                        className="w-0 absolute opacity-0 [&:checked+span]:border-2 [&:checked+span]:border-pgn-primary"
                        name={name}
                        value={value}
                        required={!!required}
                        checked={options.length < 2 || initValue === value ? true : false}
                        {...props}
                    />
                    <span className="rounded-md border border-gray-300 px-4 py-2">
                        {label}
                    </span>
                </label>
            ))}
        </div>
    );
}
