import { getBaseURL } from "../../config";

/**
 * Convert {@link File} to {@link String base64 strings}.
 * @param {File[]} files
 * @returns {Promise<String[]>}
 */
export const FileToBase64 = async (files) => {
    return Promise.all(
        files.map((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
        })
    );
};

/**
 * Convert {@link String base64 string} to {@link File files}
 * @param {string[]} files
 * @returns {File[]}
 */
export const base64ToFile = (base64Images) => {
    return base64Images.map((base64) => {
        const type = base64.split(";")[0].split(":")[1];
        const binaryString = atob(base64.split(",")[1]);

        const len = binaryString.length;
        const arrayBuffer = new ArrayBuffer(len);
        const bytes = new Uint8Array(arrayBuffer);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return new File([bytes], "", {
            type: type,
            lastModified: Date.now(),
        });
    });
};

/**
 * Convert image {@link String urls} to {@link File files}.
 * @param {String[]} imgUrls
 * @returns {Promise<File|boolean[]>}
 */
export const imgUrlsToFile = async (imgUrls) => {
    const convertToFile = (urls) => {
        return urls.map((url) => {
            return new Promise((resolve) => {
                fetch(`${getBaseURL()}${url}`)//FIXME: Add correct endpoint
                    .then((response) => {
                        if (response.ok) {
                            response.blob().then((blob) => {
                                const type = url.split(".").pop();
                                resolve(
                                    new File([blob], "", {
                                        type: `image/${type}`,
                                    })
                                );
                            });
                        } else {
                            resolve(false);
                        }
                    })
                    .catch((error) => console.error(error));
            });
        });
    };
    if (Array.isArray(imgUrls)) {
        return Promise.all(convertToFile(imgUrls));
    } else {
        return [];
    }
};
