import { HalfMove } from './HalfMove';
import { Sheet } from './recognition';
import { Chess } from 'chess.js';
import { RowLocation } from './RowLocation';
import { GameLogicWriter } from './GameLogicWriter';
import { MoveShift } from './MoveShift';
import { compareMoves } from './HalfMoveMatcher';
import { SheetIndex } from './SheetIndex';
import { RowIndex } from './RowIndex';
import { GameLogicSnapshot } from './snapshot/GameLogicSnapshot';
import { GameChangedCallback } from './GameChangedCallback';
import { HalfMoveSnapshot } from './snapshot/HalfMoveSnapshot';
import { SetSANCommand } from './commands/SetSANCommand';

/**
 * Hauptklasse für die Spielnachbearbeitung und Datenausführung.
 *
 * Nachdem alle Formulare an den HTR-Dienst gesendet wurden, kann mit der eigentlichen
 * Machbearbeitung der Daten begonen werden. In dieser Phase wird diese Klasse einmal
 * für die Partie generiert. Solange wie die Partie bearbeitet wird, kann das Objekt
 * verwendet werden.
 *
 * @example Neues Spiel initialisieren...
 * ```
 * const writer1_page1 = ... // Aus DB laden
 * const writer2_page1 = ... // Aus DB laden
 *
 * let gl = new GameLogic([writer1_page1], [writer2_page1])
 * ```
 *
 * @example Aus der Datenbank bestehendes Spiel laden
 * ```
 * const writer1_page1 = ... // Aus DB laden
 * const writer1_page2 = ... // Aus DB laden
 * const writer2_page1 = ... // Aus DB laden
 * const writer2_page2 = ... // Aus DB laden
 *
 * const data_from_db = ... // Aus DB laden
 *
 * let gl = new GameLogic([writer1_page1, writer1_page2], [writer2_page1, writer2_page2], data_from_db)
 * ```
 *
 * @example Neues Spiel initialisieren mit Callback, bei jeder Änderung wird das aktuell als am wahrschlich erkannte PGN
 * auf die Konsole ausgegeben.
 * ```
 * const writer1_page1 = ... // Aus DB laden
 * const writer2_page1 = ... // Aus DB laden
 *
 * let gl = new GameLogic([writer1_page1], [writer2_page1], (snapshot) => {console.log(snapshot.bestBoardPgn)})
 * ```
 */
export class GameLogic {
  /**
   * Alle Partieformulare des Erfassers1 zu dieser Partie
   */
  #halfMoves: readonly HalfMove[];

  /**
   * Fehlertext der ausgegeben wird wenn ein leeres Array für sheetsWriter1 an den Konstruktor übergeben wird...
   */
  static readonly #ERROR_NO_SHEETS =
    'At least one sheet need to be defined for writer 1';

  /**
   * Erstellt ein neues GameLogic-Objekt und initialisiert es mit den bereits vorhandenen Daten
   * aus der Datenbank.
   *
   * @param sheetsWriter1 Texterkennungsdaten des ersten Schreibers
   * @param sheetsWriter2 Texterkennungsdaten des zweiten Schreibers
   * @param gameChangedCallback Falls mit React gearbeotet wird, kann hier ein Callback übergeben werden der nach jeder Änderung den neuen Spielstand als Snapshot-Datenstruktur liefert. Das erlaubt die Arbeit
   * @param gamePgn Datenstring zum Wiederherstellen der Partie aus dem persistenten Speicber. Es handelt sich hier um ein PGN-Format mit eigenen Header-Feldern. Siehe {@linkcode save}-Funktion
   * @param pgnDebug Nur für den Debug-Fall: Hier kann das bereits bekannte, korrekte PGN übergebnen werden, dann unterstütz das Objektmodell gleich den Vergleich...
   */
  public constructor(
    sheetsWriter1: Sheet[],
    sheetsWriter2: Sheet[],
    public gameChangedCallback?: GameChangedCallback,
    gamePgn?: string,
    pgnDebug?: string
  ) {
    if (sheetsWriter1 == undefined || sheetsWriter1.length == 0)
      throw new Error(GameLogic.#ERROR_NO_SHEETS);

    this.writer1 = new GameLogicWriter(this, 1, sheetsWriter1);

    if ((sheetsWriter2 ?? []).length > 0)
      this.writer2 = new GameLogicWriter(this, 2, sheetsWriter2 ?? []);
    else this.writer2 = null;

    // Versuche bei einem neuen Spiel automatisch den letzten Spielzug zu ermitteln
    if (!gamePgn) this.#calculateLastMoveProposal();

    let debugMoves: string[] | undefined;

    if (pgnDebug) {
      const debugBoard = new Chess();
      debugBoard.loadPgn(pgnDebug);
      debugMoves = debugBoard.history();
    }

    let last: HalfMove | null = null;
    const halfMoves: HalfMove[] = [];
    for (let index = 0; index < 130 * this.writer1.sheets.length; index++) {
      last = new HalfMove(this, last, debugMoves?.at(index));
      halfMoves.push(last);
    }

    halfMoves[0]?.updateRowLocations();

    this.#halfMoves = halfMoves;

    if (gamePgn) {
      const loadedGame = new Chess();

      loadedGame.loadPgn(gamePgn);
      const headers = loadedGame.header();
      const lastMove1 = headers['LastMoveWriterOne'];

      if (lastMove1 && lastMove1.length > 0)
        this.writer1.setLastMoveFixedInternal(
          new RowLocation(
            (this.writer1.sheets.length - 1) as SheetIndex,
            +lastMove1 as RowIndex
          ),
          false
        );
      const lastMove2 = headers['LastMoveWriterTwo'];
      if (lastMove2 && lastMove2.length > 0 && this.writer2)
        this.writer2.setLastMoveFixedInternal(
          new RowLocation(
            (this.writer2.sheets.length - 1) as SheetIndex,
            +lastMove2 as RowIndex
          ),
          false
        );

      const shifts = headers['Shifts'];
      if (shifts) {
        shifts.split('|').forEach((m) => {
          const entry = m.split(':');
          const index = +entry[0];
          const values = entry[1].split('/');

          this.halfMoves[index].writer1.setShiftInternal(
            +values[0] as MoveShift
          );

          const w2 = this.halfMoves[index].writer2;
          if (values.length == 2 && w2 != null)
            w2.setShiftInternal(+values[1] as MoveShift);
        });
      }

      halfMoves[0]?.calculate(false);

      const history = loadedGame.history();
      for (let index = 0; index < history.length; index++) {
        const element = history[index];
        if (this.halfMoves[index].san != element)
          this.halfMoves[index].setSanFixedInternal(element, false);
      }
    }

    halfMoves[0]?.calculate();
  }

  /**
   * @private
   *
   * Diese Hilfsmethode selektiert den aktuell in der Ansicht ersten Halbzug.
   *
   * Der Anwender soll automatisch direkt das Brett und die Daten des Zuges sehen,
   * den er als nächstes bearbeiten sollte. In der Regel ist der Filter aktiv, dann ist
   * der erste Zug in der Regel der, bei dem der Anwender aktiv werden muss.
   */
  selectFirst() {
    const lmc1 = this.writer1.lastMoveCalculated;
    const lmc2 = this.writer2?.lastMoveCalculated;
    if (this.writer1.lastMoveFixed == undefined && lmc1 != undefined)
      this.setSelectedHalfMoveIndex(
        this.halfMoves.filter((x) => x.writer1.rowLocation?.isEqual(lmc1))[0]
          .halfMoveIndex
      );
    else if (this.writer2?.lastMoveFixed == undefined && lmc2 != undefined)
      this.setSelectedHalfMoveIndex(
        this.halfMoves.filter((x) => x.writer2?.rowLocation?.isEqual(lmc2))[0]
          .halfMoveIndex
      );
    else if (this.filtered) {
      // Suche den ersten Zug der unsicher ist.
      const arr = this.halfMoves.filter(
        (x) =>
          !x.confident &&
          x.commands.filter((y) => y instanceof SetSANCommand).length != 1
      );

      this.setSelectedHalfMoveIndex(arr[0].halfMoveIndex);
    }
  }

  /**
   * Liste mit allen Halbzügen.
   *
   * Hinweis: Die Liste liefert so viele Halbzüge wie es Formularzellen gibt. Das sind
   * in der Regel mehr als das eigentliche Spiel verwendet.
   */
  public get halfMoves() {
    return this.#halfMoves;
  }

  /**
   * @private
   *
   * Interne Hilfsmethode, die bach einer Neuberechnung der Züge vom letzten erfolgreich
   * (irgendwie) erkannten Halbzug aufgerufen wird.
   *
   * @param moves Move-Array der nach aktuellen Zustand wahrscheinlichsten Zugkonstellation
   */
  setBestBoard(moves: readonly string[]) {
    const chess = new Chess();
    moves.forEach((x, i) => {
      chess.move(x);
      this.halfMoves[i].bestBoardFenAfter = chess.fen();
    });
    this.bestBoardMoves = moves;
    this.#bestBoardPgn = chess.pgn({ maxWidth: 80 });
  }

  /**
   * @private
   *
   * Liefert die SAN-Ausdrücke aller Halbzüge entsprechend dem aktuell als am besten
   * erkannten Spielverlaufs ({@linkcode bestBoardPgn}).
   */
  public bestBoardMoves: readonly string[] = [];

  /**
   * Backing Field für {@linkcode bestBoardPgn}
   */
  #bestBoardPgn?: string;

  /**
   * Das nach aktuellem Bearbeitungsstand wahrscheinlichstes PGN
   *
   * Die UI kann diese Eigenschaft für einen Download jederzeit anbieten, order erst dann
   * wenn das Ende des Spiels erkannt und
   *
   * @returns PGN-String des wahrscheinlichsten Spiels
   */
  public get bestBoardPgn(): string | undefined {
    return this.#bestBoardPgn;
  }

  /**
   * Bietet Zugriff auf die Schreiber-1 spezifischen Daten zu diesem Spiel
   *
   * @returns Das {@linkcode GameLogicWriter}-Objekt für den Schreiber 1
   */
  public readonly writer1: GameLogicWriter;

  /**
   * Bietet Zugriff auf die Schreiber-1 spezifischen Daten zu diesem Spiel.
   *
   * @returns Das {@linkcode GameLogicWriter}-Objekt für den Schreiber 1, oder ```null```,
   * falls für diese Partie keine Formulare eines zweiten Schreibers angegeben wurden.
   */
  public readonly writer2: GameLogicWriter | null;

  /**
   * @private
   *
   * Gibt an ob der letzte Zug bereits für beide Schreiber gesetzt wurde.
   *
   * Hinweis: Wenn nur ein Schreiber existiert, dann wird ```true```zurückgeliefert sobald
   * für den ersten Schreiber der letzte Zug vom Anwender gesetzt wurde...
   */
  gameEndIsDefined() {
    return (
      this.writer1.lastMoveFixed != undefined &&
      (this.writer2 == null || this.writer2.lastMoveFixed != undefined)
    );
  }

  /**
   * Hilsmethode um den letzten Zug des Spiels automatisch zu bestimmen.
   *
   * Verwendet {@linkcode GameLogicWriter.calculateLastMoveProposalForWriter}.
   */
  #calculateLastMoveProposal() {
    this.writer1.calculateLastMoveProposalForWriter();
    this.writer2?.calculateLastMoveProposalForWriter();
  }

  /**
   * @private
   * Berechnet den Vorschlag für die Verschiebungen, falls einer der beiden Schreiber Züge vergessen
   * haben zu erfassen.
   *
   * Aktuell unterstützt die Funktion nicht die Erkennung von zu viel erfassten Zügen.
   */
  calculatePossibleShifts() {
    if (this.writer2 == null) return false;

    type HalfMoveVariant = {
      shift1: MoveShift;
      shift2: MoveShift;
      location1: RowLocation | null;
      location2: RowLocation | null;
      locationCandidate1: RowLocation;
      locationCandidate2: RowLocation;
      hasIntersection: boolean;
      hm1: string[];
      hm2: string[];
    };

    function sortVariantArray(vv: GameVariant[]) {
      return vv.sort((x, y) => {
        const ix = x.filter(
          (h) => h.hasIntersection && h.shift1 == 0 && h.shift2 == 0
        ).length;
        const iy = y.filter(
          (h) => h.hasIntersection && h.shift1 == 0 && h.shift2 == 0
        ).length;
        if (iy != ix) return iy - ix;

        const sx = x
          .map((x) => Math.abs(x.shift1) + Math.abs(x.shift2))
          .reduce((acc, curr) => acc + curr, 0);
        const sy = y
          .map((x) => Math.abs(x.shift1) + Math.abs(x.shift2))
          .reduce((acc, curr) => acc + curr, 0);

        const lx = x.length;
        const ly = y.length;
        if (ly != lx) return lx - ly;
        else return sx - sy;
      });
    }

    type GameVariant = HalfMoveVariant[];

    let variants: GameVariant[] = [[]];
    const endedVariants: GameVariant[] = [];

    while (variants.length > 0) {
      const newVariants: GameVariant[] = [];
      for (
        let variantIndex = 0;
        variantIndex < variants.length;
        variantIndex++
      ) {
        const variant = variants[variantIndex];

        const prevRowLocation1 =
          variant.length > 0
            ? variant[variant.length - 1].locationCandidate1
            : undefined;
        const prevRowLocation2 =
          variant.length > 0
            ? variant[variant.length - 1].locationCandidate2
            : undefined;

        Variants: for (const writer1Shift of [0, 1] as MoveShift[]) {
          for (const writer2Shift of [0, 1] as MoveShift[]) {
            const copiedVariant = [...variant];

            // Auschluss von blöden Fällen, die wir eh nicht erkennen können...
            //if (writer1Shift == 1 && writer2Shift == 1 || writer1Shift < 0 && writer2Shift < 0)
            //    continue

            // Vorerst eine einfache Regel: Mindestens ein Schreiber muss diesen Zug richtig erfasst haben..
            // Ist weniger als die oben auskommentierte, sollte aber reichen...
            if (!(writer1Shift == 0 || writer2Shift == 0)) continue;

            // Wenn in den letzten 3 Zügen der eine Schreiber einen Shift hatte, darf der andere Schreiber keinen Schift kriegen.
            // Das ist ein Zeichen dass einfach nur die Erkennugn in dem Bereich so ungünstig / schlecht ist, dass
            // eine Pseudo-Verschiebung eingefügt wird, die gleich danach wieder korrigiert wird.
            // Den Fall kann es zwar wirklich geben, aber dann erkenenn wir ihn halt nicht
            if (
              writer1Shift == 1 &&
              copiedVariant.slice(-5).filter((x) => x.shift2 == 1).length > 0
            )
              continue;
            else if (
              writer2Shift == 1 &&
              copiedVariant.slice(-5).filter((x) => x.shift1 == 1).length > 0
            )
              continue;

            // Bereche die Zellen beider Spieler
            const rowLocation1 = this.writer1.getRelativeRowLocation(
              prevRowLocation1,
              (writer1Shift - 1) as MoveShift
            );
            const rowLocation2 = this.writer2.getRelativeRowLocation(
              prevRowLocation2,
              (writer2Shift - 1) as MoveShift
            );
            let intersection: string[] = [];
            let hm1: string[] = [];
            let hm2: string[] = [];
            if (!(rowLocation1 == null || rowLocation2 == null)) {
              hm1 = this.writer1.sheets[
                rowLocation1.sheetIndex
              ].notations[0].models.map(
                (x) => x.halfMoves[rowLocation1.rowIndex].r[0].t
              );
              hm2 = this.writer2.sheets[
                rowLocation2.sheetIndex
              ].notations[0].models.map(
                (x) => x.halfMoves[rowLocation2.rowIndex].r[0].t
              );
              intersection = hm1.filter(
                (value) => hm2.filter((x) => compareMoves(value, x)).length > 0
              );
            }

            const candidate1 = rowLocation1 ?? prevRowLocation1;
            const candidate2 = rowLocation2 ?? prevRowLocation2;

            // Falls gleich der erste Zug nicht matched, kann dieser Fall eintreten.
            if (candidate1 == undefined || candidate2 == undefined) continue;

            const halfMoveVariant: HalfMoveVariant = {
              shift1: writer1Shift,
              shift2: writer2Shift,
              hasIntersection: intersection.length > 0,
              location1: writer1Shift != 1 ? rowLocation1 : null,
              location2: writer2Shift != 1 ? rowLocation2 : null,
              locationCandidate1: candidate1,
              locationCandidate2: candidate2,
              hm1: hm1,
              hm2: hm2
            };

            copiedVariant.push(halfMoveVariant);
            if (
              (rowLocation1 != null &&
                this.writer1.lastMoveFixed &&
                rowLocation1.isEqual(this.writer1.lastMoveFixed)) ||
              (rowLocation2 != null &&
                this.writer2.lastMoveFixed &&
                rowLocation2.isEqual(this.writer2.lastMoveFixed))
            ) {
              endedVariants.push(copiedVariant);
            } else {
              newVariants.push(copiedVariant);
              if (intersection.length > 0) break Variants;
            }
          }
        }
      }

      // Wenn sich mehr als 50 Varianten angesammelt haben, dann nur die 10 Wahrscheinlichsten behalten.
      if (newVariants.length > 50)
        variants = sortVariantArray(newVariants).slice(0, 10);
      else variants = newVariants;
    }

    // Aus den Varianten am Ende wird nun die beste gewählt...
    const finalVariants = sortVariantArray(endedVariants).slice(0, 1);
    let refresh = false;
    if (finalVariants.length > 0) {
      const bestVariant = finalVariants[0];
      for (
        let halfMoveIndex = 0;
        halfMoveIndex < bestVariant.length;
        halfMoveIndex++
      ) {
        const halfMove = this.halfMoves[halfMoveIndex];
        const hm = bestVariant[halfMoveIndex];
        if (halfMove.writer1.setShiftInternal(hm.shift1)) refresh = true;
        const w2 = halfMove.writer2;
        if (w2 && w2.setShiftInternal(hm.shift2)) refresh = true;
      }

      if (refresh) {
        this.halfMoves[0].calculate();
        return true;
      }
    }

    return false;
  }

  /**
   * Speichert alle Bearbeitungsschritte des Anwenders in ein PGN-Format
   *
   * Die potentiellen Verschiebungen als auch die Information des letzten Zuges werden
   * in Custom-Header-Dateien gespeichert.
   *
   * Der String kann im {@linkcode GameLogic.constructor} verwendet werden um den Spielzustand
   * nachträglich aus einer Datenbank wiederherzustellen.
   *
   * @returns Ein PGN-Format mit zusätzlichen Headern
   */
  public save() {
    if (this.bestBoardPgn) {
      let str = '';
      const chess = new Chess();
      chess.loadPgn(this.bestBoardPgn);

      this.halfMoves
        .filter(
          (hm) =>
            hm.visible &&
            (hm.writer1.shift != 0 ||
              (hm.writer2 != null && hm.writer2?.shift != 0))
        )
        .forEach((hm) => {
          if (hm.writer2 != null)
            str += `${hm.halfMoveIndex}:${hm.writer1.shift}/${hm.writer2?.shift}|`;
          else str += `${hm.halfMoveIndex}:${hm.writer1.shift}|`;
        });

      if (this.writer1.lastMoveFixed != undefined)
        chess.header(
          'LastMoveWriterOne',
          `${this.writer1.lastMoveFixed.rowIndex}`
        );

      if (this.writer2?.lastMoveFixed != undefined)
        chess.header(
          'LastMoveWriterTwo',
          `${this.writer2.lastMoveFixed.rowIndex}`
        );

      if (str.length > 0) {
        chess.header('Shifts', str.slice(0, -1));
      }
      chess.header('PgnAppFormatVersion', '1');

      return chess.pgn({ maxWidth: 80 });
    }

    return null;
  }

  /**
   * Backing-Field zu {@linkcode selectedHalfMoveIndex}
   */
  #selectedHalfMoveIndex = 0;

  /**
   * Liefert den Index des aktuell selektierten Halbzuges.
   *
   * Falls kein Halbzug selektiert ist, dann liefert
   */
  public get selectedHalfMoveIndex() {
    return this.#selectedHalfMoveIndex;
  }

  /**
   * Setzt den aktuell selektierten Halbzug im Spiel.
   */
  public set selectedHalfMoveIndex(index: number) {
    this.setSelectedHalfMoveIndex(index);
    this.callGameChangedCallback(true);
  }

  /**
   * @private
   * Private Hilfsmethode zum Setzen des selektierten Halbzuges, ohne
   * dass ein Refresh ausgelöst wird.
   * @param index Index des Halbzuges, der selektiert werden soll.
   */
  setSelectedHalfMoveIndex(index: number) {
    this.#selectedHalfMoveIndex = index;
  }

  /**
   * Liefert den selektierten Halbzug.
   */
  public get selectedHalfMove() {
    return this.halfMoves[this.selectedHalfMoveIndex];
  }

  /**
   * Liefert den FEN-Ausdruck des wahrscheinlichsten Board bevor der aktuell selektierte Hablzug
   * ausgegeführt wurde.
   *
   * Dieser Ausdruck sollte verwendet werden, um ein Schachbrett in der UI zu initialisieren, so dass der Anwender damit
   * in der Lage ist, diesen Zug auf dem Brett auszuführen, sollte der richtige Zug nicht als Button / Command vorgeschlagen
   * werden können.
   */
  public get selectedBestBoardFenBefore() {
    return this.selectedHalfMove.bestBoardFenBefore;
  }

  /**
   * Backing-Field zu {@linkcode filtered}
   */
  #filtered = true;

  /**
   * Gibt an ob derzeit nur die unsicheren Züge angezeigt werden oder alle.
   */
  public get filtered() {
    return this.#filtered;
  }

  /**
   * Ändert den Wert der {@linkcode filtered}-Eigenschaft.
   */
  public set filtered(value: boolean) {
    this.#filtered = value;
    this.callGameChangedCallback(true);
  }

  /**
   * Beinhaltet den zuletzt erzeugten {@linkcode GameLogicSnapshot}.
   *
   * Dieser wird für die interne Implementierung benötigt als Cache, damit nicht alle Daten immer komplett neu
   * gesnapshotted werden müssen.
   */
  #lastSnapshot?: GameLogicSnapshot = undefined;

  /**
   * Beinhaltet die Liste der zuletzt generierten {@linkcode HalfMoveSnapshot}-Objekte, ohne dass da ein Filter
   * angewendet wurde. Es macht Sinn diese Liste zu cachen, falls nur die Filter-Eigenschaft verändert wurde,
   * dann müssen die HalfMoves nicht neu erzeugt werden.
   */
  #lastSnapshotHalfMoveList?: HalfMoveSnapshot[] = undefined;

  /**
   * Triggert den {@linkcode gameChangedCallback}-Callback, falls dieser gesetzt
   * ist.
   *
   * @private
   * @param refreshOnlyRoot soll nur das Root-Objekt neu erzeugt, aber die Halbüge recycled werden?
   */
  callGameChangedCallback(refreshOnlyRoot = false) {
    if (this.gameChangedCallback) {
      if (!refreshOnlyRoot || !this.#lastSnapshotHalfMoveList)
        this.#lastSnapshotHalfMoveList = this.halfMoves
          .filter((x) => x.visible)
          .map((x) => new HalfMoveSnapshot(x));

      this.#lastSnapshot = new GameLogicSnapshot(
        this,
        this.#lastSnapshotHalfMoveList.filter(
          (x) => !(this.filtered && x.confident)
        ),
        this.#lastSnapshotHalfMoveList[this.selectedHalfMoveIndex]
      );
      this.gameChangedCallback(this.#lastSnapshot);
    }
  }

  /**
   * Erlaubt das Zurücksetzen des letzten Zuges bei beiden Schreibern.
   *
   * Diese Methode ist ein kleine Performance-Optimierung im Gegensatz zum manuellen Zurücksezten
   * der {@linkcode GameLogicWriter.lastMoveFixed}-Eigenschaften beider Schreiber, weil diese
   * Methode eine Transaktion um beide Schreiber fasst und so der Refresh / Recalculate am
   * Ende nur einmal ausgeführt wird.
   */
  public resetLastMoveFixed() {
    this.writer1.setLastMoveFixedInternal(undefined, this.writer2 == undefined);
    if (this.writer2) this.writer2.lastMoveFixed = undefined;
  }
}
