/**
 * @param {React.ReactNode} props.children
 * @param {string} props.color
 * @returns Round number display
 */
function RowMoveNumber({ children, color }) {
    return (
        <span
            className={
                "h-10 w-10 border border-black rounded-full flex justify-center items-center " +
                (color === "b" ? "bg-black text-white" : "")
            }
        >
            {children}
        </span>
    );
}

export default RowMoveNumber;
