import {
    createContext,
    useContext,
    useMemo,
    useState
} from "react";
import { useStepNavigationContext } from "./StepNavigationProvider";

/**
 * @type {{
 *  tab: string,
 *  setTab: Function
 *  p1ActiveImage: number,
 *  setP1ActiveImage: Function
 *  p2ActiveImage: number,
 *  setP2ActiveImage: Function
 * }}
 */
const TabContext = createContext();

/**
 * Provider (context) for the tabs.
 * @param {React.ReactNode} children
 * @returns {TabContext} component
 */
function TabProvider({ children }) {
    const { getActivePath } = useStepNavigationContext();
    const { path } = getActivePath();

    const [tab, setTab] = useState(path !== "pgnedit" ? "player_1" : "chess");
    const [p1ActiveImage, setP1ActiveImage] = useState(0);
    const [p2ActiveImage, setP2ActiveImage] = useState(0);

    const context = useMemo(
        () => ({
            tab,
            setTab,
            p1ActiveImage,
            setP1ActiveImage,
            p2ActiveImage,
            setP2ActiveImage,
        }),
        [
            tab,
            setTab,
            p1ActiveImage,
            setP1ActiveImage,
            p2ActiveImage,
            setP2ActiveImage,
        ]
    );

    return (
        <TabContext.Provider value={context}>{children}</TabContext.Provider>
    );
}

/**
 * @returns {TabContext}
 */
export const useTabContext = () => {
    return useContext(TabContext);
};

export default TabProvider;
