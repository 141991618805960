import { useGameDataContext } from "../../stepview/context/GameDataProvider";
import Loading from "../Loading";

function Game({ children }) {
    const { loading } = useGameDataContext();

    return (
        <>
            {loading && <Loading />}
            {children}
        </>
    );
}

export default Game;
