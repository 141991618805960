import React from "react";

import ChessPreview from "../../pgnedit/ChessPreview";
import Tabs from "../../shared/components/form/Tabs";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import { useStepNavigationContext } from "../../shared/stepview/context/StepNavigationProvider";
import { useTabContext } from "../../shared/stepview/context/TabProvider";
import NotationLangSelect from "./NotationLangSelect";
import UploadField from "./UploadFields";

/**
 *
 * @param {{label: String, value: String | Number, icon: String}[]} props.tabs
 * @returns Tabbed preview of uploaded images
 */
export default function UploadPreview({ tabs, className, ...props }) {
    const { getActivePath } = useStepNavigationContext();
    const { path } = getActivePath();
    const { tab } = useTabContext();

    const isUploadPath = path === "upload";

    const context = useGameDataContext();

    return (
        <div
            {...props}
            className={
                "self-center w-full tablet:h-full " +
                (path === "pgnedit"
                    ? "max-w-sm tiny:max-w-xl lg:max-w-[500px] "
                    : "tablet:max-w-[500px] ") +
                (className ? className : "")
            }
        >
            <>
                <Tabs tabs={tabs} />
                <div className="flex w-full gap-2">
                    {tabs.map((tabConfig) =>
                        tabConfig.value !== "chess" ? (
                            isUploadPath ? (
                                <NotationLangSelect
                                    key={tabConfig.value}
                                    name={tabConfig.value}
                                    className="flex-1"
                                    disabled={tabConfig.value !== tab}
                                />
                            ) : (
                                <span
                                    key={tabConfig.value}
                                    className="flex-1 text-center mb-3 text-sm text-gray-500 uppercase"
                                >
                                    {context[tabConfig.value].notation_lang}
                                </span>
                            )
                        ) : (
                            <span
                                key={tabConfig.value}
                                className="flex-1 text-center mb-3 text-sm text-gray-500 uppercase"
                            ></span>
                        )
                    )}
                </div>
                {tab.includes("player") && <UploadField name={tab} />}
                {tab.includes("chess") && <ChessPreview />}
            </>
        </div>
    );
}
