/**
 * @param {React.ReactNode} props.children
 * @returns Row container
 */
function Row({ children }) {
    return (
        <ul className="grid tablet:max-h-[60vh] overflow-scroll">
            {children}
        </ul>
    );
}

export default Row;
