import { useTranslation } from "react-i18next";

/**
 * @returns Component with infos for the upload
 */
function UploadInfo({ className }) {
    const { t } = useTranslation();
    return (
        <div
            className={
                "flex flex-col gap-11 [&>*>*:first-child]:mb-4 " + className
            }
        >
            <div>
                <p className="text-gray-600">
                    {t("step1.description1")}
                    <br />
                    {t("step1.description2")}
                </p>
            </div>
            <div>
                <h3 className="text-2xl md:text-3xl text-black">{t("step1.requirements.title")}:</h3>
                <ul className="list-disc ml-4 pl-4 [&>li]:pl-1">
                    <li>{t("step1.requirements.point1")}</li>
                    <li>{t("step1.requirements.point2")}</li>
                    <li>{t("step1.requirements.point3")}</li>
                    <li>{t("step1.requirements.point4")}</li>
                </ul>
            </div>
            <div>
                <h3>{t("step1.credits.title")}</h3>
                <p>
                    {t("step1.credits.description1")}
                    <br />
                    {t("step1.credits.description2")}
                </p>
            </div>
        </div>
    );
}

export default UploadInfo;
