import { ICommand } from '../ICommand';

/**
 * Snapshot-Klasse korrespondierend zum {@linkcode ICommand}-Interface
 *
 * Die Klasse wird verwendet um den konkreten Zustand der Command-Objekte in
 * {@linkcode HalfMove.commands} nach {@linkcode HalfMoveSnapshot.commands} zu persistieren.
 */
export class CommandSnapshot implements ICommand {
  /**
   * Siehe {@linkcode ICommand.text}
   */
  public readonly text: string;

  /**
   * Siehe {@linkcode ICommand.pressed}
   */
  public readonly pressed: boolean;

  /**
   * Siehe {@linkcode ICommand.prominent}
   */
  public readonly prominent: boolean;

  /**
   * Siehe {@linkcode ICommand.execute}
   *
   * Diese Methode macht nichts anderes als die {@linkcode ICommand.execute}-Methode des unterliegenden
   * {@linkcode ICommand}-Objektes aufzurifen.
   */
  public execute() {
    this.command.execute();
  }

  constructor(private command: ICommand) {
    this.text = command.text;
    this.prominent = command.prominent;
    this.pressed = command.pressed;
  }
}
