import { useState } from "react";
import imageCompression from "browser-image-compression";
import { getBaseURL } from "../config";

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const compressionOptions = {
    maxSizeMB: 1,
    useWebWorker: true,
    maxWidthOrHeight: 3508, // DIN A4 with 300 dpi
    fileType: isSafari ? "image/jpeg" : "image/webp",
    initialQuality: isSafari ? 0.1 : 0.5
};
const baseURL = getBaseURL();

/**
 *
 * @returns Function to submit images to the API
 */
export function useImageProcessing() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const compressFiles = async (files) => {
        try {
            const promises = files.map((file) => {
                return imageCompression(file, compressionOptions);
            });

            return await Promise.all(promises);
        } catch (error) {
            throw new Error(error);
        }
    };

    /**
     * Function to send the upload images to wordpress.
     *
     * @param {[{file: base64, player: string}]} files
     * @param {[string]} notations
     * @returns {Promise}
     */
    const sendImagesToWP = async (files, notations) => {
        const formData = new FormData();
        const nonce = window.pgnvars["ajax-nonce"];
        formData.append("notations", JSON.stringify(notations));
        files.forEach(({ file, player }, index) => {
            formData.append(`image${index}`, file, file.name, player);
            formData.append("players[]", player);
        });

        const headers = {
            "X-WP-Nonce": nonce,
        };

        try {
            const url = `${baseURL}${process.env.REACT_APP_WP_API_IMAGE_ENDPOINT}`;
            const res = await fetch(url, {
                body: formData,
                method: "POST",
                headers,
            });
            return await res.json();
        } catch (error) {
            throw new Error(error);
        }
    };

    /**
     *
     * @param {Object} player_1
     * @param {Object} player_2
     * @returns Object with attributes for each player including the compressed files and the corresponding json data
     */
    const submitImages = async (player_1, player_2) => {
        try {
            const [p1Files, p2Files] =
                player_1.files.length > 0
                    ? await Promise.all([
                          compressFiles(player_1.files),
                          compressFiles(player_2.files),
                      ])
                    : await Promise.all([
                          compressFiles(player_2.files),
                          compressFiles(player_1.files),
                      ]);
            const combinedFilesByPlayer = [
                ...p1Files.map((file) => ({ file, player: "player_1" })),
                ...p2Files.map((file) => ({ file, player: "player_2" })),
            ];
            const notations = [player_1.notation_lang, player_2.notation_lang];
            const WPResponse = await sendImagesToWP(
                combinedFilesByPlayer,
                notations
            );

            const res = {
                player_1: {
                    p1Files,
                    data: Array.isArray(WPResponse.player_1)
                        ? WPResponse.player_1
                        : [],
                },
                player_2: {
                    p2Files,
                    data: Array.isArray(WPResponse.player_2)
                        ? WPResponse.player_2
                        : [],
                },
                post_id: WPResponse.post_id,
            };

            setData(res);
            return res;
        } catch (error) {
            console.error(error);
            setError(error);
            throw error;
        }
    };

    return { submitImages, data, error };
}
