import { useTranslation } from "react-i18next";
import useChessEntity from "../../../api/useChessEntity";
import Button from "../../../shared/components/buttons/Button";
import { useGameDataContext } from "../../../shared/stepview/context/GameDataProvider";
import { useAppContext } from "../../../AppProvider";
import useKeepAlive from "../../../api/useKeepAlive";

/**
 * Button to cancel the "pgnedit" process.
 * @param {string} props.className optional additional css classes
 * @param {string} props.text button text
 * @returns {Button}
 */
function PauseEditButton({ className = "", text }) {
    const { t } = useTranslation();
    const { resetApp, setSnack } = useAppContext();
    const { gameId, gameLogic, setLoading } = useGameDataContext();
    const { updateStatus } = useChessEntity();
    const { unlock, pauseIdle } = useKeepAlive();

    const buttonText =
        typeof text === "string" || text instanceof String
            ? t(text)
            : t("step3.cancelEdit");

    const save = async () => {
        setLoading(true);
        const response = await updateStatus(gameId, 1, {
            _pgn_string: gameLogic.save(),
        });

        if (response) {
            unlock(gameId);
            pauseIdle();
            resetApp();
        } else {
            setSnack({ message: t("error.endEdit"), isError: true });
        }

        setLoading(false);
    };

    return (
        <Button
            type="button"
            invert={true}
            textColor="text-pgn-primary"
            className={className}
            icon="pause"
            onClick={save}
        >
            {buttonText}
        </Button>
    );
}

export default PauseEditButton;
