import React from "react";
import RoundButton from "../../shared/components/form/RoundButton";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {Object} props
 * @param {Function} props.setPosition setter method to update the position
 * @param {Object} props.position current position {x, y}
 * @returns
 */
export default function ArrowKeys({ setPosition, position }) {
    const { t } = useTranslation();
    return (
        <div className="absolute right-0 bottom-0 flex flex-col items-center md:pr-5">
            <RoundButton
                onClick={() =>
                    setPosition((prev) => ({ ...prev, y: prev.y + 1 }))
                }
                title={t("images.top")}
                disabled={position.y > 2}
                icon="arrowTop"
                className="translate-y-2"
                color="#747e8e"
            />
            <div className="flex">
                <RoundButton
                    onClick={() =>
                        setPosition((prev) => ({ ...prev, x: prev.x + 1 }))
                    }
                    title={t("images.left")}
                    disabled={position.x > 2}
                    icon="arrowLeft"
                    className="-translate-x-3"
                    color="#747e8e"
                />
                <RoundButton
                    onClick={() =>
                        setPosition((prev) => ({ ...prev, x: prev.x - 1 }))
                    }
                    title={t("images.right")}
                    disabled={position.x < -2}
                    icon="arrowRight"
                    className="translate-x-3"
                    color="#747e8e"
                />
            </div>
            <RoundButton
                onClick={() =>
                    setPosition((prev) => ({ ...prev, y: prev.y - 1 }))
                }
                title={t("images.bottom")}
                disabled={position.y < -2}
                icon="arrowBottom"
                className="-translate-y-2"
                color="#747e8e"
            />
        </div>
    );
}
