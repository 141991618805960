import { useTranslation } from "react-i18next";
import Button from "../components/buttons/Button";

/**
 * @param {object} props
 * @param {{label: string, path: string,active: boolean}} props.crumb
 * @param {boolean} props.canNavigate
 * @param {Function} props.onNavigate
 * @param {boolean} props.visited
 * @param {boolean} props.skeletton
 * @returns Breadcrumb
 */
function Breadcrumb({
    crumb = {},
    canNavigate,
    onNavigate,
    visited,
    skeletton = false,
}) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col flex-1 max-w-[33vw] md:max-w-[200px]">
            {canNavigate ? (
                <Button
                    minimal
                    onClick={() => onNavigate(crumb)}
                    textColor="text-black"
                    className="text-sm font-medium justify-center py-1 sm:py-3"
                >
                    {t(crumb.label)}
                </Button>
            ) : (
                <span className="text-sm font-semibold text-center text-black py-1 sm:py-3">
                    {crumb.label ? t(crumb.label) : "\u00a0"}
                </span>
            )}
            <div
                className={
                    "h-3 rounded-md " +
                    (skeletton
                        ? "bg-gray-200"
                        : visited
                        ? "bg-pgn-primary"
                        : "bg-gray-400")
                }
            ></div>
        </div>
    );
}

export default Breadcrumb;
