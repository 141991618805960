import React, { useEffect } from "react";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import EditRow from "./EditRow";
import Row from "./Row";
import Toolbar from "./Toolbar";
import PGNButtons from "./buttons/PGNButtons";
import { useTabContext } from "../../shared/stepview/context/TabProvider";

/**
 * @returns View to check and edit the recognized moves
 */
export default function EditMoves() {
    const { gameLogicSnapshot, expandMobileOverlay } = useGameDataContext();
    const { halfMoves } = gameLogicSnapshot;

    const { selectedHalfMove: { halfMoveNo: selectedNo } = {} } =
        gameLogicSnapshot;

    const { setTab } = useTabContext();

    /**
     * Create mobile {@link halfMoves} list with the selected halfMove
     * as first entry.
     * @returns {any[]}
     */
    const mobileHalfMoves = () => {
        const selIndex = halfMoves.findIndex(
            ({ halfMoveNo }) => halfMoveNo === selectedNo
        );
        const indices =
            selIndex !== -1
                ? Array.from({ length: 6 }, (_, index) => selIndex + index)
                : [];

        return indices
            .map((index) => halfMoves[index])
            .filter((halfMove) => halfMove);
    };

    useEffect(() => {
        setTab("chess");
    }, [setTab]);

    return (
        <div
            className={
                "flex flex-col gap-2 " + expandMobileOverlay ? "mt-4" : "mt-1"
            }
        >
            {expandMobileOverlay && !isNaN(selectedNo) ? (
                <Row>
                    {mobileHalfMoves().map((halfMove, i) => (
                        <EditRow halfMove={halfMove} key={i} />
                    ))}
                </Row>
            ) : (
                <div className="flex flex-col gap-2">
                    <Toolbar />
                    <Row>
                        {halfMoves.map((halfMove, i) => (
                            <EditRow halfMove={halfMove} key={i} />
                        ))}
                    </Row>
                    <PGNButtons />
                </div>
            )}
        </div>
    );
}
