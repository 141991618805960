import { useTranslation } from "react-i18next";
import { useState } from "react";
import HeadDataTemplates from "./modals/HeadDataTemplates";
import Button from "../../shared/components/buttons/Button";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import Icon from "../../shared/components/Icon";

function MetaDataTemplates() {
    const { t } = useTranslation();
    const context = useGameDataContext();

    const [showTemplates, setShowTemplates] = useState(false);

    const onHandleTemplateModal = () => {
        setShowTemplates(!showTemplates);
    };

    return (
        <div className="flex flex-col gap-2 py-0 sm:py-2 max-w-2xl">
            <h3>{t("step2.templates.title")}:</h3>
            <Button
                onClick={onHandleTemplateModal}
                disabled={context.getHeaderDataSets().length === 0}
                invert
                className="m-0 sm:mr-auto"
            >
                <Icon name="files"/>
                {t("step2.templates.useTemplate")}
                <Icon name="angleRight"/>
            </Button>
            {showTemplates && (
                <HeadDataTemplates onClose={onHandleTemplateModal} />
            )}
        </div>
    );
}

export default MetaDataTemplates;
