import { useEffect, useState } from "react";
import { useAppContext } from "../AppProvider";
import { getBaseURL } from "../config";
import { getAjaxNonce, getUserData } from "./PGNVariables";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} FolderDef
 * @property {string} label
 * @property {number} value
 * @property {array} _header_data_sets
 * @property {array} _selected_header_data
 */

/**
 * Hook that fetches all available folders for the user.
 * The first folder in the folders list is the default folder for the user.
 * @returns {
 *  {FolderDef} folders
 *  {FolderDef} sharedFolders
 *  {Boolean} loading
 *  {string|null} error
 * }
 */
function useFolders() {
    const [folders, setFolders] = useState(/** @type {FolderDef} */ ([]));
    const [sharedFolders, setSharedFolders] = useState(
        /** @type {FolderDef} */ ([])
    );
    /**
     * @type {Boolean} loading
     */
    const [loading, setLoading] = useState(/** @type {boolean} */ (false));
    const [error, setError] = useState(/** @type {string|null} */ (null));

    const {
        user: { defaultFolderId, id },
    } = useAppContext();

    const { t } = useTranslation();

    useEffect(() => {
        const fetchEntities = async () => {
            try {
                setLoading(true);
                const getFolders = await fetch(
                    `${getBaseURL()}/wp-json/wp/v2/chess_entity/?parent=0&per_page=100`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "X-WP-Nonce": getAjaxNonce(),
                        },
                    }
                );

                const folders = await getFolders.json();
                const defaultFolder = folders.find(
                    (folder) => folder.id === defaultFolderId[0]
                );
                const filtered = folders.filter(
                    (folder) => folder.id !== defaultFolderId[0]
                );

                const userFolders = [defaultFolder, ...filtered].map(
                    (folder, i) => ({
                        label:
                            i > 0
                                ? decodeURI(folder.title.rendered)
                                : t("step2.defaultfolder"),
                        value: folder.id,
                        _header_data_sets: JSON.parse(
                            folder?.meta["_header_data_sets"] || "[]"
                        ),
                        _selected_header_data: JSON.parse(
                            folder?.meta["_selected_header_data"] || "[]"
                        ),
                    })
                );

                setFolders(userFolders);

                const userSharedFolders = getUserData()["sharedFolders"];
                const sharedFolders = Array.isArray(userSharedFolders)
                    ? userSharedFolders
                    : [];
                setSharedFolders(sharedFolders);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchEntities();
    }, [defaultFolderId, id, t]);

    return { folders, sharedFolders, loading, error };
}

export default useFolders;
