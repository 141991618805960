import { getAjaxNonce } from "./api/PGNVariables";
import { useAppContext } from "./AppProvider";

import Game from "./shared/components/views/Game";
import GameCacheProvider from "./shared/stepview/context/GameCacheProvider";
import { GameDataProvider } from "./shared/stepview/context/GameDataProvider";
import { StepNavigationProvider } from "./shared/stepview/context/StepNavigationProvider";
import StepView from "./shared/stepview/StepView";

import { useLanguageCookie } from "./api/useLangCookie";
import IdleInformation from "./shared/components/modals/IdleInformation";
import Snackbar from "./shared/components/Snackbar";
import Message from "./shared/components/views/Message";
import { useTranslation } from "react-i18next";

function App() {
    const { t } = useTranslation();
    const { appId, snack, setSnack, locked, idle } = useAppContext();
    useLanguageCookie();

    const { message, isError } = snack;
    const nonce = getAjaxNonce();

    const onCloseSnack = () => {
        setSnack("");
    };

    return (
        <div className="min-h-screen sm:max-w-7xl m-4 sm:mx-auto px-0 sm:px-4">
            <section>
                {locked ? (
                    <Message message={t("error.locked")} />
                ) : nonce ? (
                    <GameCacheProvider key={appId}>
                        <StepNavigationProvider>
                            <GameDataProvider>
                                <Game>
                                    <StepView />
                                </Game>
                            </GameDataProvider>
                        </StepNavigationProvider>
                    </GameCacheProvider>
                ) : (
                    <Message message={t("error.login")} />
                )}
            </section>
            {message && (
                <Snackbar
                    message={message}
                    error={isError}
                    onClose={onCloseSnack}
                />
            )}
            {idle && <IdleInformation />}
        </div>
    );
}

export default App;
