import { useTranslation } from "react-i18next";

/**
 *
 * @param {[{ round: String,  board: String, black: String, white: String event: String,}]} data
 * @param {Boolean} active
 * @param {Function} onRowClick
 * @returns
 */
function HeadDataTable({ data, active, onRowClick }) {
    const { t } = useTranslation();

    return (
        <div className="flex h-full w-full overflow-y-auto">
            <table className="table-auto border-collapse w-full">
                <thead>
                    <tr>
                        <th className="p-2">{t("headdatatable.head.round")}</th>
                        <th className="p-2">{t("headdatatable.head.board")}</th>
                        <th className="p-2">{t("headdatatable.head.white")}</th>
                        <th className="p-2">{t("headdatatable.head.black")}</th>
                        <th className="p-2">{t("headdatatable.head.event")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(
                        ({ event, round, board, black, white }, index) => {
                            return (
                                <tr
                                    key={`${event}-${round}-data-${index}`}
                                    onClick={() => onRowClick(index)}
                                    className={[
                                        "cursor-pointer",
                                        active === index
                                            ? "border-2 border-pgn-primary"
                                            : "",
                                    ].join(" ")}
                                >
                                    <td className="p-2">{round}</td>
                                    <td className="p-2">{board}</td>
                                    <td className="p-2">{white}</td>
                                    <td className="p-2">{black}</td>
                                    <td className="p-2">{event}</td>
                                </tr>
                            );
                        }
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default HeadDataTable;
