import React, { useState, useRef } from "react";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "../../hooks/useClickOutside";

/**
 * @typedef {Object} PopupProps
 * @property {string} title Header to display
 * @property {string} content main info text, can be HTML
 * @property {string} children the element to apply the info to
 * @property {Object[]} links an optional array of links to display below the main content
 */

/**
 *
 * @param {PopupProps} props
 * @returns Info Popup
 */
export default function InfoPopup({ title, content, children, links }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const ref = useRef(null);
    useClickOutside(ref, () => {
        if (open) setOpen(false);
    });

    const infoLinks = (links = Array.isArray(links) ? links : []);

    return (
        <div className="relative flex gap-1 items-center">
            <div>{children}</div>
            <button
                onClick={() => setOpen(!open)}
                className="bg-transparent text-pgn-primary [&:hover+div]:flex [&+div:hover]:flex"
                ref={ref}
            >
                <Icon name="infoCircle" className="w-6 h-6" />
            </button>
            <div
                className={
                    "z-10 bg-white rounded-md shadow-lg absolute top-6 left-0 w-full px-6 py-7 box-border flex-col gap-6 " +
                    (open ? "flex" : "hidden")
                }
            >
                <div className="flex gap-4 items-center font-[600]">
                    <Icon
                        name="infoCircle"
                        className="text-pgn-primary w-8 h-8 text-lg"
                    />
                    {t("hint.title")}: {title}
                    <button
                        onClick={() => setOpen(false)}
                        className={
                            "ml-auto bg-transparent cursor-pointer" +
                            (open ? "" : " hidden")
                        }
                    >
                        <Icon name="close" className="w-6 h-6" />
                    </button>
                </div>
                <p dangerouslySetInnerHTML={{ __html: content }} />
                {infoLinks && infoLinks.length > 0 && (
                    <ul>
                        {infoLinks.map((link, i) => (
                            <li
                                key={i}
                                className="flex gap-2 items-center p-2 text-pgn-primary font-medium"
                            >
                                <Icon name="arrowRight" />
                                <a
                                    href={link.href}
                                    className="no-underline text-current"
                                >
                                    {link.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}
