/**
 * @packageDocumentation
 *
 * Dieser Namespace beinhaltet Snapshot-Klassen, welche die öffentlich wichtigen Daten der Klassen {@linkcode GameLogic},
 * {@linkcode GameLogicWriter}, {@linkcode HalfMove}, {@linkcode HalfMoveWriter} und des Interfaces {@linkcode ICommand}
 * als Immutable-Datastructure bereitstellt. {@linkcode GameLogic}
 *
 * Die Objekte in diesem Namespace sind nur dann notwendig, wenn man ein JS Framework wie React verwendet, welche keine
 * Änderungsüberwachung tiefer Objektstrukturen unterstützen. Die {@linkcode GameLogic}-Klasse bietet eine Callback
 * {@linkcode GameLogic.gameChangedCallback}-Funktion an, welche bei jeder Änderung der Daten aufgerufen wird.
 *
 * Solche Frameworks können dann das als Parameter mitgelieferte {@linkcode GameLogicSnapshot} als Immutable State verwenden.
 */

export * from './CommandSnapshot';
export * from './GameLogicSnapshot';
export * from './GameLogicWriterSnapshot';
export * from './HalfMoveSnapshot';
export * from './HalfMoveWriterSnapshot';
