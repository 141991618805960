import { useGameDataContext } from "../shared/stepview/context/GameDataProvider";
import useCreateRequest from "./useCreateRequest";

function useChessEntity() {
    const { createPut } = useCreateRequest();
    const { metaData } = useGameDataContext();
    /**
     * Update chess_entity status
     * @param {string} id
     * @param {number} status
     * @param {{}} options
     */
    const updateStatus = async (id, status, options) => {
        try {
            return await createPut(`chess_entity/${id}`, {
                meta: {
                    ...options,
                    ...{
                        _selected_header_data: JSON.stringify(metaData),
                        _order_by_date_round: JSON.stringify(
                            metaData.dateRound
                        ),
                        _order_by_status: status,
                    },
                },
            });
        } catch (error) {
            console.error(error);
        }
    };
    return { updateStatus };
}

export default useChessEntity;
