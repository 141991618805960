import { Color } from '../Color';
import { HalfMove } from '../HalfMove';
import { ICommand } from '../ICommand';

export class SetSANCommand implements ICommand {
  #halfMove: HalfMove;
  #move: string;
  #color: Color;

  get pressed() {
    return this.#halfMove.san == this.#move;
  }

  constructor(halfMove: HalfMove, move: string, color: Color) {
    this.#halfMove = halfMove;
    this.#move = move;
    this.#color = color;
  }

  public get text() {
    return this.#moveToDisplayText();
  }

  #moveToDisplayText(): string {
    if (this.#color == 'w')
      return this.#move
        .replace('K', '♔')
        .replace('Q', '♕')
        .replace('R', '♖')
        .replace('B', '♗')
        .replace('N', '♘');
    else
      return this.#move
        .replace('K', '♚')
        .replace('Q', '♛')
        .replace('R', '♜')
        .replace('B', '♝')
        .replace('N', '♞');
  }

  public execute(): void {
    this.#halfMove.sanFixed =
      this.#halfMove.sanFixed == this.#move ? undefined : this.#move;
  }

  public toString = (): string => {
    return this.text;
  };

  public get prominent() {
    return this.#halfMove.sanFixed == this.#move;
  }
}
