import dayjs from "dayjs";
import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../AppProvider";
import useKeepAlive from "../../../api/useKeepAlive";
import { useGameCacheContext } from "../../stepview/context/GameCacheProvider";
import { useGameDataContext } from "../../stepview/context/GameDataProvider";
import Icon from "../Icon";
import Button from "../buttons/Button";
import MobileWrapper from "../layout/MobileWrapper";
import Input from "./Input";
import Radio from "./Radio";
import ChessTransition from "../ChessTransition";

/**
 *
 * @param {{key: String, value: String | Date | Number, required: Boolean, type: String}[]} props.fields
 * @param {String} title Title of the form
 * @param {Function} onSubmit Function triggered on submit of the form, passing the form values to the parent
 * @param {Function} onFieldChange
 * @param {String} submitLabel optional label for the submit button
 * @returns
 */
export default function Form({ fields, title, onSubmit, submitLabel }) {
    const { t } = useTranslation();
    const { resetApp } = useAppContext();
    const { setUpdateCache } = useGameCacheContext();
    const { player_1, updateMetaData, gameId } = useGameDataContext();
    const { unlock, pauseIdle } = useKeepAlive();

    const validate = (values) => {
        const errors = {};
        fields.forEach((field) => {
            if (!values[field.key] && field.required) {
                errors[field.key] = t("form.error.required");
            }
        });

        return errors;
    };

    /**
     * Method to update the context given a {@link React.ChangeEvent}.
     * @param {React.ChangeEvent<any>} event
     */
    const update = (event) => {
        const { target: { name, value } = {}, currentTarget: { type } = {} } =
            event;
        if (name && value) {
            if (type === "date") {
                let date = dayjs(value, "YYYY-MM-DD");
                date = date.hour(6);
                date = date.minute(0);
                date = date.second(0);

                const unix = date.unix();
                updateMetaData({
                    [name]: unix,
                });
            } else {
                updateMetaData({
                    [name]: value,
                });
            }
            setUpdateCache(true);
        }
    };

    /**
     * Check if the player_1 has valid "data" (api response).
     * @returns {boolean}
     */
    const validPlayerData = () => player_1.data.length > 0 && player_1.data[0];

    return (
        <div className="max-w-2xl">
            {title && <h3 className="font-bold text-2xl mb-4">{title}</h3>}
            <Formik
                initialValues={fields.reduce(
                    (acc, cur) => ({ ...acc, [cur.key]: cur.value || "" }),
                    {}
                )}
                enableReinitialize={true}
                validate={validate}
                onSubmit={onSubmit}
            >
                {({
                    isSubmitting,
                    isValid,
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                }) => (
                    <FormikForm className="flex flex-col gap-3">
                        {fields.map(({ key, type, required, options }) =>
                            type === "radio" && options ? (
                                <label
                                    key={key}
                                    className="flex flex-col gap-4"
                                >
                                    <span className="font-bold">
                                        {t("form.label." + key)}
                                    </span>
                                    <Radio
                                        name={key}
                                        options={options}
                                        required={!!required}
                                        initValue={values[key]}
                                        onChange={(event) => {
                                            handleChange(event);
                                            update(event);
                                        }}
                                    />
                                </label>
                            ) : (
                                <Input
                                    key={key}
                                    name={key}
                                    label={t("form.label." + key)}
                                    placeholder={t("form.placeholder")}
                                    type={type}
                                    required={!!required}
                                    setFieldValue={setFieldValue}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        update(event);
                                    }}
                                />
                            )
                        )}
                        <MobileWrapper heightCls="h-18">
                            <div className="flex gap-1">
                                <div
                                    className={
                                        "text-pgn-primary w-8 tablet:mt-6 " +
                                        (validPlayerData() ? "hidden" : "")
                                    }
                                >
                                    <ChessTransition className="text-4xl" />
                                </div>
                                <Button
                                    type="submit"
                                    disabled={
                                        !validPlayerData() ||
                                        !!(isSubmitting || !isValid)
                                    }
                                    className="ml-1 mb-1 mr-0 tablet:mr-auto mt-0 tablet:mt-6"
                                >
                                    {submitLabel ?? t("buttons.submit")}
                                    <Icon name="circleArrowRight" />
                                </Button>
                            </div>
                            <Button
                                type="button"
                                disabled={!validPlayerData()}
                                invert={true}
                                textColor="text-pgn-primary"
                                className="ml-1 mb-1 mr-0 tablet:mr-auto"
                                icon="pause"
                                onClick={() => {
                                    unlock(gameId);
                                    pauseIdle();
                                    resetApp();
                                }}
                            >
                                {t("step2.saveProcess")}
                            </Button>
                        </MobileWrapper>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
}
