import React from "react";
import { Field, ErrorMessage } from "formik";

/**
 *
 * @param {String} props.label Display name
 * @param {String} props.name unique name of the field
 * @param {String} props.placeholder
 * @param {String} props.type
 * @returns
 */
export default function Input({
    label,
    name,
    placeholder,
    type,
    setFieldValue,
    onBlur,
    ...props
}) {
    const exceptions = ["checkbox", "radio"];

    const cleanNumber = (value) => {
        return value.replaceAll(/[^\d,.]/g, "").replace(",", ".");
    };

    return (
        <div className="flex flex-col gap-1">
            <label
                htmlFor={name}
                className={`flex ${
                    exceptions.includes(type) ? "py-2 gap-2" : "flex-col gap-1"
                }`}
            >
                {!exceptions.includes(type) && (
                    <span className="font-bold">{label}</span>
                )}
                <Field
                    id={name}
                    placeholder={placeholder}
                    name={name}
                    type={type && type !== "number" ? type : "text"}
                    className="flex flex-row px-3 py-2 border border-grey justify-start"
                    onBlur={(event) => {
                        let value = event.target.value;
                        if (type === "number") {
                            value = cleanNumber(value);
                            setFieldValue(name, value);
                        }
                        onBlur({
                            ...event,
                            target: { ...event.target, value, name },
                        });
                    }}
                    {...props}
                />
                {exceptions.includes(type) && <span>{label}</span>}
            </label>
            <ErrorMessage
                name={name}
                component="span"
                className="text-red-700"
            />
        </div>
    );
}
