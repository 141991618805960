import { useId } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";
import Button from "../buttons/Button";
import { useAppContext } from "../../../AppProvider";

/**
 * Idle modal for the user.
 * @returns {Modal}
 */
function IdleInformation() {
    const { t } = useTranslation();
    const id = useId();

    const { resetApp } = useAppContext();

    const title = t("idle.title");
    const description = t("idle.message");

    /**
     * Reset app on idle confirmation/closing.
     */
    const onClose = () => {
        resetApp();
    };

    return (
        <Modal
            modalId={id}
            open={true}
            onClose={onClose}
            center
            aria-labelledby={title ? id + "-modal-title" : undefined}
            aria-describedby={
                description ? id + "-modal-description" : undefined
            }
            classNames={{
                overlay: "!bg-opacity-90 backdrop-blur-sm",
                modal: "h-1/3 w-2/3 md:h-[300px] md:w-[400px] m-0 p-4 md:p-8",
                closeButton: "translate-x-1 md:-translate-x-1/2 -translate-y-1/2 mt-4 md:mt-7",
            }}
        >
            <div className="h-full flex flex-col justify-between">
                <h3 className="-mt-1 md:-mt-2">{title}</h3>
                <p className="">{description}</p>
                <div className="flex justify-end">
                    <Button className="w-20 justify-center" onClick={onClose}>
                        {t("buttons.ok")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default IdleInformation;
