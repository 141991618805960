import { useEffect, useState } from "react";
import { useStepNavigationContext } from "../stepview/context/StepNavigationProvider";
import { useGameDataContext } from "../stepview/context/GameDataProvider";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} TabDef
 * @property {string} label
 * @property {string} value
 * @property {string} icon
 */

/**
 * Hook to retrieve tab config.
 * @returns {TabDef[]}
 */
function useTabs() {
    const [tabs, setTabs] = useState(/** @type {TabDef[]} */ ([]));

    const { t } = useTranslation();

    const { getActivePath } = useStepNavigationContext();
    const { player_1, player_2 } = useGameDataContext();

    useEffect(() => {
        const tabs = [];
        const { path } = getActivePath();
        const pathLCase = path.toLowerCase();

        if (pathLCase === "pgnedit") {
            tabs.push({
                label: t("chessboard"),
                value: "chess",
                icon: "chessBoard",
            });
        }

        tabs.push({
            label: t("player") + " 1",
            value: "player_1",
            icon: player_1?.files.length > 0 ? "check" : "",
        });

        const addPlayerTwo =
            pathLCase === "upload" ? true : player_2.files.length > 0;

        if (addPlayerTwo) {
            tabs.push({
                label: t("player") + " 2",
                value: "player_2",
                icon: player_2?.files.length > 0 ? "check" : "",
            });
        }

        setTabs(tabs);
    }, [setTabs, t, getActivePath, player_1, player_2]);

    return tabs;
}

export default useTabs;
