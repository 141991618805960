import { useTranslation } from "react-i18next";
import Button from "../../../shared/components/buttons/Button";
import { useAppContext } from "../../../AppProvider";
import { useGameDataContext } from "../../../shared/stepview/context/GameDataProvider";
import useChessEntity from "../../../api/useChessEntity";
import { getAjaxNonce, getUserData } from "../../../api/PGNVariables";
import { getBaseURL } from "../../../config";
import useKeepAlive from "../../../api/useKeepAlive";

/**
 * {@link Button} to complete the editing process.
 * This will trigger {@link updateStatus}.
 * @param {string} props.className
 * @returns {Button}
 */
function CompleteEditButton({ className = "" }) {
    const { t } = useTranslation();
    const { resetApp, setSnack, setCredits } = useAppContext();
    const { folder, gameLogic, setLoading, gameId } = useGameDataContext();
    const { updateStatus } = useChessEntity();
    const { unlock, pauseIdle } = useKeepAlive();

    /**
     * Method to complete the editing process.
     */
    const completeEdit = async () => {
        setLoading(true);

        const response = await updateStatus(gameId, 2, {
            _pgn_string: gameLogic.save(),
        });

        if (response) {
            const nonce = getAjaxNonce();
            const response = await fetch(
                `${getBaseURL()}/wp-json/wp/v2/users/me`,
                {
                    headers: {
                        "X-WP-Nonce": nonce,
                    },
                    credentials: "include",
                }
            );

            if (response.ok) {
                const data = await response.json();
                setCredits(data.user_credits.total_credits);
                unlock(gameId);
                pauseIdle();

                const userData = getUserData();
                if (userData) {
                    userData.selectedFolderId = folder;
                }
                resetApp();
            } else {
                setSnack({ message: t("error.endEdit"), isError: true });
            }
        } else {
            setSnack({ message: t("error.endEdit"), isError: true });
        }

        setLoading(false);
    };
    return (
        <Button
            type="submit"
            icon="check"
            onClick={completeEdit}
            className={className}
        >
            {t("step3.submit")}
        </Button>
    );
}

export default CompleteEditButton;
