import React from "react";
import Icon from "../Icon";
import { useTabContext } from "../../stepview/context/TabProvider";

/**
 *
 * @param {{label: String, value: String | Number, icon: String}[]} props.tabs
 * @param {String | Number} props.tab Value of the active tab
 * @param {function} props.setTab Eventhandler to change the tab
 * @returns List of tabs to switch between content
 */
export default function Tabs({ tabs}) {
    const {tab, setTab} = useTabContext();    

    return (
        <ul className="flex items-end mb-2 w-full">
            {tabs.map((tabConfig) => (
                <li className="flex-1" key={tabConfig.value}>
                    <button
                        className="w-full py-1 sm:py-3 bg-transparent border-b-2 border-grey-400 flex flex-row gap-2 cursor-pointer justify-center items-center"
                        onClick={() => setTab(tabConfig.value)}
                        disabled={tab === tabConfig.value}
                        style={{
                            fontWeight: tab === tabConfig.value ? 500 : 400,
                            borderColor:
                                tab === tabConfig.value ? "#10B981" : undefined,
                        }}
                    >
                        {tabConfig.icon && (
                            <Icon
                                name={tabConfig.icon}
                                className={
                                    tabConfig.value === "chess"
                                        ? "text-black"
                                        : tab === tabConfig.value
                                        ? "text-pgn-primary"
                                        : "text-gray-300"
                                }
                                size={6}
                            />
                        )}
                        <span
                            className={
                                "p-1 text-sm " +
                                (tab === tabConfig.value
                                    ? "text-black"
                                    : "text-gray-300")
                            }
                        >
                            {tabConfig.label}
                        </span>
                    </button>
                </li>
            ))}
        </ul>
    );
}
