import { useTranslation } from "react-i18next";
import Button from "../../shared/components/buttons/Button";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";

const filters = [
    {
        label: "filter.allmoves",
    },
    {
        label: "filter.movesByStriking",
    },
];

/**
 * @param {object} props
 * @param {string} firstLabel
 * @param {string} secondLabel
 * @returns "Filter switch" (Container with two buttons)
 */
function FilterSwitch({ firstLabel, secondLabel }) {
    const { gameLogic, gameLogicSnapshot } = useGameDataContext();
    const { t } = useTranslation();

    const activeButtonId = gameLogicSnapshot.filtered ? 1 : 0;

    /**
     * Update the gameLogic.filtered state if conditions are met.
     * @param {number} clickedId
     */
    const onFilter = (clickedId) => {
        if (activeButtonId !== clickedId) {
            gameLogic.filtered = !gameLogicSnapshot.filtered;
        }
    };
    /**
     * Function to filter the halfMoves by confident and return the length.
     * @returns {number}
     */
    const filterCount = () => {
        return gameLogicSnapshot.halfMoves.filter(
            (halfMove) => !halfMove.confident
        ).length;
    };

    return (
        <div className="flex">
            {filters.map(({ label }, index) => {
                return (
                    <Button
                        key={index}
                        className={
                            index === 0
                                ? "border-r-0 rounded-r-none hover:!border-r"
                                : "border-l-0 rounded-l-none hover:!border-l"
                        }
                        invert={activeButtonId !== index}
                        onClick={() => onFilter(index)}
                    >
                        {index === 0
                            ? t(firstLabel ? firstLabel : label)
                            : `${t(
                                  secondLabel ? secondLabel : label
                              )} (${filterCount()})`}
                    </Button>
                );
            })}
        </div>
    );
}

export default FilterSwitch;
