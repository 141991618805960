import ChessTransition from "./ChessTransition";

/**
 * @returns loading
 */
function Loading() {
    return (
        <div className="fixed top-0 left-0 h-full w-full z-[110] bg-opacity-90 backdrop-blur-sm text-pgn-primary">
            <ChessTransition className="top-1/2 left-1/2 text-[80px]" />
        </div>
    );
}

export default Loading;
