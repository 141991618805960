import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";

/**
 * @typedef {Object} useGameLogicDef
 * @property {boolean} lastMoveSelected
 * @property {Function} createPgnHead
 * @property {Function} createPgn
 * @property {Function} updateGameLogic
 */

/**
 * @returns {useGameLogicDef} Utility functions for the game logic
 */
function useGameLogic() {
    const [lastMoveSelected, setLastMoveSelected] = useState(false);

    const { metaData, gameLogicSnapshot, setGameLogicSnapshot, setLoading } =
        useGameDataContext();

    /**
     * Create header for pgn file.
     * @example `[Event "FIDE World Championship 2021"]\n
     *  [Site "Dubai, United Arab Emirates"]\n
     *  [Date "2021.12.05"]\n
     *  [Round "8.1"]\n
     *  [White "Carlsen, Magnus"]\n
     *  [Black "Nepomniachtchi, Ian"]\n
     *  [Result "1-0"]`
     *
     * @returns {string}
     */
    const createPgnHead = () => {
        let head = [];
        const pgnDict = {
            event: "Event",
            location: "Site",
            dateRound: "Date",
            round: "Round",
            board: "Board",
            white: "White",
            black: "Black",
            result: "Result",
        };

        for (const [key, value] of Object.entries(metaData)) {
            if (pgnDict[key] && value) {
                if (key.toLowerCase().includes("date")) {
                    const displayDate = dayjs.unix(value).format("YYYY-MM-DD");
                    head.push(`[${pgnDict[key]} "${displayDate}"]`);
                } else {
                    head.push(`[${pgnDict[key]} "${value.replace("\"", "\"\"")}"]`);
                }
            }
        }

        return head.join("\n");
    };

    /**
     * Create pgn for a pgn file.
     * @example `[Event "FIDE World Championship 2021"]\n
     *  [Site "Dubai, United Arab Emirates"]\n
     *  [Date "2021.12.05"]\n
     *  [Round "8.1"]\n
     *  [White "Carlsen, Magnus"]\n
     *  [Black "Nepomniachtchi, Ian"]\n
     *  [Result "1-0"]\n
     *  \n\n
     *  1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Bd3 d5 5. Nxe5 Nd7 6. Nxd7 Bxd7
     *  7. Nd2 Nxd2 8. Bxd2 Bd6 9. O-O h5 10. Qe1+ Kf8 11. Bb4 Qe7 12. Bxd6 Qxd6
     *  13. Qd2 Re8 14. Rae1 Rh6 15. Qg5 c6 16. Rxe8+ Bxe8 17. Re1 Qf6 18. Qe3 Bd7
     *  19. h3 h4 20. c4 dxc4 21. Bxc4 b5 22. Qa3+ Kg8 23. Qxa7 Qd8 24. Bb3 Rd6
     *  25. Re4 Be6 26. Bxe6 Rxe6 27. Rxe6 fxe6 28. Qc5 Qa5 29. Qxc6 Qe1+
     *  30. Kh2 Qxf2 31. Qxe6+ Kh7 32. Qe4+ Kg8 33. b3 Qxa2 34. Qe8+ Kh7
     *  35. Qxb5 Qf2 36. Qe5 Qb2 37. Qe4+ Kg8 38. Qd3 Qf2 39. Qc3 Qf4+
     *  40. Kg1 Kh7 41. Qd3+ g6 42. Qd1 Qe3+ 43. Kh1 g5 44. d5 g4
     *  45. hxg4 h3 46. Qf3 1-0`
     *
     * @returns {string}
     */
    const createPgn = () => {
        const head = createPgnHead();
        const pgn = gameLogicSnapshot.bestBoardPgn;

        if (!pgn || pgn === "") return "";

        const fullPgn = `${pgn} ${metaData.result}`;
        if (head !== "") {
            return `${head}\n\n${fullPgn}`;
        } else {
            return fullPgn;
        }
    };

    /**
     * Method to replace old gameLogic snapshot with new gameLogic snapshot.
     */
    const updateGameLogic = useCallback(
        (snapshot) => {
            setGameLogicSnapshot(snapshot);
            setLoading(false);
        },
        [setGameLogicSnapshot, setLoading]
    );

    useEffect(() => {
        if (!gameLogicSnapshot) return;

        if (gameLogicSnapshot.writer2) {
            setLastMoveSelected(
                gameLogicSnapshot.writer2.lastMoveFixed !== undefined
            );
        } else {
            setLastMoveSelected(
                gameLogicSnapshot.writer1.lastMoveFixed !== undefined
            );
        }
    }, [gameLogicSnapshot]);

    return { lastMoveSelected, createPgn, updateGameLogic };
}

export default useGameLogic;
