import { RecognizedText } from '../recognition/v1/RecognizedText';

/**
 * Beschreibt eine mögliche Variante eines Spiels zu dem Halbzug zu dem dieser BoardCandidate gehört.
 *
 * Falls ich später einmal die Verschiebung interaktiv in die Erkennung der Bretter integrieren sollte, dann werde ich das auch hier in diese Klasse integrieren.
 */

export class BoardCandidateSnapshot {
  /**
   * Siehe {@linkcode fen}
   */
  readonly #fen: string;

  /**
   * Siehe {@linkcode recognizedMoves}
   */
  readonly #recognizedMoves: RecognizedText[];

  /**
   * Siehe {@linkcode probability}
   */
  readonly #probability: number;

  /**
   * Erstellt eine neue Spielvariante
   * @param recognizedMove Erkannter SAN-Ausdrück für den letzten Zug in dieser Variante
   * @param fen FEN-Ausdruck dieser Variante
   * @param boardBefore Verknüpfung zu der Variante des vorherigen Halbzuges auf den diese Variante aufbaut.
   */
  constructor(
    recognizedMove: RecognizedText,
    fen: string,
    boardBefore: BoardCandidateSnapshot | null
  ) {
    this.#fen = fen;
    if (boardBefore) {
      this.#recognizedMoves = [...boardBefore.recognizedMoves, recognizedMove];
      this.#probability = boardBefore.probability + recognizedMove.p;
    } else {
      this.#recognizedMoves = [recognizedMove];
      this.#probability = recognizedMove.p;
    }
  }

  /**
   * Liefert den FEN-Ausdruck den das Brett nach diesem Zug laut dieser Variante dann hat.
   */
  public get fen() {
    return this.#fen;
  }

  /**
   * Liste der SAN-Halbzüge laut dieser Variante.
   */
  public get moves() {
    return this.recognizedMoves.map((x) => x.t);
  }

  /**
   * Erkannter letzer Halbzug (SAN und Wahrscheinlichkeit) innerhalb dieser Variante.
   */
  public get recognizedMove() {
    return this.#recognizedMoves[this.#recognizedMoves.length - 1];
  }

  /**
   * Lister aller erkannten Halbzüge (SAN und Wahrscheinlichkeit) innerhalb dieser Variante.
   */
  public get recognizedMoves() {
    return this.#recognizedMoves;
  }

  /**
   * Gesamtwahrscheinlichkeit dieser Variante, als Summe der Wahrscheinlichkeiten über alle Halbzüge
   */
  public get probability() {
    return this.#probability;
  }
}
