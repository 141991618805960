import { Sheet } from '../recognition';
import { RowLocation } from '../RowLocation';
import { GameLogicWriter } from '../GameLogicWriter';

/**
 * Snapshot-Klasse korrespondierend zu der {@linkcode GameLogicWriter}-Klasse
 *
 * Wird verwendet in {@linkcode GameLogicSnapshot.writer1} und {@linkcode GameLogicSnapshot.writer2}
 */
export class GameLogicWriterSnapshot {
  /**
   * Siehe {@linkcode GameLogicWriter.show}
   */
  public readonly show: boolean;

  /**
   * Siehe {@linkcode GameLogicWriter.lastMoveFixed}
   */
  public readonly lastMoveFixed: RowLocation | undefined;

  /**
   * Siehe {@linkcode GameLogicWriter.lastMoveCalculated}
   */
  public readonly lastMoveCalculated: RowLocation | undefined;

  /**
   * Siehe {@linkcode GameLogicWriter.sheets}
   */
  public readonly sheets: readonly Sheet[];

  /**
   * @private
   * Siehe {@linkcode GameLogicWriter.constructor}
   */
  constructor(gameLogicWriter: GameLogicWriter) {
    this.show = gameLogicWriter.show;
    this.lastMoveFixed = gameLogicWriter.lastMoveFixed;
    this.lastMoveCalculated = gameLogicWriter.lastMoveCalculated;
    this.sheets = gameLogicWriter.sheets;
  }
}
