import React, { Suspense } from "react";

function PaginatorComponent({
    currentPage,
    pageCount,
    setPage,
    className,
    pageTitle,
}) {
    const pages = new Array(pageCount).fill(0);

    return (
        <div
            className={
                "flex w-full sm:w-auto items-center justify-center " + className
            }
        >
            {pages.map((page, i) => (
                <button
                    key={i}
                    className={
                        "bg-transparent w-full cursor-pointer flex flex-col gap-1 sm:gap-2 after:content-[''] after:h-1.5 after:w-full after:rounded-full " +
                        (i === currentPage
                            ? "after:bg-pgn-primary"
                            : "after:bg-gray-300")
                    }
                    onClick={() => setPage(i)}
                >
                    <span className="px-2 text-xs self-center">
                        {pageTitle + " " + (i + 1)}
                    </span>
                </button>
            ))}
        </div>
    );
}

/**
 *
 * @param {Number} props.currentPage
 * @param {function} props.onDelete Communicates the new page to the parent
 * @param {Number} props.pageCount
 * @param {String} props.pageTitle Numbered title to display for each page
 * @returns Simple bullet paginator
 */
export default function Paginator(props) {
    return (
        <Suspense fallback="loading">
            <PaginatorComponent {...props} />
        </Suspense>
    );
}
