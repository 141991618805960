import { useCallback } from "react";
import { getBaseURL } from "../config";
import { getAjaxNonce } from "./PGNVariables";

/**
 * Basic hook with function to retrieve game data.
 * @returns {{ getGame: Function}}
 */
function useGame() {
    const getGame = useCallback(async (id) => {
        try {
            const response = await fetch(
                `${getBaseURL()}/wp-json/wp/v2/chess_entity/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-WP-Nonce": getAjaxNonce(),
                    },
                }
            );

            if (!response.ok) {
                throw new Error(
                    JSON.stringify({
                        status: response.status,
                        body: await response.text(),
                    })
                );
            }

            return response.json();
        } catch (error) {
            throw error;
        }
    }, []);
    return { getGame };
}

export default useGame;
