import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
    useTransition,
} from "react";
import { getUserData } from "./api/PGNVariables";
import useLocalStorage from "./shared/hooks/useLocalStorage";

/**
 * @typedef {Object} UserDef
 * @property {number} id
 * @property {boolean} loggedIn
 * @property {string} username
 * @property {number} credits
 * @property {number[]} defaultFolderId
 * @property {number[]} selectedFolderId
 */

/**
 * @typedef {Object} SharedDef
 * @property {number} author
 * @property {number} parent
 */

/**
 *  @type {{
 *  appId: number,
 *  setAppId: Function,
 *  resetApp: Function,
 *  user: UserDef,
 *  setUser: Function,
 *  shared: boolean,
 *  setShared: Function,
 *  credits: number,
 *  setCredits: Function,
 *  snack: string,
 *  setSnack: Function
 *  locked: boolean,
 *  setLocked: Function,
 *  keepAliveId: number|null,
 *  setkeepAliveId: Function,
 *  stopKeepAlive: Function,
 *  idle: boolean,
 *  setIdle: Function,
 *  isPending: boolean,
 * }}
 */
const AppContext = createContext();

const userData = getUserData();
const userCredits = getUserData().credits;

export const AppProvider = ({ children }) => {
    const [appId, setAppId] = useState(new Date().valueOf());
    const [user, setUser] = useState(/** @type {UserDef} */ (userData));
    const [shared, setShared] = useState(/** @type {SharedDef|null} */ (null));
    const [credits, setCredits] = useState(/** @type {number} */ (userCredits));
    const [snack, setSnack] = useState(
        /** @type {{message: String, isError: boolean}} */ ({})
    );
    const [locked, setLocked] = useState(false);
    const [keepAliveId, setkeepAliveId] = useState(
        /** @type {number|null} */ (null)
    );
    const [idle, setIdle] = useState(false);

    const [isPending, startTransition] = useTransition();

    const { clearStorage } = useLocalStorage();

    /**
     * Stop the keep alive process and clear the keepAliveId.
     */
    const stopKeepAlive = useCallback(() => {
        if (keepAliveId !== null) {
            clearInterval(keepAliveId);
            setkeepAliveId(null);
        }
    }, [keepAliveId]);

    /**
     * Reset App and localStorage state
     */
    const resetApp = useCallback(() => {
        startTransition(() => {
            clearStorage();
            if (window.location.search !== "") {
                window.history.replaceState(
                    {},
                    document.title,
                    window.location.pathname
                );
            }

            setLocked(false);
            setIdle(false);
            stopKeepAlive();

            setAppId(new Date().valueOf());
        });
    }, [clearStorage, stopKeepAlive]);

    const context = useMemo(
        () => ({
            appId,
            setAppId,
            resetApp,
            user,
            setUser,
            shared,
            setShared,
            credits,
            setCredits,
            snack,
            setSnack,
            locked,
            setLocked,
            keepAliveId,
            setkeepAliveId,
            stopKeepAlive,
            idle,
            setIdle,
            isPending,
        }),
        [
            appId,
            user,
            shared,
            credits,
            snack,
            locked,
            keepAliveId,
            idle,
            resetApp,
            stopKeepAlive,
            isPending,
        ]
    );

    return (
        <AppContext.Provider value={context}>{children}</AppContext.Provider>
    );
};

/**
 * @returns {AppContext}
 */
export const useAppContext = () => {
    return useContext(AppContext);
};

export default AppContext;
