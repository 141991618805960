import { RowIndex } from './RowIndex';
import { SheetIndex } from './SheetIndex';
import { getRowLocationName } from './Utils';

/**
 * @private
 *
 * Beschreibt eine konkrete Zelle eines Schreibers auf seinem Formular
 *
 * Dabei geht es also nicht um einen logischen Spiel-Index sondern wirklich um die physikalisch
 * auf dem Papier vorhandene Zelle, also Halbzug.
 */

export class RowLocation {
  /**
   * @param sheetIndex Das Blatt des Schreibers, beginnend beim Index 0
   * @param rowIndex Die Zeile auf dem Blatt, beginnend beim Index 0
   */
  constructor(
    public readonly sheetIndex: SheetIndex,
    public readonly rowIndex: RowIndex
  ) {}

  /**
   * Liefert eine lesbare Beschreibung des Halbzuges.
   *
   * @returns
   */
  toString() {
    return `${this.sheetIndex + 1}-${getRowLocationName(this.rowIndex)}`;
  }

  /**
   * Vergleicht diese RowLocation mit einer anderen RowLocation des gleichen Schreibers.
   *
   * @private
   * @param other Die andere RowLocation, mit der diese verglichen werden soll.
   * @returns ```true```falls  ```other``` eine frühere Zelle beschreibt als diese Zelle, sonst ```false```
   */
  public isGreaterThan(other: RowLocation) {
    return (
      this.sheetIndex > other.sheetIndex ||
      (this.sheetIndex == other.sheetIndex && this.rowIndex > other.rowIndex)
    );
  }

  /**
   * Prüft zwei RowLocation-Objekte auf fachliche Gleichheit.
   *
   * Hierbei wird also nicht die Objektidentität im Hauptspeicher geprüft, sondern ob
   * {}
   * @param other
   * @returns ```true```, wenn beide die gleiche Zelle beschreiben, sonst ``false```
   */
  public isEqual(other: RowLocation) {
    return (
      this.sheetIndex == other.sheetIndex && this.rowIndex == other.rowIndex
    );
  }

  /**
   * Vergleicht diese RowLocation mit einer anderen RowLocation des gleichen Schreibers.
   *
   * @private
   * @param other Die andere RowLocation, mit der diese verglichen werden soll.
   * @returns ```true```falls  ```other``` eine frühere oder gleiche Zelle beschreibt als diese Zelle, sonst ```false```
   */
  public isEqualOrGreaterThan(other: RowLocation) {
    return (
      this.sheetIndex > other.sheetIndex ||
      (this.sheetIndex == other.sheetIndex && this.rowIndex >= other.rowIndex)
    );
  }
}
