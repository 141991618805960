import FilterSwitch from "./FilterSwitch";
import ResetButton from "./buttons/ResetButton";

/**
 * Toolbar for ChooseLastMove and EditMoves.
 * @param {object} props
 * @returns
 */
function Toolbar(props) {
    return (
        <div className="flex flex-col sm:flex-row justify-between">
            <FilterSwitch {...props} />
            <ResetButton className="hidden tablet:inline-flex"></ResetButton>
        </div>
    );
}

export default Toolbar;
