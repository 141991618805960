import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../shared/components/buttons/Button";
import Paginator from "../../shared/components/form/Paginator";
import RoundButton from "../../shared/components/form/RoundButton";
import FullScreenImage from "../../shared/components/modals/FullScreenImage";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import { useStepNavigationContext } from "../../shared/stepview/context/StepNavigationProvider";
import ArrowKeys from "./ArrowKeys";

/**
 * Component to display player files (images).
 * @param {string} props.player player name
 * @param {Function} props.onAdd
 * @param {number} props.index
 * @param {Function} props.setIndex
 * @returns
 */
function ImageSliderComponent({ player, onAdd, index, setIndex }) {
    const { t } = useTranslation();
    const { getActivePath } = useStepNavigationContext();
    const context = useGameDataContext();

    const images = context[player].files;

    const isUploadPath = getActivePath().path === "upload";

    const [fullScreenOpen, setFullScreenOpen] = useState(false);
    const [zoom, setZoom] = useState(0);
    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

    /**
     * Toggle iamge full screen.
     */
    const toggleFullScreen = () => {
        setFullScreenOpen(!fullScreenOpen);
    };

    /**
     * Zoom into image.
     */
    const zoomIn = () => {
        setZoom(zoom + 1);
    };

    /**
     * Zoom out of image.
     */
    const zoomOut = () => {
        if (zoom - 1 < 1) {
            setZoomPosition({ x: 0, y: 0 });
        }
        setZoom(zoom - 1);
    };

    /**
     * Delete image from player context.
     * @param {number} index
     */
    const onDelete = (index) => {
        const newFiles = [...context[player].files];

        URL.revokeObjectURL(newFiles[index].preview);
        newFiles.splice(index, 1);
        context.updatePlayer(player, { files: newFiles });
        if (index === images.length - 1) {
            setIndex(index - 1);
        }
    };

    /**
     * Move image in player context.
     * @param {number} index
     */
    const onMove = (index) => {
        //FIXME: Enable the move feature for the metadata/pgnedit view to move the images -> apiResponse
        const newFiles = [...context[player].files];

        if (index < newFiles.length - 1) {
            const item = newFiles.splice(index, 1);
            newFiles.splice(index + 1, 0, item[0]);
        } else {
            const item = newFiles.pop();
            newFiles.unshift(item);
        }
        context.updatePlayer(player, { files: newFiles });
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-col gap-1 items-center mb-2">
                {isUploadPath && (
                    <div className="flex w-full sm:justify-center">
                        {images.length > 1 && (
                            <Button
                                minimal
                                onClick={() => onMove(index)}
                                icon="arrowRightBar"
                                className="pt-0 pb-0"
                            >
                                {t("images.moveImage")}
                            </Button>
                        )}
                        <Button
                            icon="imageMinus"
                            invert
                            className="flex sm:hidden ml-auto mr-2"
                            onClick={() => onDelete(index)}
                        ></Button>
                        <Button
                            icon="imagePlus"
                            invert
                            className="flex sm:hidden"
                            onClick={onAdd}
                        ></Button>
                    </div>
                )}
                {images.length > 1 && (
                    <Paginator
                        pageCount={images.length}
                        setPage={setIndex}
                        currentPage={index}
                        className="mt-1 sm:mx-auto"
                        pageTitle={t("images.image")}
                    />
                )}
            </div>
            <>
                {images[index] && (
                    <div className="relative pl-2">
                        <figure className="flex justify-center items-center overflow-hidden">
                            <img
                                src={images[index].preview}
                                draggable={false}
                                className={
                                    (getActivePath().path === "pgnedit"
                                        ? "max-h-80 tiny:max-h-[25rem] sm:max-h-[30rem] tablet:max-h-fit"
                                        : "") +
                                    (isUploadPath
                                        ? "max-h-[38rem] tablet:max-h-[25rem] 4xl:max-h-fit"
                                        : "")
                                }
                                style={{
                                    transform: `
                                            scale(${
                                                1 + 0.2 * zoom
                                            }) translate(${
                                        zoomPosition.x * 10
                                    }%, ${zoomPosition.y * 10}%)`,
                                }}
                                alt={images.name}
                            />
                        </figure>
                        <div className="flex gap-5 flex-col absolute top-0 -left-3 bottom-0 justify-center">
                            <RoundButton
                                onClick={toggleFullScreen}
                                title={t("images.fullScreen")}
                                icon="fullScreen"
                            />
                            <RoundButton
                                onClick={zoomIn}
                                title={t("images.zoomIn")}
                                disabled={zoom > 4}
                                icon="plus"
                            />
                            <RoundButton
                                onClick={zoomOut}
                                title={t("images.zoomOut")}
                                disabled={zoom === 0}
                                icon="minus"
                            />
                        </div>
                        {zoom > 0 && (
                            <ArrowKeys
                                position={zoomPosition}
                                setPosition={setZoomPosition}
                            />
                        )}
                    </div>
                )}

                {isUploadPath && (
                    <div className="justify-between pt-4 hidden sm:flex">
                        <Button
                            minimal
                            onClick={() => onDelete(index)}
                            icon="trash"
                        >
                            {t("images.deleteImage")}
                        </Button>
                        <Button invert onClick={onAdd} icon="plus">
                            {t("images.addImage")}
                        </Button>
                    </div>
                )}
            </>
            {images[index] && (
                <FullScreenImage
                    isOpen={fullScreenOpen}
                    close={toggleFullScreen}
                >
                    <img src={images[index].preview} alt={images[index].name} />
                </FullScreenImage>
            )}
        </div>
    );
}

/**
 *
 * @param {Object[]} props.images Array of files with blob previews
 * @param {function} props.onDelete Communicates the index to the parent to delete the image
 * @param {function} props.onMove Communicates the old and new index to the parent to move the image
 * @param {String} props.name Player name
 * @returns Preview slider of uploaded images
 */
export default function ImageSlider(props) {
    return (
        <Suspense fallback="loading">
            <ImageSliderComponent {...props} />
        </Suspense>
    );
}
