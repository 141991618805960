import React, { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../shared/hooks/useClickOutside";
import { useTranslation } from "react-i18next";
import Icon from "../../shared/components/Icon";
import Button from "../../shared/components/buttons/Button";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";

/**
 *
 * @param {String} props.src Source of the reference image
 * @param {Number} props.x Coordinate of the point to start drawing from horizontally
 * @param {Number} props.y Coordinate of the point to start drawing from vertically
 * @param {Number} props.w Width of the cell
 * @param {Number} props.h Height of the cell
 * @param {Boolean} props.hideActions disable all MoveBox-actions
 * @param {Function} props.onDelete EventHandler to delete moves
 * @param {Function} props.onAdd EventHandler to add moves
 * @param {number} props.id Box id
 * @param {Boolean} props.disableBox disable the MoveBox
 * @returns
 */
export default function MoveBox({
    x,
    y,
    w,
    h,
    writer,
    hideActions,
    onDelete,
    onAdd,
    id,
    disableBox,
}) {
    const { player_1, player_2 } = useGameDataContext();
    const [isEdit, setIsEdit] = useState(false);
    const canvas = useRef(null);
    const { t } = useTranslation();

    const editRef = useRef(null);
    useClickOutside(editRef, () => {
        if (isEdit) setIsEdit(false);
    });

    useEffect(() => {
        const { rowLocation: { sheetIndex } = {} } = writer;
        const file = (id === 0 ? player_1 : player_2)["files"][sheetIndex];
        const src = file ? file.preview : "";

        var context = canvas.current.getContext("2d");
        var imageObj = new Image();
        imageObj.onload = function () {
            context.drawImage(imageObj, x, y, w, h, 0, 0, w, h);
        };

        imageObj.src = src;
    }, [canvas, x, y, w, h, writer, id, player_1, player_2]);

    // TODO: Boost performance with intersection observer

    return (
        <div
            className="bg-transparent relative"
            onClick={(e) => {
                e.preventDefault();
                if (!hideActions) {
                    setIsEdit(true);
                }
            }}
            ref={editRef}
        >
            <div
                className={
                    "absolute -top-5 left-0 w-full rounded-md" +
                    (isEdit
                        ? " bg-gray-100 h-20 shadow-md text-pgn-primary"
                        : " [&>div]:hidden")
                }
            >
                {!hideActions && <Icon name="pencil" className="mx-auto" />}
                <div className="bg-gray-100 shadow-md rounded-md mt-12 absolute w-max flex z-10">
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            onDelete();
                        }}
                        minimal
                        icon="trash"
                    >
                        {t("step3.deleteMove")}
                    </Button>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            onAdd();
                        }}
                        minimal
                        icon="plus"
                    >
                        {t("step3.addMove")}
                    </Button>
                </div>
            </div>
            <canvas
                className={
                    "h-10 w-24 relative border border-black rounded-md p-0 " +
                    (isEdit ? "border-pgn-primary " : "") +
                    (id === disableBox ? "opacity-30" : "")
                }
                width={w}
                height={h}
                ref={canvas}
            ></canvas>
            {writer.shift !== 0 && (
                <span className="absolute -top-2 -right-2 font-bold text-pgn-primary text-center text-[12px] bg-white rounded-full border-2 p-0.5 min-w-[1rem] border-pgn-primary">
                    {writer.shift}
                </span>
            )}
        </div>
    );
}
