import heic2any from "heic2any";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppProvider";
import Icon from "../../shared/components/Icon";
import Button from "../../shared/components/buttons/Button";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import { useTabContext } from "../../shared/stepview/context/TabProvider";
import ImageSlider from "./ImageSlider";

/**
 * @typedef {Object} dropzoneFile
 * @property {string} code
 * @property {string} message
 */

/**
 * @param {String} name context name for the StepViewProvider
 * @returns upload field with drag and drop support from useDropzone
 */
const UploadField = ({ name }) => {
    const { p1ActiveImage, p2ActiveImage, setP1ActiveImage, setP2ActiveImage } = useTabContext();

    const activeImage = name === "player_1" ? p1ActiveImage : p2ActiveImage;

    const { setSnack } = useAppContext();
    const context = useGameDataContext();

    const { t } = useTranslation();
    const files = context[name]["files"];

    const dropzoneMsg = t("step1.upload.note");
    const fileLimit = 4;
    
    /**
     * Set the active image to the selected player tab.
     * @param {number} index 
     */
    const setActiveImage = (index) => {
        if(name === "player_1") {
            setP1ActiveImage(index);
        } else {
            setP2ActiveImage(index);
        }
    };

    /**
     * Function to check if uploaded files are valid.
     * @param {dropzoneFile[]} acceptedFiles
     * @param {dropzoneFile[]} rejectedFiles
     * @returns {boolean} is valid
     */
    const isFileValid = ({ length: accepted }, rejected) => {
        const fileLength = files.length + accepted + rejected.length;
        const fileLimitExceeded = fileLimit < fileLength;
        if (fileLimitExceeded || rejected.length > 0) {
            const errorCodes = [];

            if (fileLimitExceeded) {
                errorCodes.push("too-many-files");
            }

            rejected.forEach(({ errors: fileErrors }) => {
                if (Array.isArray(fileErrors)) {
                    fileErrors.forEach(({ code }) => {
                        const found = errorCodes.find((c) => c === code);

                        if (!found) {
                            errorCodes.push(code);
                        }
                    });
                }
            });

            const errors = translateErrors(errorCodes);
            setSnack({ message: errors.join("\n "), isError: true });
            return false;
        }
        setSnack({});
        return true;
    };

    /**
     * Function to translate a list with error codes.
     * @param {string[]} errorCodes
     * @returns {string[]} error messages
     */
    const translateErrors = (errorCodes) => {
        return errorCodes
            .map((code) => {
                switch (code) {
                    case "file-invalid-type":
                        return t("error.fileFormat");
                    case "too-many-files":
                        return t("error.fileLimit");
                    case "file-too-large":
                        return t("error.fileSizeExceed");
                    case "file-too-small":
                        return t("error.fileSizeSubceed");
                    default:
                        return "";
                }
            })
            .filter((error) => error);
    };

    /**
     * Handler for processing files with MIME type checks.
     * @param {dropzoneFile[]} files
     * @returns Processed files
     */
    const processMIMEs = async (files) => {
        return await Promise.all(
            files.map(async (file) => {
                if (file.type === "image/heic") {
                    try {
                        const convertedBlob = await heic2any({
                            blob: file,
                            toType: "image/jpeg",
                        });
                        const newFile = new File(
                            [convertedBlob],
                            file.name.replace(".heic", ".jpeg"),
                            {
                                type: "image/jpeg",
                                lastModified: new Date().getTime(),
                            }
                        );
                        newFile.preview = URL.createObjectURL(newFile);
                        return newFile;
                    } catch (error) {
                        console.error("Error converting HEIC file:", error);
                        setSnack({
                            message: t("error.fileConversion"),
                            isError: true,
                        });
                    }
                } else {
                    file.preview = URL.createObjectURL(file);
                    return file;
                }
            })
        );
    };

    /**
     * onDrop function.
     * @param {dropzoneFile[]} acceptedFiles
     * @param {dropzoneFile[]} rejectedFiles
     */
    const onDrop = async (acceptedFiles, rejectedFiles) => {
        if (isFileValid(acceptedFiles, rejectedFiles)) {
            const processedFiles = await processMIMEs(acceptedFiles);
            const newFiles = [].concat(files, processedFiles);
            // Update the context with the new set of files
            context.updatePlayer(name, {
                files: newFiles,
            });

            setActiveImage(newFiles.length - 1);
        }
    };

    const {
        ref,
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: {
            "image/png": [],
            "image/jpeg": [],
            "image/heic": [],
        },
        noClick: true,
        noKeyboard: true,
        minSize: 0,
        maxFiles: fileLimit,
    });
    return (
        <div className="relative w-full" ref={ref}>
            <div className="p-6 rounded-3xl bg-gray-100 ">
                {files.length > 0 ? (
                    <div {...getRootProps()}>
                        {isDragActive && (
                            <div className="absolute z-10 inset-0 p-6 rounded-3xl bg-gray-100">
                                <div className="aspect-[1_/_1.35]">
                                    <div className="flex h-full flex-col gap-2 items-center justify-center">
                                        <div className="flex h-44 p-8 text-[#6b7280] italic leading-6 text-center items-center bg-white border border-gray-400 border-dashed whitespace-pre-wrap rounded-2xl">
                                            {t("step1.upload.drop")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <input readOnly {...getInputProps()}></input>
                        <ImageSlider
                            player={name}
                            onAdd={open}
                            index={activeImage}
                            setIndex={setActiveImage}
                        />
                    </div>
                ) : (
                    <div className="aspect-[1.75_/_1.35] tablet:aspect-[1.25_/_1.35] 4xl:aspect-[1_/_1.35]">
                        <div className="flex h-full flex-col gap-2 items-center justify-center">
                            <div
                                {...getRootProps()}
                                className="flex flex-col gap-5 p-8 text-[#6b7280] italic leading-6 text-center items-center bg-white border border-gray-400 border-dashed whitespace-pre-wrap rounded-2xl"
                            >
                                <input readOnly {...getInputProps()} />
                                <Icon name="imageUpload" size={6} />
                                {!isDragActive && dropzoneMsg}
                                {isDragActive && !isDragReject && dropzoneMsg}
                                {isDragReject && dropzoneMsg}
                                <Button type="button" onClick={open} invert>
                                    {t("step1.upload.button")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default UploadField;
