/** @type {import('tailwindcss').Config} */
module.exports = {
    content: ["./src/**/*.{js,jsx,ts,tsx}"],
    theme: {
        extend: {
            screens: {
                'nano': '375px',
                'tiny': '500px',
                'tablet': '900px',
                '3xl': '1792px',
                '4xl': '2048px'
            },
            colors: {
                "pgn-primary": "#059669",
                emerald: {
                    50: "#ECFDF5",
                    100: "#D1FAE5",
                    200: "#A7F3D0",
                    300: "#131313",
                    400: "#34D399",
                    500: "#10B981",
                    600: "#059669",
                    700: "#047857",
                    800: "#065F46",
                    900: "#064E3B",
                },
                black: "#131313",
                gray: {
                    200: "#E5E7EB",
                    400: "#9CA3AF",
                    600: "#4B5563",
                },
            },
            animation: {
                "fade-out": "3s linear infinite fadeout",
                "fade-in": "3s linear infinite fadein",
            },
            keyframes: {
                fadeout: {
                    "0%,100%": { opacity: "1" },
                    "50%": { opacity: "0" },
                },
                fadein: {
                    "0%,100%": { opacity: "0" },
                    "50%": { opacity: "1" },
                },
            },
            boxShadow: {
                "md-top": "0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 -2px 4px -2px rgb(0 0 0 / 0.1)",
                "lg-top": "0 -10px 15px -3px rgb(0 0 0 / 0.1), 0 -4px 6px -4px rgb(0 0 0 / 0.1)",
            },
        },
    },
    plugins: [],
    corePlugins: {
        preflight: false,
    },
    important: true,
};
