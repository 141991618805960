import { useTranslation } from "react-i18next";
import Container from "../shared/components/layout/Container";
import MetaDataFolder from "./components/MetaDataFolder";
import MetaDataForm from "./components/MetaDataForm";
import MetaDataTemplates from "./components/MetaDataTemplates";
import ViewHeading from "../shared/components/views/ViewHeading";
import { useAppContext } from "../AppProvider";

function MetaData() {
    const { t } = useTranslation();
    const { shared } = useAppContext();

    const notShared = shared === null;

    return (
        <Container>
            <ViewHeading
                heading={t("step2.title")}
                hintTitle={t("hint.step2.title")}
                hintContent={t("hint.step2.content")}
            />
            {notShared && <MetaDataFolder />}
            {<MetaDataTemplates />}
            <MetaDataForm />
        </Container>
    );
}

export default MetaData;
