import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";
import SearchField from "../SearchField";
import HeadDataTable from "../HeadDataTable";
import Button from "../../../shared/components/buttons/Button";
import { useState } from "react";
import { useGameDataContext } from "../../../shared/stepview/context/GameDataProvider";

/**
 *
 * @param {[{
 *  event: String,
 *  location: String,
 *  date: String,
 *  round: String,
 *  board: String,
 *  black: String,
 *  white: String,
 *  result: String
 * }]} headData
 * @returns
 */
function HeadDataTemplates({ onClose }) {
    const context = useGameDataContext();
    const { t } = useTranslation();

    const [filters, setFilters] = useState([]);
    const [active, setActive] = useState(null);

    const headerData = context.getHeaderDataSets();

    const title = `${t("step2.templates.modal.title")} (${headerData.length})`;
    const id = `modal-headdatatemplates-${Date.now()}`;

    const onAddFilter = (filter) => {
        if(!filters.find(f => f === filter)) {
            setFilters([...filters, filter]);
            setActive(null);
        }
    };

    const onRemoveFilter = (remove) => {
        setFilters([...filters].filter((filter) => filter !== remove));
        setActive(null);
    };

    const onRowClick = (index) => {
        if (index !== active) {
            setActive(index);
        } else {
            setActive(null);
        }
    };

    const onConfirm = () => {
        if (active >= 0 && filterData()[active]) {
            context.updateMetaData(filterData()[active]);
            onClose();
        }
    };

    const filterData = () => {
        let filtered = [...headerData];
        filters.forEach((filter) => {
            filtered = filtered.filter((data) => {
                const picked = (({ round, board, white, black, event }) => ({
                    round,
                    board,
                    white,
                    black,
                    event,
                }))(data);
                return Object.values(picked).some((str) =>
                    (str ? str.toString() : "")
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                );
            });
        });

        return filtered;
    };

    if (!Array.isArray(headerData)) {
        return;
    }

    return (
        <Modal
            modalId={id}
            open={true}
            onClose={onClose}
            center
            aria-labelledby={title ? id + "-modal-title" : undefined}
            classNames={{
                overlay: "!bg-opacity-90 backdrop-blur-sm",
                modal: "min-h-screen w-full md:min-h-0 md:h-1/3 md:w-2/3 lg:h-[500px] lg:w-[750px] m-0 px-0 py-4 md:py-8",
                closeButton: "translate-x-1 md:-translate-x-1/2 -translate-y-1/2 mt-4 md:mt-7",
            }}
        >
            <div className="h-full flex flex-col justify-start items-center gap-4 px-4 md:px-8">
                <h3 className="m-0 self-start">{title}</h3>
                <SearchField
                    onSearch={onAddFilter}
                    onRemoveSearch={onRemoveFilter}
                    filters={filters}
                />
                <HeadDataTable
                    data={filterData()}
                    active={active}
                    onRowClick={onRowClick}
                />
                <Button disabled={active === null} onClick={onConfirm}>
                    {t("buttons.applySelection")}
                </Button>
            </div>
        </Modal>
    );
}

export default HeadDataTemplates;
