import GameTitle from "../GameTitle";
import StepBreadcrumbs from "../../stepview/StepBreadcrumbs";
import UploadPreview from "../../../upload/components/UploadPreview";
import useTabs from "../../hooks/useTabs";
import { useStepNavigationContext } from "../../stepview/context/StepNavigationProvider";
import TabProvider from "../../stepview/context/TabProvider";

function Container({ children }) {
    const { getActivePath } = useStepNavigationContext();

    const { path } = getActivePath();

    const tabs = useTabs();

    return (
        <div
            className={
                "flex flex-col gap-1 sm:gap-4 tablet:grid tablet:grid-cols-2 tablet:grid-rows-[8rem] tablet:auto-rows-[1fr] tablet:grid-flow-row-dense "
            }
        >
            <div className="flex flex-col gap-1 order-1">
                <GameTitle />
                <StepBreadcrumbs />
            </div>
            <div
                className={
                    "flex flex-col gap-2 sm:gap-6 " +
                    (path === "upload" ? "order-4" : "order-2")
                }
                style={{
                    gridRow: "2",
                }}
            >
                {children}
            </div>
            <TabProvider>
                <UploadPreview
                    tabs={tabs}
                    style={{
                        gridRow: "1 / span 2",
                        gridColumn: "2",
                        justifySelf: "end",
                        order: "3",
                    }}
                />
            </TabProvider>
        </div>
    );
}

export default Container;
