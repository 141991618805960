import { useTranslation } from "react-i18next";
import Button from "../../../shared/components/buttons/Button";
import { useGameDataContext } from "../../../shared/stepview/context/GameDataProvider";
import ClipboardButton from "./ClipboardButton";
import CompleteEditButton from "./CompleteEditButton";
import PauseEditButton from "./PauseEditButton";
import ResetButton from "./ResetButton";

/**
 * @param {boolean} props.mobile mobile behaviour
 * @returns wrapper with pgn buttons {@link ResetButton}, {@link CompleteEditButton},
 * {@link PauseEditButton} and {@link ClipboardButton}
 */
function PGNButtons({ mobile = false, toggle }) {
    const { gameLogicSnapshot } = useGameDataContext();
    const { t } = useTranslation();

    if (!gameLogicSnapshot) return;

    const { bestBoardPgn } = gameLogicSnapshot;

    const buttonDesktop = "mr-0 sm:mr-auto mt-3";

    return mobile ? (
        <div className="flex-col flex tablet:hidden gap-2">
            <div className="flex gap-2">
                {bestBoardPgn && (
                    <ClipboardButton text={t("step3.copyPgn")} />
                )}
                <Button
                    className="flex-1"
                    invert
                    icon="chessBoard"
                    onClick={toggle}
                >
                    {t("chessboard")}
                </Button>
            </div>
            <div className="flex gap-2">
                <ResetButton text="" minimal={false} />
                <PauseEditButton text="" />
                {bestBoardPgn && <CompleteEditButton className="flex-1" />}
            </div>
        </div>
    ) : (
        <div className="flex-col hidden tablet:flex">
            {bestBoardPgn && <CompleteEditButton className={buttonDesktop} />}
            <PauseEditButton className={buttonDesktop} />
            {bestBoardPgn && <ClipboardButton />}
        </div>
    );
}

export default PGNButtons;
