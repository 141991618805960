import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useGame from "../../api/useGame";
import { imgUrlsToFile } from "../images/ImageConverter";
import useLocalStorage from "./useLocalStorage";

import dayjs from "dayjs";
import { useAppContext } from "../../AppProvider";
import { getUserData } from "../../api/PGNVariables";
import useKeepAlive from "../../api/useKeepAlive";

/**
 * Hook that retrieves the "cache" (initial state for the {@link GameDataContext}).
 * @returns {{ cache: Object, cacheLoad: Boolean}}
 */
function useCache() {
    const { t } = useTranslation();
    const { setLocked } = useAppContext();
    const { loadStorage, clearStorage } = useLocalStorage();
    const { getGame } = useGame();

    const [cache, setCache] = useState({});
    const [cacheLoad, setCacheLoad] = useState(false);
    const [cacheApplied, setCacheApplied] = useState(false);
    const [error, setError] = useState("");

    const errorOnGameLoad = t("error.gameLoad");

    const util = useMemo(
        () => ({
            loadStorage,
            clearStorage,
            getGame,
        }),
        [loadStorage, clearStorage, getGame]
    );

    const { keepAlive, refresh } = useKeepAlive();

    /**
     * Load cache with {@link loadStorage} or {@link getGame}.
     * @returns {Promise<Object>}
     */
    const loadCache = useCallback(async () => {
        try {
            const { loadStorage, clearStorage, getGame } = util;
            const urlParams = new URLSearchParams(window.location.search);
            const gameId = urlParams.get("edit");
            const localStorage = loadStorage();

            if (gameId) {
                const game = await getGame(gameId);
                const overwriteGame = () => {
                    if (
                        game &&
                        localStorage &&
                        game.id === localStorage.gameId
                    ) {
                        const gameModified = game.modified;
                        const lsModifiedUnix = localStorage.modified;
                        if (gameModified && lsModifiedUnix) {
                            const gameModifiedUnix = dayjs(gameModified).unix();
                            if (lsModifiedUnix > gameModifiedUnix) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                if (overwriteGame()) {
                    return localStorage;
                } else {
                    clearStorage();
                    return game;
                }
            } else {
                return localStorage;
            }
        } catch (error) {
            throw error;
        }
    }, [util]);

    /**
     * Normalize cache.
     * @param {{any}} cache
     * @returns
     */
    const formatCache = async (cache) => {
        let formatted = { ...cache };

        if (Object.keys(formatted).length > 0) {
            if (formatted.meta) {
                if (formatted?.meta._selected_header_data) {
                    formatted = {
                        ...formatted,
                        metaData: JSON.parse(
                            formatted.meta._selected_header_data
                        ),
                    };
                }

                const pgnString = formatted?.meta._pgn_string;
                if (pgnString && pgnString !== "") {
                    formatted = {
                        ...formatted,
                        pgnString: pgnString,
                    };

                    formatted.navigation = {
                        type: "to",
                        path: "pgnedit",
                    };
                }
            }

            formatted.player_1 = { ...formatted.player_1 };
            formatted.player_2 = { ...formatted.player_2 };

            const { player1: images1 = [], player2: images2 = [] } =
                formatted["chess_entity_images"] || {};

            if (images1.length > 0) {
                const sanitize = (url) => {
                    const urlPartial = "/wp-content";
                    const targetIndex = url.indexOf(urlPartial);

                    if (targetIndex !== -1) {
                        return url.substring(targetIndex);
                    }

                    return "";
                };

                const [p1Images, p2Images] = await Promise.all([
                    imgUrlsToFile(
                        images1.map((imageUrl) => sanitize(imageUrl))
                    ),
                    imgUrlsToFile(
                        images2.map((imageUrl) => sanitize(imageUrl))
                    ),
                ]);

                formatted.player_1 = {
                    ...formatted.player_1,
                    files: p1Images
                        .filter((image) => image !== false)
                        .map((file) =>
                            Object.assign(file, {
                                preview: URL.createObjectURL(file),
                            })
                        ),
                };
                formatted.player_2 = {
                    ...formatted.player_2,
                    files: p2Images
                        .filter((image) => image !== false)
                        .map((file) =>
                            Object.assign(file, {
                                preview: URL.createObjectURL(file),
                            })
                        ),
                };
            }
            const apiResponse = formatted["chess_entity_json_api_response"];
            if (apiResponse) {
                if (Array.isArray(apiResponse.player_1)) {
                    formatted.player_1.data = apiResponse.player_1;
                }
                if (Array.isArray(apiResponse.player_2)) {
                    formatted.player_2.data = apiResponse.player_2;
                }
            }

            if (formatted.id) {
                formatted = { ...formatted, gameId: formatted.id };
            }
        }
        return formatted;
    };

    /**
     * Apply loaded cache to state.
     * The result of {@link loadCache} will be formatted.
     */
    const applyChache = useCallback(async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const clearCache = urlParams.get("clearCache");
            if (clearCache !== null) {
                util.clearStorage();
                if (window.location.search !== "") {
                    window.history.replaceState(
                        {},
                        document.title,
                        window.location.pathname
                    );
                }
            } else {
                setCacheLoad(true);
                const cache = await loadCache();
                const formatted = await formatCache(cache);

                if (formatted.gameId) {
                    refresh(formatted.gameId).then((response) => {
                        if (response && response.success) {
                            const sharedFolders =
                                getUserData()["sharedFolders"] || [];
                            const isShared =
                                sharedFolders.findIndex(
                                    (folder) =>
                                        folder.value === formatted.parent
                                ) !== -1;
                            if (isShared) {
                                // is shared game
                                keepAlive(formatted.gameId);
                            }
                            setCache(formatted);
                        } else {
                            setLocked(true);
                        }
                    });
                }
            }
        } catch (error) {
            let parsed;
            try {
                parsed = JSON.parse(error.message);
            } catch (error) {
                parsed = {};
            }
            const { status, message: errorMsg } = parsed;

            switch (status) {
                case 404:
                    setError(errorOnGameLoad);
                    break;
                case 401:
                    setError(errorOnGameLoad);
                    break;
                default:
                    if (errorMsg) {
                        console.error(errorMsg);
                    }
            }
        } finally {
            setCacheLoad(false);
        }
    }, [loadCache, util, errorOnGameLoad, keepAlive, refresh, setLocked]);

    useEffect(() => {
        if (cacheApplied === false) {
            setCacheApplied(true);
            applyChache();
        }
    }, [cacheApplied, applyChache]);

    return { cache, cacheLoad, error };
}

export default useCache;
