import { GameLogic } from '../GameLogic';
import { ICommand } from '../ICommand';

export class ResetLastMoveCommand implements ICommand {
  #gameLogic: GameLogic;

  constructor(gameLogic: GameLogic) {
    this.#gameLogic = gameLogic;
  }

  public get text() {
    return '⌦⚑';
  }

  public execute(): void {
    this.#gameLogic.resetLastMoveFixed();
  }

  public toString = (): string => {
    return this.text;
  };

  public get pressed() {
    return false;
  }

  public get prominent() {
    return false;
  }
}
