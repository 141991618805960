import Icon from "../../shared/components/Icon";
import Button from "../../shared/components/buttons/Button";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import MoveBox from "./MoveBox";
import RowItem from "./RowItem";
import RowMoveNumber from "./RowMoveNumber";

/**
 * @param {{any}} props.halfMove row halfMove
 * @param {Boolean} props.disableBox disable either writer1 or writer2 boxes
 * @returns
 */
function ChooseLastMoveRow({ halfMove, disableBox }) {
    const { setLoading } = useGameDataContext();

    return (
        <RowItem>
            <RowMoveNumber color={halfMove.color}>
                {halfMove.halfMove.moveNo}
            </RowMoveNumber>
            {[halfMove.writer1, halfMove.writer2]
                .filter((writer) => writer)
                .map((writer, i) => {
                    return (
                        <MoveBox
                            key={i}
                            id={i}
                            writer={writer}
                            {...writer.halfMoveWriter.box}
                            hideActions={true}
                            disableBox={disableBox}
                        />
                    );
                })}
            <Icon
                name="arrowRight"
                size={6}
                className="mx-2 w-[24px] sm:mx-0"
            />
            {halfMove.commands.map((command, i) => (
                <Button
                    key={i}
                    className="pl-5 pr-5 hover:pl-5 hover:pr-5"
                    active={command.pressed}
                    invert
                    icon="flag"
                    onClick={() => {
                        setLoading(true);
                        setTimeout(() => {
                            command.execute();
                        }, 10);
                    }}
                ></Button>
            ))}
        </RowItem>
    );
}

export default ChooseLastMoveRow;
