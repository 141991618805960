/**
 * Display messages.
 * @param {string} props.message
 * @param {string} props.text
 * @returns message component
 */
function Message({ message = "", text = "" }) {
    return (
        <div className="flex flex-col p-4 gap-2">
            <h1 className="mx-auto w-full max-w-7xl text-2xl text-emerald-500 hidden sm:block">
                {message}
            </h1>
            {text && <p>{text}</p>}
        </div>
    );
}

export default Message;
