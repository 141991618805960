import InfoPopup from "../modals/InfoPopup";

/**
 * @param {string} props.heading
 * @param {string} props.hintTitle
 * @param {string} props.hintContent
 * @param {Object[]} props.hintLinks
 * @returns heading (h2) wrapped in {@link InfoPopup}.
 */
function ViewHeading({
    heading = "",
    hintTitle = "",
    hintContent = "",
    hintLinks = [],
}) {
    return (
        <InfoPopup title={hintTitle} content={hintContent} links={hintLinks}>
            <h2 className="mb-2 mt-5">{heading}</h2>
        </InfoPopup>
    );
}

export default ViewHeading;
