import { Color } from './Color';
import { GameLogic } from './GameLogic';
import { ICommand } from './ICommand';
import { HalfMoveWriter } from './HalfMoveWriter';
import { RecognizedText } from './recognition/v1/RecognizedText';
import { Chess, DEFAULT_POSITION } from 'chess.js';
import { SetSANCommand } from './commands/SetSANCommand';
import { SetLastMoveCommand } from './commands/SetLastMoveCommand';
import { ResetLastMoveCommand } from './commands/ResetLastMoveCommand';
import { getColorFromHalfMoveNo, getMoveNoFromHalfMoveNo } from './Utils';
import {
  compareLegalWithRecognizedMoves,
  compareLegalWithRecognizedMovesOneCharWrongAllowed,
  compareMoves
} from './HalfMoveMatcher';
import { BoardCandidateSnapshot } from './snapshot/BoardCandidateSnapshot';

/**
 * Diese Klasse stellt einen Halbzug des Spiels dar, übergreifend über beide Schreiber.
 *
 * Die Schreiber-spezifischen Daten eines Halbzuges stehen dann in den Eigenschaften writer1 und writer2
 * zur Verfügung.
 */
export class HalfMove {
  /**
   * Nummer des Halbzuges, beginnend bei 0.
   *
   * Die Halbzug-Nummer des Zuges 3 Schwarz ist beispielsweise 5, die von 2 Weiß ist
   * beispielsweise 3.
   */
  public readonly halfMoveIndex: number;

  /**
   * @private
   *
   * Verweis auf das übergeordnete {@linkcode GameLogic}-Objekt, zu dem dieses Objekt zugeordnet ist
   *
   * Dieses Feld wird intern benötigt, eine Applikation wird diese Eigenschaft in der Regel nicht brauchen.
   */
  public readonly gameLogic: GameLogic;

  /**
   * @private
   * Verweis auf das vorherige {@linkcode HalfMove}-Objekt
   *
   * Falls es ```null```ist, handelt es sich um den ersten Halbzug.
   */
  readonly #halfMoveBefore: HalfMove | null;

  /**
   * Verweis auf das nächst folgende {@linkcode HalfMove}-Objekt
   *
   * Falls es ```null```ist, handelt es sich um den letzten Halbzug im
   * {@linkcode GameLogic.halfMoves}-Array (Das ist nicht unbedingt der letze Halbzug
   * des Spiels, siehe {@linkcode visible})
   */
  #halfMoveAfter: HalfMove | null = null;

  /**
   * @private
   *
   * Backing-Field für {@linkcode debugSan}.
   */
  readonly #debugSan?: string;

  /**
   * @hidden
   *
   * Liefert den echten SAN-Wert dieses Zuges, falls dem {@linkcode GameLogic}-Konstruktor ein gpn mit
   * den richtigen Zügen vorgegeben wurde.
   *
   * Falls ```undefined``` geliefert wird, bedeutet dies dass  kein pgn dem Konstruktor mitgegeben
   * wurde.
   *
   * Im Regelbetrieb wird diese Eigenschaft nicht benötigt, hier handelt es sich um ein Debug-Feature.
   */
  public get debugSan() {
    return this.#debugSan;
  }

  /**
   * Erzeugt ein neues HalfMove-Objekt
   *
   * @private
   * @param gameLogic Verweis auf das Spiel-Objekt zu dem dieser Halbzug gehört.
   * @param halfMoveBefore Verweis auf den vorherigen Halbzug.
   */
  public constructor(
    gameLogic: GameLogic,
    halfMoveBefore: HalfMove | null,
    debugSan?: string
  ) {
    this.gameLogic = gameLogic;
    this.#halfMoveBefore = halfMoveBefore;
    this.#debugSan = debugSan;
    this.halfMoveIndex = (this.#halfMoveBefore?.halfMoveIndex ?? -1) + 1;

    this.writer1 = new HalfMoveWriter(
      halfMoveBefore ? halfMoveBefore.writer1 : null,
      this,
      this.gameLogic.writer1
    );

    if (gameLogic.writer2)
      this.writer2 = new HalfMoveWriter(
        halfMoveBefore ? halfMoveBefore.writer2 : null,
        this,
        gameLogic.writer2
      );

    if (this.#halfMoveBefore) this.#halfMoveBefore.#halfMoveAfter = this;
  }

  /**
   * Berechnet den Spieler (B/W) ausgehend von der Halbzug-Nr.
   */
  public get color(): Color {
    return getColorFromHalfMoveNo(this.halfMoveNo);
  }

  /**
   * Liefert die die Zugnummer zu dem dieser Halbzug gehört.
   */
  public get halfMoveNo() {
    return this.halfMoveIndex + 1;
  }

  /**
   * Liefert die die Zugnummer zu dem dieser Halbzug gehört.
   */
  public get moveNo() {
    return getMoveNoFromHalfMoveNo(this.halfMoveNo);
  }

  /**
   * Daten vom Schreiber 1 zu diesem Halbzug.
   *
   * Alle Daten, die Schreiberspezifisch sind, sind in die beiden Eigenschaften
   * {@linkcode writer1} und {@linkcode writer2} ausgelagert.
   */
  public readonly writer1: HalfMoveWriter;

  /***
   * Daten vom Schreiber 2 zu diesem Halbzug.
   *
   * Im Gegensatz zu {@linkcode writer1} kann diese Eigenschaft auch den Wert
   * ```null``` annehmen. Dies ist allerdings nur dann der Fall, wenn nur Bilder
   * bzw. JSON-Daten des Schreibers 1 angegeben wurden.
   *
   * Für weitere Informationen, siehe {@linkcode writer1}
   */
  public readonly writer2: HalfMoveWriter | null = null;

  /**
   * Liste der Buttons / Befehle welche im aktuellen Spielzustand dargestellt werden können.
   *
   * Die Liste beinhaltet alle möglichen Buttons. Dies können auch mehr als 4 sein. Die UI kann entscheiden
   * nur die ersten n Buttons darzustellen, oder ab dem x. Button diese in einem Pull-Down-Menü anzuzeigen.
   */
  public commands: readonly ICommand[] = [];

  /**
   * Backing-Field für den Getter {@linkcode sanFixed}
   */
  #sanFixed?: string | undefined = undefined;

  /**
   * Liefert den SAN-Text den der Anwender für diese Zelle explizt
   * gesetzt hat.
   *
   * Die Eigenschaft nimmt den Wert ```undefined```an bzw. muss auf diesen
   * Wert gesetzt werden, wenn keine Vorgabe bei diesem Halzug explizit gesetzt
   * sein soll...
   */
  public get sanFixed() {
    return this.#sanFixed;
  }

  /**
   * SAN-Wert der vom Anwender für diesen Zug explizit gesetzt wurde.
   *
   * @param value SAN-Zug der für diesen Zug angedacht ist. Wenn undefined gesetzt ist,
   * dann wird der Wert ignoriert und es wird aus den Texterkennungsdaten versucht den
   * richtigen Zug zu ermitteln.
   */
  public set sanFixed(value: string | undefined) {
    this.setSanFixedInternal(value);
  }

  /**
   * @private
   * @param value
   * @param calculate
   */
  setSanFixedInternal(value: string | undefined, calculate = true) {
    if (this.#sanFixed != value) {
      this.#sanFixed = value;

      this.#selectNextMove();

      // Starte die Neuberechnung ab diesem Zug
      if (calculate) this.calculate();
    }
  }

  #selectNextMove() {
    let nextMove = this.#halfMoveAfter;
    while (nextMove) {
      if (
        nextMove &&
        nextMove.visible &&
        (!this.gameLogic.filtered || !nextMove.confident)
      ) {
        this.gameLogic.setSelectedHalfMoveIndex(nextMove.halfMoveIndex);
        break;
      }
      nextMove = nextMove.#halfMoveAfter;
    }
  }

  /**
   * Gibt den aktuell wahrscheinlichsten SAN-Wert dieses Halbzuges an.
   *
   * Das aktuell wahrscheinlichste Brett wird in der Eigenschaft
   * {@linkcode GameLogic.bestBoardMoves} beschrieben. Diese Eigenschaft liefert einfach
   * das {@linkcode halfMoveIndex}. Element dieses Arrays zurück.
   */
  public get san() {
    return this.gameLogic.bestBoardMoves[this.halfMoveIndex];
  }

  #bestBoardFenAfter: string | undefined;
  /**
   * Liefert den FEN-Ausdruck für das Brett, bevor dieser Halbzug ausgeführt wurde.
   *
   * Falls der vorherige Halbzug keinen Wert in der Eigenschaft {@linkcode san} hat, weil
   * das Brett nicht soweit erkannt wurde, dann liefert diese Methode ```undefined``` zurück.
   *
   * @returns Liefert den FEN-Ausdruck oder ```undefinied```
   */
  public get bestBoardFenBefore() {
    return this.#halfMoveBefore
      ? this.#halfMoveBefore.bestBoardFenAfter
      : DEFAULT_POSITION;
  }

  /**
   * Liefert den FEN-Ausdruck für das Brett, nachdem dieser Halbzug ausgeführt wurde.
   *
   * Falls der Halbzug keinen Wert in der Eigenschaft {@linkcode san} hat, weil
   * das Brett nicht soweit erkannt wurde, dann liefert diese Methode ```undefined``` zurück.
   *
   * @returns Liefert den FEN-Ausdruck oder ```undefinied```
   */
  public get bestBoardFenAfter() {
    return this.#bestBoardFenAfter;
  }

  /**
   * @private
   */
  set bestBoardFenAfter(fen: string | undefined) {
    this.#bestBoardFenAfter = fen;
  }

  /**
   * Backing-Field zu {@linkcode isCorrect}.
   */
  #isCorrect?: boolean;

  /**
   * @hidden
   *
   * Gibt an, ob der aktuell erkannte Zug ({@linkcode san}) dem echten Spielzug laut angegebenen
   * Debug-PGN entspricht (Siehe {@linkcode #debugSan} bzw. {@linkcode GameLogic.debugBoard}). Diese Eigenschaft wird im Regelbetrieb daher natürlich nicht gebraucht
   * bzw. darf hier nicht verwendet werden, da hier ja das "echte" PGN nicht bekannt. ist.
   */
  public get isCorrect() {
    return this.#isCorrect;
  }

  /**
q   * Backing Field zu {@linkcode recognizedTexts}.
   */
  #recognizedTexts: readonly RecognizedText[] = [];

  /**
   * Liefert eine Einschätzung ob beide Schreiber den gleichen Zug beschrieben haben oder
   * unterschiedeliche Züge. Das ist ein Wert mit Unsicherheit.
   *
   * Diese Eigenschaft wird bei der Berechnung des {@linkcode confident}-Wertes verwendet.
   */
  #writerVeryDifferent = false;

  /**
   * Liefert alle von der Texterkennung über beide Schreiber erkannten Zugtexte und Wahrscheinlichkeiten,
   * unabhängig davon ob diese Züge möglich sind oder nicht.
   *
   * Diese Eigenschaft sollte in der Regel von einer Applikation nicht benötigt werden,
   * die ist eher als Debug-Möglichkeit gedacht. Der Anwender kriegt die Zugvorschläge die
   * davon möglich sind über die {@linkcode commands}-Eigenschaft dann indirekt geliefert.
   *
   * @returns Array mit den erkannten Texten. Dabei muss es sich auch nicht zwingend um
   * gültige SAN-Werte handeln, das Netz wurde auch mit üblichen, alternativen Schreibeweisen
   * wie z.B. ```xe6``` oder ```exd```trainiert.
   * @hidden
   */
  public get recognizedTexts() {
    return this.#recognizedTexts;
  }

  /**
   * Backing Field zu {@linkcode recognizedMoves}.
   */
  #recognizedMoves: readonly RecognizedText[] = [];

  /**
   * Liefert die Liste der validen SAN-Züge über alle möglichen Bretter welche aus den erkannten Texten gematched werden konnte.
   *
   * Die Liste wird verwendet um die Vorschlagsbuttons zu generieren.
   *
   * Diese Eigenschaft sollte in der Regel von einer Applikation nicht benötigt werden,
   * die ist eher als Debug-Möglichkeit gedacht. Der Anwender kriegt die Zugvorschläge die
   * davon möglich sind über die {@linkcode commands}-Eigenschaft dann indirekt geliefert.
   *
   * @hidden
   */
  public get recognizedMoves() {
    return this.#recognizedMoves;
  }

  /**
   * Dient zum Aktualisieren der {@linkcode HalfMoveWriter.rowLocation}-Eigenschaft
   * @private
   */
  public updateRowLocations() {
    this.writer1.updateRowLocation(this.writer2 == undefined);
    this.writer2?.updateRowLocation(true);
  }

  /**
   * @private
   */
  updateRecognizedTexts() {
    const texts1 = this.writer1.recognizedTexts ?? [];
    const texts2 = this.writer2?.recognizedTexts ?? [];

    this.#writerVeryDifferent = !(
      texts1
        .map((x) => x.t.replace('x', '').replace('#', '').replace('+', ''))
        .filter((item) =>
          texts2
            .map((x) => x.t.replace('x', '').replace('#', '').replace('+', ''))
            .includes(item)
        ).length > 0
    );
    this.#recognizedTexts = HalfMove.#groupAndSumMoves([...texts1, ...texts2]);
  }

  static #groupAndSumMoves(
    moves: readonly RecognizedText[]
  ): readonly RecognizedText[] {
    const groupedMovesMap = moves.reduce((acc, move) => {
      if (!acc.has(move.t)) {
        acc.set(move.t, move.p);
      } else acc.set(move.t, (acc.get(move.t) ?? 0) + move.p);

      return acc;
    }, new Map<string, number>());

    const groupedMovesArray: RecognizedText[] = Array.from(
      groupedMovesMap,
      ([t, sumP]) => ({ t, p: sumP })
    );

    // Sortiere zuerst nach der Wahrscheinlichkeit, und dann nach der Länge
    // Längere Texte werden bevorzugt...
    groupedMovesArray.sort((x, y) => {
      const ret = y.p - x.p;
      if (ret != 0) return ret;
      else return y.t.length - x.t.length;
    });

    return groupedMovesArray;
  }

  /**
   * Backing-Field zu {@linkcode possibleMoves}
   */
  #possibleMoves: readonly string[] = [];

  #boardCandidates: readonly BoardCandidateSnapshot[] = [];

  public get boardCandidates() {
    return this.#boardCandidates;
  }

  /**
   * Liefert alle derzeit erlaubten Züge für diesen Halzug über alle möglichen Bretter zu diesem
   * Halbzug...
   */
  public get possibleMoves() {
    return this.#possibleMoves;
  }

  /**
   * @private
   *
   * Löst die Neu-Berechnung der Züge ausgehend von diesem Zug neu aus.
   *
   * Für die folgenden Züge werden die möglichen Züge neu ermittent und der wahrscheinlichste Zug vorausgewählt
   * Für die vorherigen Züge bleibt die Liste der möglichen Züge natürlich gleich, aber die Auswahl des wahrscheinlichsten
   * Zuges kann sich auch bei den vorhiergen Zügen dadaurch verändern.
   *
   */
  calculate(triggerCallback = true) {
    this.writer1.gameLogicWriter.endAt = undefined;
    if (this.writer2) this.writer2.gameLogicWriter.endAt = undefined;

    this.#calculateRecursive();

    // Sorge dafür dass wenn der aktuell selektierte Zug durch die Änderung weggefiltered wird,
    // und der Filter aktiv ist, dass dann der erste Zug selektiert wird, der nicht sicher ist.
    if (this.gameLogic.selectedHalfMove.confident && this.gameLogic.filtered)
      this.gameLogic.selectFirst();

    if (triggerCallback) this.gameLogic.callGameChangedCallback();
  }

  /**
   * Rekursive Implementierung zu {@linkcode calculate}
   */
  #calculateRecursive() {
    if (this.writer1.isEnd() && this.writer1.gameLogicWriter.endAt == undefined)
      this.writer1.gameLogicWriter.endAt = this;

    if (
      this.writer2?.isEnd() &&
      this.writer2.gameLogicWriter.endAt == undefined
    )
      this.writer2.gameLogicWriter.endAt = this;

    // Berechne ob es sich bei diesem Zug im den letzten Zug
    this.#isLastMove =
      this.writer1.gameLogicWriter.endAt != undefined &&
      (this.writer2 == null ||
        this.writer2.gameLogicWriter.endAt != undefined) &&
      Math.max(
        this.writer1.gameLogicWriter.endAt.halfMoveIndex,
        this.writer2?.gameLogicWriter.endAt?.halfMoveIndex ?? -1
      ) == this.halfMoveIndex;

    this.#invisibleBecauseAfterLastMove =
      this.#halfMoveBefore != null &&
      (this.#halfMoveBefore.#isLastMove ||
        this.#halfMoveBefore.#invisibleBecauseAfterLastMove);
    this.#visible = !this.#invisibleBecauseAfterLastMove;

    if (this.gameLogic.gameEndIsDefined()) {
      const candidatesBefore =
        this.#halfMoveBefore == null
          ? [null]
          : this.#halfMoveBefore.#boardCandidates;

      const candidates: BoardCandidateSnapshot[] = [];
      let commands: ICommand[] = [];
      const newPossibleMoves: string[] = [];
      let newRecognizedMoves: RecognizedText[] = [];
      const map: Set<string> = new Set<string>();

      const probeFunc = (
        compareMethod: (
          legalMoves: readonly string[],
          recognizedTexts: readonly RecognizedText[]
        ) => RecognizedText[]
      ) => {
        // Gehe alle möglichen Bretter des vorherigen Zuges durch und kombiniere diese mit den erkannten
        // Zügen auf diesen Hapbzug.
        candidatesBefore.forEach((candidateBefore) => {
          const fenBefore = candidateBefore?.fen ?? DEFAULT_POSITION;
          let board = new Chess(fenBefore);
          // Ergänze alle denkbaren Züge aus diesen Brett, welche  nicht bereits aus einem anderen Brett
          // ergänzt wurden...
          const localPossibleMoves = board.moves();

          localPossibleMoves.forEach((x) => {
            if (newPossibleMoves.indexOf(x) == -1) newPossibleMoves.push(x);
          });

          // Ermittle alle legalen Züge aus der Mege der Texte
          const compared = compareMethod(
            localPossibleMoves,
            this.recognizedTexts
          );

          const localRecognizedMoves = HalfMove.#groupAndSumMoves(compared);
          newRecognizedMoves = [...newRecognizedMoves, ...localRecognizedMoves];

          localRecognizedMoves.forEach((legalMove, index2) => {
            if (
              this.sanFixed == undefined ||
              compareMoves(legalMove.t, this.sanFixed, false)
            ) {
              if (index2 > 0) board = new Chess(fenBefore);

              // führe den legalen Zug durch
              board.move(legalMove.t);

              // Generiere das FEN
              const fen = board.fen();

              // Falls das FEN nicht bereits enthalten ist, merke es in der Liste
              if (!candidates.find((x) => x.fen == fen)) {
                const candidate = new BoardCandidateSnapshot(
                  legalMove,
                  fen,
                  candidateBefore
                );

                candidates.push(candidate);
              }
            }
            if (!map.has(legalMove.t)) {
              map.add(legalMove.t);
              commands.push(new SetSANCommand(this, legalMove.t, this.color));
            }
          });
        });
      };

      // Versuche nun zunächst über moderate Zugvergleiche ein passenden Zug / Brett zu finden.
      if (candidatesBefore.length < 500)
        probeFunc(compareLegalWithRecognizedMoves);

      // Wenn so kein Brett etwas gefunden werden konnte, dann versuche nun mittels ausgefalleneren Methoden.
      let unsecureMethod = false;
      if (candidates.length == 0 && candidatesBefore.length < 128) {
        probeFunc(compareLegalWithRecognizedMovesOneCharWrongAllowed);
        unsecureMethod = candidates.length > 0;
      }

      // Falls der Anwender einen Zug manuell gesetzt hat, weil er nicht erkannt wurde, muss dieser ergänzt werden.
      // In dem Fall wird er als erster Zug eingefügt.
      if (this.sanFixed != undefined && !map.has(this.sanFixed)) {
        const sanFixed = this.sanFixed;

        candidatesBefore.forEach((candidateBefore) => {
          const fenBefore = candidateBefore?.fen ?? DEFAULT_POSITION;
          const board = new Chess(fenBefore);

          // Es ist möglich dass der
          if (board.moves().indexOf(sanFixed) == -1) return;

          if (!map.has(sanFixed)) {
            map.add(sanFixed);
            commands = [
              new SetSANCommand(this, sanFixed, this.color),
              ...commands
            ];
          }
          board.move(sanFixed);

          let pMax = Math.max(...candidates.map((x) => x.recognizedMove.p));
          if (pMax < 0) pMax = 0;

          const candidate = new BoardCandidateSnapshot(
            { t: sanFixed, p: pMax * 2 },
            board.fen(),
            candidateBefore
          );
          candidates.push(candidate);
        });
      }

      // Es wird ermittelt ob man sich sicher ist dass der Zug richtig automatisch erkannt wurde oder nicht.
      // Aktuell ist die Logik einfach:
      this.#confident =
        !unsecureMethod && // Wenn die unsichere Methode angewendet werden musste, dann auf keinen Fall sicher
        !this.#isLastMove && // Der Letzte Zug soll immer sichtbar sein, daher nie sicher.
        this.#sanFixed == undefined && // Züge mit gesetzten sanFixed immer unsicher
        this.writer1.shift == 0 && // Schreiber 1 darf keine Verschiebung haben, Verschiebungen sind immer anzuzeigen.
        (this.writer2 == null || this.writer2.shift == 0) && // Gleiches für Verschiebungen von Schreiber 2
        (candidatesBefore.length == 0 || // Alle Züge nach dem ersten, nicht mehr erkannten Zug, sollen als sicher definiet sein, also sicher in dem Sinne, dass diese auf keinen Fall geraten werden können. Diese sollen nicht angezeigt werden.
          (((!this.#writerVeryDifferent &&
            Array.from(map).filter(
              (x) => x.length > this.recognizedTexts[0].t.length
            ).length < 2) ||
            map.size == 1) &&
            map.size > 0));

      candidates.sort((x, y) => y.probability - x.probability);
      this.#boardCandidates = candidates;
      this.commands = commands;
      this.#recognizedMoves = HalfMove.#groupAndSumMoves(newRecognizedMoves);
      this.#possibleMoves = newPossibleMoves.sort();

      if (this.#isLastMove && this.#boardCandidates.length > 0) {
        this.gameLogic.setBestBoard(this.#boardCandidates[0].moves);
      } else if (
        !this.#invisibleBecauseAfterLastMove &&
        this.#halfMoveBefore &&
        this.#halfMoveBefore.#boardCandidates.length > 0 &&
        this.#boardCandidates.length == 0
      ) {
        this.gameLogic.setBestBoard(
          this.#halfMoveBefore.#boardCandidates[0].moves
        );
      }

      if (this.#isLastMove)
        commands.push(new ResetLastMoveCommand(this.gameLogic));
    } else {
      if (
        this.gameLogic.writer1.lastMoveFixed != undefined &&
        this.writer2 != null
      )
        this.commands = [new SetLastMoveCommand(this.writer2)];
      else this.commands = [new SetLastMoveCommand(this.writer1)];

      this.#invisibleBecauseAfterLastMove = false;
      this.#visible = this.writer1.rowLocation != null;

      const w1 = this.writer1.rowLocation;
      const w1c = this.gameLogic.writer1.lastMoveCalculated;
      const w2 = this.writer2?.rowLocation;
      const w2c = this.gameLogic.writer2?.lastMoveCalculated;

      const range = 3;
      this.#confident =
        w1 != undefined &&
        w1c != undefined &&
        (w1.sheetIndex != w1c.sheetIndex ||
          Math.abs(w1.rowIndex - w1c.rowIndex) > range) &&
        (w2 == undefined ||
          w2c == undefined ||
          w2.sheetIndex != w2c.sheetIndex ||
          Math.abs(w2.rowIndex - w2c.rowIndex) > range);
    }
    if (this.#halfMoveAfter != null) this.#halfMoveAfter.#calculateRecursive();

    // Berechne ob der Zug orrekt ist...
    const san = this.san;
    this.#isCorrect =
      this.#debugSan == undefined || san == undefined
        ? undefined
        : this.#debugSan == san;
  }

  /**
   * @private
   *
   * Debug-Eigenschaft für den Test. Wird bei der Regelverwendung nicht benötigt.
   *
   * Falls der zugehörigen {@linkcode GameLogic}-Klasse im Konstruktor das richtige
   * PGN übergeben wurde, liefert dieser Getter den laut vorgegebenen PGN korrekten Zug
   */
  public correctSan(): string | undefined {
    return this.#correctSan;
  }

  /**
   * Backing Field für {@linkcode correctSan}
   */
  readonly #correctSan?: string;

  /**
   * Hilfs-Variable damit alle Züge nach dem letzten Zug als unsichtbar gekennzeichnet
   * werden kann.
   */
  #invisibleBecauseAfterLastMove = false;

  /**
   * Backing-Field zu {@linkcode visible}
   */
  #visible = true;

  /**
   * Gibt an ob dieser Halbzug überhaupt angezeigt werden soll.
   *
   * Ein Zug wird beispielsweise nicht angezeigt wenn er nach dem letzten Zug liegt. Die
   * Auflistung {@linkcode GameLogic.halfMoves} beinhaltet in der Regel mehr Züge als das
   * Spiel besitzt. Dies vereinfacht die Programmlogik. Wenn der Anwender zusätzliche
   * Halbzüge einfügt, werden die nicht sichtbaren Halbzüge einfach entsprechend sichtbar
   * gemacht.
   *
   * Die Programmoberfläche darf nicht sichtbare Halbzüge niemals darstellen.
   *
   * Es ist zu beachten dass es noch die Eigenschaft {@linkcode confident} gibt, welche eine
   * Aussage darüber macht wie sicher sich die Programmlogik damit ist, dass dieser Halbzug
   * richtig erkannt wurde. Das bedeutet dass die Programmoberfläche noch weitere Kriterien
   * für die Sichtbarkeit einer Zeile heranziehen muss, siehe dazu ebenfalls {@linkcode confident}
   */
  public get visible() {
    return this.#visible;
  }

  /**
   * Backing-Field zu {@linkcode confident}
   */
  #confident = false;

  /**
   * Gibt an ob der Anwender diesen Zug wirklich beurteilen muss oder ob wir davon ausgehen
   * dass dieser Zug sicher erkannt wurde.
   *
   * Die Eigenschaft wird auch in dem Modus "Angabe des letzten Zuges" verwendet. Dort hat
   * werden nur die Zeilen rund um die erkannten letzen Züge dargestellt (3 vorher, 3 nachher).
   *
   *
   *
   * @example Es ist zu beachten dass bezüglich der Sichtbarkeit eines Halbzuges auch die Eigenschaft
   * {@linkcode visible} zu beachten ist. Hier ein exemplarischer Code.
   *
   * ```const halfMoveIsVisibleInUI = halfMove.visible && !(filterIsActive && halfMove.confident)```
   */
  public get confident() {
    return this.#confident;
  }

  /**
   * Hilfsvariable welche den letzten Halbzug markiert.
   */
  #isLastMove = false;

  /**
   * @hidden
   *
   * Liefert die Anzahl der möglichen Boards bis zu diesem Halbzug.
   */
  public get possibleBoardsCount() {
    return this.#boardCandidates.length;
  }

  /**
   * Gibt an ob dieser Halbzug aktuell der selektierte Halbzug in dem Spiel ist.
   */
  public get selected() {
    return this.gameLogic.selectedHalfMoveIndex == this.halfMoveIndex;
  }

  /**
   * Setzt diesen Halbzug als den selektierten Halbzug
   */
  public select() {
    this.gameLogic.selectedHalfMoveIndex = this.halfMoveIndex;
  }
}
