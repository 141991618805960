import { useTranslation } from "react-i18next";
import ComboBox from "../../shared/components/form/Combo";
import { useGameDataContext } from "../../shared/stepview/context/GameDataProvider";
import { useGameCacheContext } from "../../shared/stepview/context/GameCacheProvider";

function MetaDataFolder() {
    const { t } = useTranslation();
    const { setUpdateCache } = useGameCacheContext();
    const {
        folders = [],
        sharedFolders = [],
        folder,
        setFolder,
    } = useGameDataContext();

    /**
     * Function to handle folder change.
     * @param {{value: number, label: string}} change
     */
    const onFolderChange = ({ value }) => {
        setFolder(value);
        setUpdateCache(true);
    };

    /**
     * @returns {{options: array}[]}
     */
    const getOptions = () => {
        return [
            {
                options: sharedFolders,
            },
            {
                options: folders,
            },
        ];
    };

    return (
        <div className="flex flex-col gap-2 py-0 sm:py-2 w-full sm:w-4/6">
            <h3>{t("step2.chooseFolder")}:</h3>
            <ComboBox
                name="folder"
                label={t("step2.folder")}
                labelClass="text-pgn-primary text-sm"
                isGrouped={true}
                options={getOptions()}
                isDisabled={folders.length === 0}
                isSearchable={true}
                value={folder}
                onChange={onFolderChange}
                icon="folder"
                theme="pgn"
            />
        </div>
    );
}

export default MetaDataFolder;
